/* -----------------------------------------------------------
 *  Estilos específicos do 'externalServicesSessionLifetime'
 * -----------------------------------------------------------
**/
@import '../../../styles/common.scss';


$btn-refresh-height: 24px;
$bar-vertical-padding: 0.6em;
div#esSessionLifetimeId {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    padding: $bar-vertical-padding 1em;
    z-index: 9900;

    .session-lifetime-shadow {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background-color: $servicos_default-card-shadow-color;
        height: calc(calc(4 * #{$bar-vertical-padding}) + #{$btn-refresh-height});
        width: 100%;
        opacity: 0.4;
    }

    .session-lifetime-box {
        position: relative;
        background-color: $servicos_session-lifetime-bgColor;
        color: $servicos_session-lifetime-color;
        padding: $bar-vertical-padding 0;
        padding-left: 1em;
        text-align: left;
        line-height: $btn-refresh-height;
        width: 100%;
        border-radius: 3px;

        .refresh-wrapper {
            display: inline;
            position: absolute;
            right: 1em;

            .btn-refresh {
                cursor: pointer;

                @include mc-form-button($servicos_button-secundary-bgColor, $formButton_color,
                        $servicos_button-secundary-hover-bgColor, $formButton_hover-color, 
                        $formButton_disable-bgColor, $formButton_disable-color, none) {
        
                    & {
                        font-size: 0.7em;
                        height: $btn-refresh-height;
                        line-height: $btn-refresh-height;
                        width: 100px;

                        &:disabled {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
