/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'agendamentoConsultas' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('agendamento-consultas') {

    text-align: center;

    ._smp-frame-wrapper_ ._month-item-wrapper_ {
        width: 25%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    $progress-height: 24px;
    .gauge-bar {
        background-color: $main_color;
        width: 100%;
        height: $progress-height;
        border-radius: calc(#{$progress-height} / 2);
        margin-top: 1em;

        .gauge-inticator {
            background-color: $main_information-bgColor;
            border-radius: inherit;
            height: 100%;
            width: 0;

            &.step-1 { width: 25% }
            &.step-2 { width: 50% }
            &.step-3 { width: 75% }
            &.step-4 { width: 100% }
        }
    }

    .step-one-wrapper {
        .instructions-section {
            margin-top: 1.5em;

            .title {
                @include appFont-bold();
            }

            .text {
                margin-top: 0.75em;
            }
        }

        .no-schedule-section {
            margin-top: 1em;
        }

        .solicitar-agendamento {
            margin-top: 2em;
            margin-bottom: 1em;
        }

        .recomendations-section {
            margin-top: 1em;
        }

        .selecao-especialidade-wrapper {
            margin-top: 1.5em;
            text-align: center;

            .especialidade-field {
                .place-holder {
                    color: $main_placeholderColor;
                }

                select {
                    padding: 0.33rem 0;
                    text-indent: 0.66rem; /* Funciona em todos os browsers */
                    padding-left: 0px !important;  /* Saffari o padding é sempre zero */
                    padding-right: 0.66rem;
                    width: 100%;
                    color: $main_color;
                    background-color: $appCard_normal-bgColor;
                    border-color: $main_color;

                    &:active, &:focus {
                        outline: none;
                    }
                }

                option {
                    padding: 0.33rem 0;
                    color: $main_color;
                }
            }
        }
    }

    .step-two-wrapper {
        .especialidade-atual {
            margin-top: 1em;
        }

        .mes-atual {
            margin-top: 0.5em;
            display: inline-block;

            & > div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .descricao {
                    align-self: center;
                    @include appFont-bold();
                }

                .picker-wrapper {
                    align-self: center;
                    margin-left: 0.5em;

                    button {
                        background-color: transparent;
                        padding: 0;
                        height: 20px;
                        width: 20px;
                        border: none;
                        border-radius: 0;

                        &:hover, &:active, &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .grade-agendamento, .legenda-section {
            $mark-border-width: 3px;

            .teleatendimento {
                border-left: $mark-border-width $appBooking_mark-teleatendimento-color solid;
                border-right: $mark-border-width transparent solid;
            }
        }

        .grade-agendamento {
            margin-top: 1em;
            font-size: 1em;

            .grid-day-column.new-month.begin-of-month {
                border-left: grey 1px dashed;
            }
        }

        .legenda-section {
            margin-top: 2.5em;

            .legenda {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .hour-field {
                    background-color: $appBooking_hour-bgColor;
                    padding: 0.2em;
                    font-size: 0.6rem;
                    width: 50px;
                }

                .info-legenda {
                    align-self: center;
                    margin-left: 0.5em;
                    font-size: 0.8em;
                }

                &:not(:first-child) {
                    margin-top: 0.3em;
                }
            }
        }
    }

    .step-three-wrapper, .step-four-wrapper {
        .step-title {
            margin-top: 1.5em;
        }

        .information-wrapper {
            margin-top: 2.0em;
        }
    }

    .step-three-wrapper {
        .summary-wrapper {
            margin-top: 2em;

            .info-row:not(:first-child) {
                margin-top: 0.33em;
            }
        }

        .validade-wrapper {
            margin-top: 1.5em;
        }
    }

    .step-four-wrapper {
        .logo-confirmacao {
            margin: 0 auto;
            margin-top: 2em;
            display: inline-block;
            height: auto;
            width: 90px;

            img {
                height: auto;
                width: 100%;
            }
        }
    }

    .main-action {
        margin: 0 auto;
        padding-top: 1.5rem;
        max-width: 280px;

        &:not(:empty) {
            padding-bottom: 1.5rem;
        }

        button:not(:first-child) {
            margin-top: 1em;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .agendamento-consultas-wrapper {
        & > .content-wrapper > .content-box {
            @media screen and (max-width: 400px) {
                .grade-agendamento { font-size: 0.9em !important; }
            }
        }
    }
}

/* @media (hover: hover) esta apresentando comportamento invertido sendo assim para garantir foi usado o seletor '.desktop' */
#root > .app-viewport.desktop {
    .agendamento-consultas-wrapper {
        & > .content-wrapper > .content-box {
            .step-two-wrapper {
                .mes-atual > div .picker-wrapper button:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}
