/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appHeader' module specific styles
 * ---------------------------------------------------------
**/

#root > .app-viewport {
    .app-header-wrapper {
        display: flex;
        justify-content: center;
        padding: $appHeader_verticalPadding 0.75em;

        & > .image-box {
            display: inline-block;

            & > .app-logo {
                height: $appHeader_logoHeight;
                width: auto;
                vertical-align: middle;
            }

            &:not(:first-child) {
                margin-left: 2em;
            }
        }
    }
}
