/* Import common definition styles */
@import '../../styles/common.scss';


/* ---------------------------------------------------------
 *  'adminMenu' module specific styles
 * ---------------------------------------------------------
**/

#root > .app-viewport.external-service {
    .admin-menu-wrapper {
        background-color: $servicos_default-bgColor;
        color: $servicos_default-color;
        font-size: $servicos_default-base-font-size;
        height: 100%;

        & > .content {
            background-color: inherit;
            color: inherit;
            font-size: inherit;
            padding: $appContent_defaultVerticalPadding $appContent_horizontalPadding;

            min-width: 520px;

            .header-card {
                @include es-card-envelope(0 0 15px 0, 15px 20px, 8px) {
                    background-color: $servicos_default-header-bgColor;

                    @include es-default-header-content(auto, 120px, 2rem, $servicos_default-header-color);
                }
            }

            .content-section {
                .section-header-wrapper {
                    & {
                        position: relative;
                    }
            
                    h2 {
                        color: inherit;
                        text-align: left;
                        margin-top: 1rem;
                        margin-bottom: 0;
                        font-size: 1.3em;
                    }
                }
            
                .links-wrapper {
                    margin-top: 1.5em;

                    .item-link {
                        @include es-form-button();

                        & {
                            margin-bottom: 0.5em;
                        }

                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            line-height: 2.45em;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}    

#root > .app-viewport.external-service {
    .admin-menu-wrapper {
        & > .content {
            .header-card .header-wrapper > .header-content {
                h1 {
                    font-size: 2.6em;
                }
            }
        }
    }
}
