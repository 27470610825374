/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'solicitacaoAgendamentoConsultaAdm' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .solicitacao-agendamento-consulta-adm-wrapper {
        background-color: $servicos_default-bgColor;
        color: $servicos_default-color;
        font-size: $servicos_default-base-font-size;
        height: 100%;

        width: 100%;
        margin: 0;
        padding: 0;
        overflow-x: auto !important;

        .btn-export {
            @include es-form-button();
        }

        .information-card {
            &.info-success {
                @include message-box($main_information-bgColor, $main_color) {
                    margin-top: $appContent_defaultVerticalPadding;
                }
            }

            &.info-error {
                @include message-box($main_warning-color, $main_color) {
                    margin-top: $appContent_defaultVerticalPadding;
                }
            }
        }

        & > .content-wrapper {
            background-color: inherit;
            color: inherit;
            font-size: inherit;
            padding: $appContent_defaultVerticalPadding $appContent_horizontalPadding;

            min-width: $app_minWidth;

            .content-box {
                .ui.form {
                    font-size: inherit;
                }

                .header-card {
                    @include es-card-envelope(0 0 15px 0, 15px 20px, 8px) {
                        background-color: $servicos_default-header-bgColor;

                        @include es-default-header-content(auto, 120px, 2rem, $servicos_default-header-color) {
                            .header-content {
                                h2 {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }

                .primary-card {
                    @include es-card-envelope(15px 0px, 20px 20px, 6px) {
                        background-color: $servicos_default-card-bgColor;
                    }

                    .section-instruction {
                        text-align: left;

                        .instruction-message {
                            font-size: inherit;
                            color: inherit;
                        }
        
                        .instruction-required {
                            margin-top: 10px;
                            font-size: inherit;
                            color: $servicos_form-field-required-mark-color;
                        }
                    }

                    .form-wrapper {
                        margin-top: 15px;
                        
                        .form-export {
                            color: inherit;
        
                            .field-error {
                                color: $servicos_form-field-error-message-color;
                                padding: 0px;
                            }
            
                            .group-card { // Estilos e lay-out dos agrupamentos e grid
                                color: inherit;
                                text-align: left;
                                overflow: visible;

                                border-radius: 4px;
                                border: 1px solid $servicos_form-field-border-color;
                                padding: 10px 15px;

                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                }

                                & > ._djfb_grid-group-title {
                                    margin-top: 5px;
                                    color: $servicos_default-color;
                                    font-weight: 700;
                                }

                                & > ._djfb_grid-group-description {
                                    color: lighten($servicos_default-color, 15%);
                                    font-size: inherit;
                                }
                            }

                            @include es-form-servicos();  // Estilos dos campos dos formulário

                            .rb-wrapper {
                                &:not(:first-of-type) {
                                    padding-top: 2px;
                                }

                                &:not(:last-of-type) {
                                    padding-bottom: 2px;
                                }
                            }

                            // Estilos dos campos/grupos
                            .subgroup-card-idented {
                                margin-left: 29px;
                            }

                            .group-tipo-notificacao, .group-codigo-paciente, .group-contato, .group-periodo, .group-configuracao {
                                ._djfb_grid-group-title {
                                    font-style: italic;
                                    font-weight: 600;
                                }
                            }

                            .group-codigo-paciente, .group-contato, .group-periodo, .group-configuracao {
                                margin-top: 5px;
                            }

                            .group-tipo-notificacao {
                                margin-top: 15px;
                            }

                            .group-contato {
                                & > ._djfb_grid-group-content > ._djfb_grid-cell:not(:last-child) {
                                    margin-right: 15px;
                                }
        
                                .email-contato {
                                    width: 270px;
                                }
        
                                .telefone-ddd {
                                    width: 65px;
                                }
        
                                .telefone-numero {
                                    width: 120px;
                                }
                            }
                            
                            .group-periodo, .group-configuracao {
                                ._djfb_grid-group-content {
                                    & > ._djfb_grid-cell:not(:last-child) {
                                        margin-right: 15px;
                                    }
                                }
                            }

                            .group-periodo {
                                ._djfb_grid-cell {
                                    $date-field-width: 145px;
                                    $date-picker-wrapper-width: calc(#{$formBuilder_date-picker-button-width} + #{$formBuilder_date-picker-button-margin-left});
                                    $date-input-width: calc(#{$date-field-width} - #{$date-picker-wrapper-width});
                                    ._djfb_grid-field-wrapper > ._djfb_grid-column {
                                        min-width: $date-field-width;
                                        width: $date-field-width;
                    
                                        input {
                                            width: $date-input-width;
                                        }
                                    }
                                }
                            }

                            .group-configuracao {
                                .config-lote {
                                    width: 205px;
                                }

                                .config-sleep {
                                    width: 260px;
                                }

                                .config-max {
                                    width: 200px;
                                }
                            }
        
                        }
                    }
                }

                .action-section {
                    margin-top: 15px;
                    padding: 15px 0;
                    text-align: center;
                }
            }
        }
    }

    &.mobile {
        .solicitacao-agendamento-consulta-adm-wrapper {
            @include scrollbar-hide();
            & > .content-wrapper {
                padding: $appContent_defaultVerticalPadding $appContent_mobile-horizontalPadding;
            }
        }
    }

}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
@mixin header-responsive($logo-width, $font-size-title, $font-size) {
    .header-wrapper {
        .logo-wrapper .logo-box { 
            width: $logo-width !important;
        }
        .header-content {
            h1 { font-size: $font-size-title !important; }
            h2 { font-size: $font-size !important; }
        }
    }
}

#root > .app-viewport.external-service {
    .solicitacao-agendamento-consulta-adm-wrapper {
        & > .content-wrapper > .content-box { 
            @media screen and (max-width: 400px) {
                @include header-responsive(95px, 2.1rem, 0.85rem);
            }

            @media screen and (min-width: 400px) and (max-width: 500px) {
                @include header-responsive(110px, 2.2rem, 1.0rem);
            }

            @media screen and (min-width: 500px) and (max-width: 600px) {
                @include header-responsive(120px, 2.8rem, 1.25rem);
            }
        }
    }
}