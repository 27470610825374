/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'cadastro' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('cadastro') {
    #termosModalWrapperId {
        font-size: 0.9rem;
        color: $main_color;

        .modal-header-wrapper {
            height: calc(#{$appCard_action-bar-buttonSize} + 1em);
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            & > .close-button {
                margin-right: 1em;
                width: $appCard_action-bar-buttonSize;
                height: $appCard_action-bar-buttonSize;

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }

        .termos-content-wrapper {
            & > .termos {
                h3 { 
                    color: inherit !important;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .cadastro-instrucoes {
        margin-top: 1em;

        .instrucoes-content {
            text-align: left;
            font-size: 1em;
        }
    }

    .cadastro-form {
        @include mc-form-principal();

        .float-label-field {
            margin-bottom: 0;
        }

        .field-separator {
            height: 1rem;
        }

        .field-error {
            text-align: left;
            font-size: 0.75em;
            color: $main_warning-color;
            padding: 0 5px;
        }

        .senha {
            .has-content.invalid ~ .field-error {
                margin-top: -5px;
                margin-left: -5px;
            }
        }

        .aceite-termos {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 2em;
            font-size: inherit;
                            
            .checkbox-termos {
                .ui.checkbox {
                    margin: 0;
                }
            }

            .link-termos-wrapper {
                position: absolute;
                top: 0;
                left: calc(#{$mainForm_field-checkbox-size} + 0.6em);
                font-size: inherit;
                width: 100%;

                #link-termos {
                    font-size: inherit;
                    color: $appCard_forms-color;

                    &:hover {
                        color: darken($appCard_forms-color, 10%);
                    }
                }
            }
        }

        .aceite + .field-error {
            margin-top: -0.75em;
        }
    }

    .cadastro-confirmacao-dados {
        text-align: center;
        overflow: hidden;

        & > .info-principal {
            margin: 1.2em 0;
            font-size: 1.1em;

            @include appFont-bold();
        }

        & > .info-adicional {
            margin: 2em 0;
            font-size: inherit;
        }

        & > .info-row {
            display: flex;
            justify-content: center;
            align-items: baseline;

            &:not(:first-child) {
                margin-top: 0.5em;
            }

            & > .title {
                display: inline-block;
                margin-right: 0.33em;
                font-size: 1em;
                white-space: nowrap;

                @include appFont-bold();
            }

            & > .value {
                display: inline-block;
                font-size: 0.92em;
                text-align: left;

                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }                    
    }
    
    .confirmacao-registro {
        text-align: center;

        & > .info-principal {
            margin: 2.0em 0;
            font-size: 1.3em;
            @include appFont-bold();

            h2 {
                color: inherit;
            }
        }

        & > .info-adicional {
            margin: 2em 0;
            font-size: inherit;
        }

        & > .email-enviado {  
            height: 120px;
            margin: 1.5rem 0;
            text-align: center;

            & > img {
                display: inline-block;
                height: 100%;
                width: auto;
            }
        }
    }

    .main-action {
        padding: 1.5rem 0;

        button:not(:first-of-type) {
            margin-top: 1rem;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
