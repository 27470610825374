/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'teleatendimento' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('teleatendimento') {
    max-width: unset;

    .device-info-wrapper, .instrucoes-wrapper, .sala-espera-wrapper, .video-chamada-wrapper, .closed-connection-wrapper {
        position: relative;
        overflow: hidden;
        padding-bottom: 1.5em;
    }

    .device-info-wrapper, .instrucoes-wrapper, .sala-espera-wrapper, .closed-connection-wrapper {
        width: 100%;
        max-width: $app_default-card-module-content-max-width;
        margin: 0 auto;
    }

    .instrucoes-wrapper {
        text-align: center;
        & > .instrucoes-box {
            margin: 0 auto;
            padding-bottom: 1em;
            max-width: $teleatendimento_instrucoes-max-width;
        }
    }

    .sala-espera-wrapper {
        & > .midia-box {
            margin: 0 auto;
            padding-bottom: 1em;
            max-width: $teleatendimento_informacoes-max-width;

            & .info-carousel-block:not(:first-child) {
                margin-top: 1em;
            }
        }
    }

    .video-chamada-wrapper {
        height: $teleatendimento_meeting-wrapper-height;
        min-height: $teleatendimento_meeting-wrapper-min-height;
        display: flex;
        flex-direction: column;

        .jitsi-root-element {
            height: 100%;
        }
    }

    .feedback-wrapper {
        margin-top: 2em;

        .rating-wrapper {
            margin-top: 1em;
            display: flex;
            justify-content: center;
            align-items: center;

            .thumb-up, .thumb-down {
                width: 64px;
                height: 64px;
                border-radius: 32px;

                &.selected {
                    box-shadow: $teleatendimento_feedbackButton-selected-shadow;
                }

                &.disabled:not(.selected) {
                    opacity: 0.5;
                }

                &:not(.disabled) {
                    cursor: pointer;
                }
            }

            .thumb-down {
                margin-left: 4em;
            }
        }

        .thankyou-wrapper {
            padding: 1.5em 0;
            text-align: center;

            .thakyou-message {
                font-size: 2em;
            }
        }

        .more-detail-wrapper {
            @include mc-form-textarea-field($appCard_forms-color, $appCard_forms-bgColor, $appCard_forms-color, 0.9em);

            & {
                margin-top: 1em;
            }

            .textarea-field {
                margin-top: 0.3em;
            }
        }

        .send-button-wrapper {
            margin-top: 0.3em;

            .send-detail-button {
                width: 150px;
            }
        }

        .pesquisa-satisfacao-link-wrapper {
            margin-top: 2em;
        }
    }

    .main-action {
        margin-top: 1em;
        & :not(:first-child) {
            margin-top: 1em;
        }
    }
}