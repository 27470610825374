/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'ativarUsuario' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .ativar-usuario-wrapper {
        background-color: $main_bgColor;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        width: 100%;

        & > .content-wrapper {
            & > .content-box {
                display: inline-block;
                padding: $appContent_defaultVerticalPadding 0;
                width: 75%;
                max-width: $app_default-auth-max-width;

                & > .header {
                    flex-grow: 0;
                    padding: 0;
                    font-size: inherit;

                    .app-logo {
                        margin: 0;
                        width: 100%;
                        text-align: center;

                        img {
                            display: inline-block;
                            height: 70px;
                            width: auto;
                            vertical-align: middle;
                        }
                    }
                }

                & > .body {
                    text-align: center;
                    padding: 1.5rem 0 0 0;
                    font-size: inherit;
            
                    .info-principal {
                        margin-top: 2rem;
                        font-size: inherit;
                        
                        h2 {
                            font-size: 1.7em !important;
                        }
                    }

                    .info-adicional {
                        margin-top: 1rem;
                        font-size: 1em;

                        p span {
                            padding: 0;
                        }
                    }

                    .status-ativacao {  
                        height: 200px;
                        margin: 1.5rem 0;
                        text-align: center;

                        & > img {
                            display: inline-block;
                            height: 100%;
                            width: auto;
                        }
                    }

                    .main-action {
                        margin-top: 25px;
                    }
    
                    .reenviar-email-ativacao {
                        margin-top: 20px;
    
                        span {
                            padding: 0;
                        }
    
                        a {
                            font-size: 0.9em;
                        }
                    }

                    .mensagem-wifi {
                        margin-top: 1.5em;
                    }
                }        
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .ativar-usuario-wrapper {
        & > .content-wrapper {
            & > .content-box {
                @media screen and (max-width: 390px) {
                    width: 90% !important;
                    font-size: 0.9rem;

                    .body { 
                        padding-top: 1.2rem !important;

                        .info-principal { margin-top: 1.5rem !important; }
                        .status-ativacao { 
                            height: 180px !important;
                            margin: 1.2rem 0 !important; 
                        }
                    }
                }
            }
        }
    }
}