/* Import common definition styles */
@import '../../../../styles/common.scss';


.file-select-list {
    padding: 0;
    margin: 0;

    .file-confirmation-form {
        width: 100%;

        @include mc-form-input-float-label($appCard_forms-color, $appCard_forms-bgColor, $appCard_forms-color, $appCard_forms-color, inherit);

        .file-information {
            label {
                margin-right: 0.5em;
                @include appFont-bold();
            }
        }
    }

    & > .mfs-elem-grid {
        overflow: visible;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin-right: calc(-1 * #{$multiFileSelect_fileButton-spacing});
        margin-bottom: calc(-1 * #{$multiFileSelect_fileButton-spacing});

        &::after {
            content: "";
            flex: auto;
        }

        .mfs-button {
            position: relative;
            height: $multiFileSelect_fileButton-height;
            width: $multiFileSelect_fileButton-width;
            margin-bottom: $multiFileSelect_fileButton-spacing;
            margin-right: $multiFileSelect_fileButton-spacing;

            &.file-button {
                .file-remove-button {
                    cursor: pointer;
                    overflow: hidden;
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    color: $main_color;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        opacity: 0.8 !important;
                    }

                    &:not(.enabled) {
                        display: none;
                    }

                    & > img {
                        height: auto;
                        width: 100%;
                    }
                }

                & > .file-type-img-wrapper {
                    color: black;
                    width: 100%;
                    height: 100%;
                }

                &.sent > .file-type-img-wrapper {
                    opacity: 0.6;
                }

                &.new > .file-type-img-wrapper {
                    opacity: 1;
                }

            }

            &.add-button {
                & > img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
                
                &:not(.disabled) {
                    & > img {
                        cursor: pointer;
                    }
                }

                &.disabled {
                    opacity: 0.5;
                }
            }
        }

        .file-add-wrapper {
            margin-right: $multiFileSelect_fileButton-spacing;
            margin-bottom: 1em;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .file-description {
                margin-right: 0.5em;
                width: 100%;
            }

            .confirmation-ok {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}
