/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------
 *  Estilos específicos do 'privacyPolicy'
 * ---------------------------------------
**/
#root > .app-viewport.external-service {
    .privacy-policy-wrapper {
        background-color: $servicos_default-bgColor;
        color: $servicos_default-color;
        font-size: 1rem;
        height: 100%;

        & > .content {
            background-color: inherit;
            color: inherit;
            font-size: inherit;
            padding: 1rem 1rem;
            min-width: 320px;

            & > .header-card {
                @include es-card-envelope(0 0 15px 0, 15px 20px, 8px) {
                    background-color: $servicos_default-header-bgColor;

                    @include es-default-header-content(auto, 120px, 2rem, $servicos_default-header-color) {
                        & > .header-content h1 {
                            font-size: 2.5rem;
                        }
                    }
                }
            }

            & > .body {
                padding: 15px 15px 0 15px;
                text-align: left;

                h3 {
                    color: inherit;
                }

            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
@mixin header-responsive($logo-width, $font-size) {
    .header-wrapper {
        .logo-wrapper .logo-box { 
            width: $logo-width !important;
        }
        .header-content {
            h1 { font-size: $font-size !important; }
        }
    }            
}

#root > .app-viewport.external-service {
    .privacy-policy-wrapper {
        & > .content > .header-card {
            @media screen and (max-width: 400px) { 
                @include header-responsive(90px, 1.3rem);
            }
            @media screen and (min-width: 401px) and (max-width: 520px) {
                @include header-responsive(100px, 1.6rem);
            }
            @media screen and (min-width: 521px) and (max-width: 580px) {
                @include header-responsive(120px, 1.8rem);
            }
            @media screen and (min-width: 581px) and (max-width: 660px) {
                @include header-responsive(120px, 2.2rem);
            }
        }
    }
}
