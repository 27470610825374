/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'viewerModal' module specific styles
 * ---------------------------------------------------------
**/
@mixin viwer-modal($modal-position: fixed,
            $modal-header-height: $viewerModal_default-header-height, 
            $modal-footer-height: $viewerModal_default-footer-height, 
            $zindex: 7000) {

    $modal-content-height: calc(100% - #{$modal-header-height} - #{$modal-footer-height});
    & div.vw-modal-wrapper {
        background-color: $main_viewer-bgColor;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 1s;
        z-index: $zindex;
        position: $modal-position;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-width: $app_minWidth;
        cursor: default;

        @include no-select();
    
        img {
            opacity: 1;
        }

        .vw-modal-header, .vw-modal-header_custom {
            height: $modal-header-height;
            width: 100%;
        }

        .vw-modal-header {
            background-color: $main_bgColor; 
            margin: 0;
            padding: 0;

            .vw-modal-header-content {
                display: block;
                height: 100%;
                margin-right: 3rem;
            }
            
            .vw-modal-close {
                @include round-cross-btn(10) {
                    top: calc(#{$modal-header-height} / 2);
                    right: 0.75rem;
                    transform: translateY(-50%);

                    &:hover, &:focus {
                        .close {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
        
        .vw-modal-body {
            height: $modal-content-height;
            overflow-x: hidden; 
            overflow-y: scroll;

            @include scrollbar-custom-style($app_default-scrollbar-width,
                $viewerModal_sbtrack, $viewerModal_sbtrack-shadow,
                $viewerModal_sbthumb, $viewerModal_sbthumb-hover);

            & .vw-modal-body-content {
                box-sizing: content-box;

                @content;
            }
        }

        .vw-modal-footer, .vw-modal-footer_custom {
            height: $modal-footer-height;
            width: 100%;
            position: $modal-position;
            bottom: 0px;
        }

        .vw-modal-footer {
            background-color: $main_bgColor; 
        }
    }
}

div#viwerModalId {
    @include viwer-modal();
}
