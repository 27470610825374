/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'termoConsentimento' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('termo-consentimento') {
    & > .information-section {
        margin-top: 1em;
        text-align: center;

        .info-header {
            font-size: 1.05em;
            @include appFont-bold();
        }

        .info-update {
            margin-top: 1em;
        }

        .main-info {
            margin-top: 1em;
            font-size: 1em;
        }

        .visualizar-termo {
            margin-top: 1em;
            display: flex;
            flex-direction: column;
            justify-content: center;

            & div {
                align-self: center;
                width: 250px;
            }
        }
    }

    & > .answer-section {
        margin-top: 2.5em;
        text-align: center;

        & > .answer-box {
            display: inline-block;
            text-align: left;

            .data-hora-resposta {
                margin-top: 0;
            }

            .message {
                margin-top: 1em;
                font-size: 1em;
            }

            .form-resposta {
                @include mc-form-radio-field($main_color, $main_placeholderColor, $main_bgColor, $main_bgColor, $mainForm_field-radio-size);

                .radio-sim, .radio-nao {
                    & > .ui.radio.checkbox {
                        margin: 0.25em 0;

                        label {
                            padding-top: 0;
                            font-size: 1em;
                        }
                    }
                }

                .radio-field {
                    margin-left: 1em;
                }

                .radio-field:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .main-action {
        margin-top: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & div {
            align-self: center;
            width: 250px;

            &:not(:first-child) {
                margin-top: 1em;
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
