/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'faqEditGrid' module specific styles
 * ---------------------------------------------------------
**/

div#faqEditGridWrapper {
    margin-top: 1em;

    .faq-item-wrapper {
        position: relative;
        display: flex;
        padding: 0.3em 0;
        justify-content: space-between;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px;
        border-color: $servicos_default-header-bgColor;

        &:first-child {
            border-top: solid 1px;
        }

        &.changed {
            background-color: $servicos_default-item-changed-color;
        }

        &.hide-from-list {
            background-color: inherit;
        }

        & > .inset-before, & > .inset-after {
            background-color: $servicos_default-header-bgColor;
            color: $servicos_form-info-button-color;

            position: absolute;
            display: inline-block;
            border-radius: 7px;
            width: 14px;
            height: 14px;
            line-height: 14px;
            left: 50%;
            padding-left: 1px;
            cursor: pointer;
        }

        & > .inset-before {
            top: -0.5em;
        }

        & > .inset-after {
            bottom: -0.5em;
        }

        & > .item-info-wrapper {
            display: flex;
            align-items: center;

            .order-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .current-order-wrapper {
                    position: relative;
                    width: 35px;

                    .order-value {
                        background-color: darken($servicos_default-bgColor, 5%);
                        font-size: 0.8em;
                        cursor: pointer;
                    }

                    .edit-order-wrapper {
                        background-color: $servicos_default-header-bgColor;
                        position: absolute;
                        padding: 5px 0.5em;
                        border: none;
                        border-radius: 5px;
                        top: -5px;
                        left: 100%;
                        z-index: 1;

                        display: flex;

                        &:not(.enabled) {
                            display: none;
                        }

                        input {
                            border: solid 1px;
                            width: 40px;
                            font-size: 0.8em;

                            &:focus {
                                outline: none;
                            }
                        }

                        .change-order-ok {
                            background-color: $servicos_default-header-color;
                            color: $servicos_default-header-bgColor;
                            border-radius: 6px;
                            padding-left: 0.5em;
                            padding-right: 0.5em;
                            margin-left: 0.4em;
                            line-height: 1.8em;

                            @include appFont-bold();

                            font-size: 0.8em;
                            text-transform: uppercase;

                            &.enabled {
                                cursor: pointer;
                            }

                            &:not(.enabled) {
                                opacity: 0.5;
                            }
                        }
                    }
                }

                .order-change-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    margin-left: 0.4em;

                    .order-up, .order-down {
                        width: 10px;
                        height: 8px;
                        line-height: 10px;
                        color: $servicos_form-info-button-color;

                        img {
                            height: 100%;
                            width: 100%;
                        }

                        &.disabled {
                            visibility: hidden;
                            cursor: not-allowed;
                        }

                        &:not(.disabled) {
                            cursor: pointer;
                        }
                    }

                    .order-down {
                        margin-top: 0.3em;
                    }
                }
            }

            .item-title {
                margin-left: 0.75em;
                text-align: left;
            }
        }

        & > .item-buttons-wrapper {
            display: flex;
            align-items: center;
            margin-left: 1em;

            $item-button_size: 20px;
            .id-mark, .hide-from-list-mark, .tags-mark, .image-mark, .item-preview, .item-edit, .item-delete {
                display: inline-block;
                width: $item-button_size;
                height: $item-button_size;
                font-size: 0.8em;

                &:not(:first-child) {
                    margin-left: 0.33em;
                }
            }

            .item-preview, .item-edit, .item-delete { 
                cursor: pointer;
            }
        }
    }

    .action-buttons {
        margin-top: 1.5em;

        .btn-save {
            @include es-form-button();
        }
    }
}
