/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appInitializing' module specific styles
 * ---------------------------------------------------------
**/

#root {
    #initializingWrapperId {
        background-color: $main_bgColor;
        color: $main_color;
        position: fixed;
        width: 100%;
        height: 100%;
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
