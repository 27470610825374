/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'confirmarTelefone' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('confirmar-telefone') {
    text-align: center;

    .info-principal {
        margin-top: 2rem;
        font-size: inherit;
        font-size: 1.55em;
    }

    .info-adicional {
        margin-top: 1em;
        font-size: 1em;
    }

    .confirmar-telefone-form {
        margin-top: 2.5em;

        .token-input-wrapper {
            display: flex;
            justify-content: center;

            .sms-token {
                display: inline-block;
                text-align: center;
                width: 200px;
                
                input {
                    text-align: center;
                    background-color: transparent !important;
                    color: $appCard_normal-color;
                    caret-color: $appCard_normal-color;

                    @include form-style-controls-selection("&", $main_selectionColor, darken($appCard_normal-color, 25%));

                    &, &:focus, &:active {
                        letter-spacing: 0.5rem !important;
                        font-size: 3rem !important;
                        height: 5rem !important;
                        padding: 0 !important;
                        border: 0 !important;
                    }
                }
            }
        }    
    }

    .main-action {
        padding-top: 1.5rem;

        &:not(:empty) {
            padding-bottom: 1.5rem;
        }

        & > div:not(:first-child) {
            margin-top: 1em;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
