/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'userHome' module specific styles
 * ---------------------------------------------------------
**/

@mixin calc-user-home-height($navCoverHeight: $navBar_cover-height, $navSmallCoverHeight: $navBar_cover-small-height) {
    @media screen and (max-width: $navButton_small-size-maxWidth) {
        height: calc(100vh - #{$appHeader_fullHeight} - #{$navSmallCoverHeight}); /* Browsers that do not support custom properties */
        height: calc((var(--vh, 1vh) * 100) - #{$appHeader_fullHeight} - #{$navSmallCoverHeight});
    }

    @media screen and (min-width: $navButton_small-size-maxWidth) {
        height: calc(100vh - #{$appHeader_fullHeight} - #{$navCoverHeight}); /* Browsers that do not support custom properties */
        height: calc((var(--vh, 1vh) * 100) - #{$appHeader_fullHeight} - #{$navCoverHeight});
    }

    & {
        /* workaround para resolver problema do iOs de scroll do que está atrás */
        -webkit-transform: translateZ(0);
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-perspective: 1000;
    }
}

#root > .app-viewport {
    .user-home-wrapper {
        background-color: transparent;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;

        &.no-nav-bar, &.hide-nav-bar {
            @include calc-user-home-height(1px, 1px); /* if cover height were 0 fails to identify scrollbar visibility */
        }

        &:not(.no-nav-bar):not(.hide-nav-bar) {
            @include calc-user-home-height($navBar_cover-height, $navBar_cover-small-height); 
        }

        & > .content-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;

            & > .content-box {
                width: 100%;
                max-width: $app_default-card-module-content-max-width;
            
                .identificacao-usuario {
                    margin-bottom: 1em;
                    text-align: center;

                    @include appFont-black();
                }

                .news-section, .notification-section, .service-menu-section, .empty-section {
                    margin-top: 1em;
                }

                .news-section {
                    .content-row {
                        &:not(:first-child) {
                            margin-top: 0.75em;
                        }
                    }
                }

                .notification-section {
                    .content-row {
                        padding: 0.25em 1em;
                        background-color: $userHome_notice-bgColor;
                        color: $userHome_notice-color;

                        &:not(:first-child) {
                            margin-top: 0.2em;
                        }

                        &.notice {
                            a {
                                display: flex;
                                color: inherit;
                                text-decoration: none;
                            }

                            .notice-icon {
                                height: 18px;
                                width: auto;
                                margin-right: 0.5em;
                            }
                        }
                    }
                }

                .service-menu-section {
                    .buttons-wrapper {
                        background-color: transparent;
                        padding: 1em 1em 0 1em;
                    }
                }

                .empty-section {
                    & > .title {
                        background-color: $accordion_default-title-bgColor;
                        color: inherit;
                        padding: 0.75rem 1em;
                        text-align: left;
                    }
                }
            }
        }
    }
}


/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    &:not(.mobile) {
        & .user-home-wrapper {
            padding: 0 $appContent_horizontalPadding;
            @include scrollbar-style();
        }
    }

    &.mobile {
        & .user-home-wrapper {
            padding: 0 $appContent_mobile-horizontalPadding;
            @include scrollbar-hide();
        }
    }
}
