/* -----------------------------------------------
 * APPLICATION - MIXIN FORM LIBRARY
 * -----------------------------------------------
 */
@mixin form-style-controls-states() {
    &:active, &:focus, &:hover {
        @content;
    }
}

@mixin form-style-controls-autofill($multi-selector: "input, textarea, select", 
            $border-color: inherit, $box-shadow: inherit, $text-color: inherit, $font-size: 1rem) {
    #{$multi-selector} {
        &:-webkit-autofill, &:autofill {
            &, &:active, &:hover, &:focus {
                color: $text-color;
                caret-color: $text-color;
                border-color: $border-color;
                -webkit-text-fill-color: $text-color;
                box-shadow: $box-shadow !important;
                -webkit-box-shadow: $box-shadow !important; 

                @include appFont-default();
                font-size: $font-size;
            }

            &::first-line {
                &, &:active, &:hover, &:focus {
                    font-family: inherit;
                    font-size: inherit;
                }
            }

            @content;
        }
    }
}

@mixin form-style-controls-placeholder($multi-selector: "input, textarea, select", $color: $main_placeholderColor) {
    #{$multi-selector} {
        &::-webkit-input-placeholder, &::-moz-placeholder, &::-ms-placeholder { 
            color: $color;
        }
        &::placeholder {
            color: $color;
            opacity: 1; /* Firefox */
        }
    }
}

@mixin form-style-controls-selection($multi-selector: "input, textarea, select", 
            $selection-color: $main_selectionColor, $text-color: $main_color) {
    #{$multi-selector} {
        &::selection{
            background: $selection-color;
            color: $text-color;
        }
        
        &::-moz-selection{
            background: $selection-color;
            color: $text-color;
        }
    }
}

@mixin mc-compact-button() {
    @include mc-form-button() {
        & {
            width: 100%;
            height: auto;
            font-size: 0.8rem;
            line-height: 1em;
            padding: 0.90em 3.5em 0.8em 3.5em;
            border-radius: 6px;

            @content;
        }
    }
}

@mixin mc-confirmation-button() {
    @include mc-form-button() {
        & {
            height: auto;
            font-size: 0.8rem;
            line-height: 1rem;
            padding-top: 0.9em;
            padding-bottom: 0.8em;
            border-radius: 6px;

            @content;
        }
    }
}

@mixin mc-filter-input($text-color, $bg-color, $border-color, $font-size: $mainForm_base-font-size) {
    .filter-input {
        input {
            background-color: $bg-color;
            color: $text-color;
            border-color: $border-color;
            font-size: $font-size;
            font-family: inherit;
            border-radius: 0;
            padding: 0.33em 0.5em;
            height: auto;

            @include form-style-controls-states() {
                border-color: darken($border-color, 25%) !important;
            }
        }

        @include form-style-controls-autofill("input", $border-color, 0 0 0px 30px $bg-color inset, $text-color, $font-size);
        @include form-style-controls-selection("input", $main_selectionColor, $text-color);
        @include form-style-controls-placeholder("input", $main_placeholderColor);

        @content;
    }
}

@mixin mc-form-button($bg-color: $formButton_bgColor, $text-color: $formButton_color,
            $hover-bg-color: $formButton_hover-bgColor, $hover-color: $formButton_hover-color, 
            $dis-bg-color: $formButton_disable-bgColor, $dis-color: $formButton_disable-color, 
            $active-border-color: $formButton_active-border-shadow,
            $height: 2.45rem, $width: 100%) {

    height: $height;
    width: $width;
    border-radius: 10px;
    background-color: $bg-color;
    color: $text-color;
    border: none;
    text-transform: uppercase;
    @include appFont-bold();
    
    &:disabled {
        background-color: $dis-bg-color;
        color: $dis-color;
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        &:not(:disabled) {
            background-color: $hover-bg-color;
            color: $hover-color;
            cursor: pointer;
        }
    }

    &:focus, &:active {
        color: $hover-color;

        -webkit-box-shadow: $active-border-color;
        -moz-box-shadow: $active-border-color;
        box-shadow: $active-border-color;
    }

    @content;
}

@mixin mc-form-input-float-label($text-color, $bg-color, $border-color, $label-color, $font-size: 1rem) {
    .float-label-field {
        margin-top: calc(1rem + #{$mainForm_field-vertical-margin});
        margin-bottom: $mainForm_field-vertical-margin;
        position: relative;
        
        i:not(.ignore-form-style) {
            font-size: $font-size;
            color: $label-color !important;
            opacity: 1 !important;
            margin-top: -2px !important;
        }
    
        input {
            font-family: inherit !important;
            color: $text-color !important;
    
            &, &:focus, &:not([type]):focus {
                font-size: $font-size;
                height: 1.9rem;
                padding-left: 3px;
                margin-top: 2px;
    
                border: none;
                border-bottom: $mainForm_field-border-width solid $border-color;
                background-color: $bg-color !important;
                border-radius: 0%;
            }

            @include form-style-controls-states() {
                border-color: lighten($border-color, 10%) !important;
                outline: none;
            }
        }
    
        .input-wrapper, .masked-input-wrapper {
            width: 100%;
        }
        
        .ui.fluid.input {
            border: none;
        }
    
        /* label como "placeholder" */
        .ui.label.label {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            pointer-events: none;    
            color: $label-color;
            font-size: $font-size;
            font-weight: 400;
            overflow: hidden;
    
            padding: 0 !important;
            margin-left: 2px;
            margin-top: 5px;
            line-height: 1.3em;
            background-color: $bg-color; /* Necessario para tapar placeholders (datas) */
        }

        &:not(.non-floating-placeholder) .ui.label.label {
            transition: 0.2s ease all;
        }

        /* label suspenso */
        .has-content .ui.label.label,
        .empty input:focus ~ .ui.label.label {
            opacity: 1;
            font-size: calc(max(calc(0.72 * #{$font-size}), 0.72rem)) !important;
            top: -14px !important;
            padding: 0 !important;
            border-radius: 2px;
            margin-top: 0px !important;
            font-weight: 700;
        }

        &.non-floating-placeholder {
            .has-content .ui.label.label,
            .empty input:focus ~ .ui.label.label {
                visibility: hidden;
            }
        }

        /* validacao */
        .invalid {
            input {
                &, &:focus, &:not([type]):focus {
                    border-bottom-color: $main_warning-color;
                }
            }                    

            .ui.fluid.input {
                border-bottom-color: $main_warning-color !important;
            }

        }

        @include form-style-controls-autofill("input", $text-color, 0 0 0px 30px $bg-color inset, $text-color, $font-size);
        @include form-style-controls-selection("input", $main_selectionColor, $text-color);
        @include form-style-controls-placeholder("input", $main_placeholderColor);

        @content;
    }
}

@mixin mc-form-textarea-field($text-color, $bg-color, $border-color, $font-size: 1rem) {
    .textarea-field {
        margin: $mainForm_field-vertical-margin 0;
        font-family: inherit;

        textarea {
            font-family: inherit;
            font-size: $font-size;
            padding: 7px 10px;
            background-color: $bg-color;
        }

        .input-counter {
            text-align: right;
        }

        .input-wrapper:not(.disabled) {
            textarea {
                color: $text-color;
                border-color: $border-color;
                border-width: $mainForm_field-border-width;
                border-radius: 0;

                @include form-style-controls-states() {
                    border-color: lighten($border-color, 10%);
                }
            }

            .input-counter {
                color: $text-color;
                
                .count-current {
                    &.zero { color: $text-color; }
                    &.valid { color: $main_success-color; }
                    &.invalid { color: $main_warning-color; }
                }
    
                .count-max {
                    color: $main_success-color;
                }
            }
        }

        .input-wrapper.disabled {
            .input-counter {
                color: $mainForm_field-disabled-color;
                .count-current, .count-max {
                    color: inherit;
                }
            }
        }

        @include form-style-controls-autofill("textarea", $text-color, 0 0 0px 30px $bg-color inset, $text-color, $font-size);
        @include form-style-controls-selection("textarea", $main_selectionColor, $text-color);
        @include form-style-controls-placeholder("textarea", $main_placeholderColor);

        @content;
    }
}

@mixin mc-form-checkbox-field($text-color, $border-color, $check-color, $size: $mainForm_field-checkbox-size) {
    .checkbox-field {
        font-size: inherit;

        $checkbox-fontsize: calc(#{$size} - 4px);
        $checkbox-label-left-space: calc(#{$size} + #{$mainForm_field-label-left-margin});
        .ui.checkbox {
            font-size: inherit;
            line-height: $size;
            margin: $mainForm_field-vertical-margin 0;
            
            & label {
                font-size: inherit;
                padding-left: $checkbox-label-left-space;
                color: $text-color;

                &::before, &::after { 
                    height: $size;
                    width: $size;
                }

                &::before {
                    border-color: $border-color;
                    border-radius: 1px;
                    border-width: $mainForm_field-border-width;
                }

                &::after { 
                    font-size: $checkbox-fontsize;
                    color: $check-color !important;
                }
            }

            &:not(.disabled) label {
                @include form-style-controls-states() {
                    &::before {
                        border-color: lighten($border-color, 10%);
                    }
                }
            }
        }

        @content;
    }
}

@mixin mc-form-radio-field($text-color, $border-color, $bullet-color, $toggle-slider-color, $size: $mainForm_field-radio-size) {
    .radio-field {
        font-size: inherit;
        margin: $mainForm_field-vertical-margin 0;

        $radio-fontsize: calc(#{$size} - 4px);
        $radio-label-left-space: calc(#{$size} + #{$mainForm_field-label-left-margin});
        .ui.radio {
            font-size: inherit;
            min-width: $size;
            min-height: $size;
            line-height: $size;
            
            & label {
                font-size: inherit;
                padding-left: $radio-label-left-space;
                color: $text-color;
                height: $size;

                &::before, &::after { 
                    height: $size;
                    width: $size;
                }

                &::before {
                    border-color: $border-color;
                    border-width: $mainForm_field-border-width;
                }

                &::after { 
                    font-size: $radio-fontsize;
                    background-color: $bullet-color;
                }
            }

            &:not(.disabled) label {
                @include form-style-controls-states() {
                    &::before {
                        border-color: lighten($border-color, 10%);
                    }
                }
            }
        }

        /* Toggle and Slider */
        .ui.toggle label {
            &::before {
                height: $size;
                border-width: thin;
                border-style: solid;
                border-color: transparentize($border-color, 0.9);
            }
        }

        .ui.slider label {
            &::before {
                margin-top: -1px;
                height: 3px;
            }
        }
    
        .ui.toggle, .ui.slider {
            font-size: inherit;

            & input:checked~label::after {
                left: $size;
            }

            & input:checked~label::before {
                background-color: $toggle-slider-color !important;
            }

            & label {
                font-size: inherit;
                padding-left: calc((2 * #{$size}) + 8px);
                color: $text-color !important;

                &::after {
                    width: $size;
                    height: $size;
                    border-width: $mainForm_field-border-width;
                    border-style: solid;
                    border-color: $border-color;
                }

                &::before {
                    width: calc(#{2 * $size});
                    background-color: $mainForm_field-radio-toggle-slider-unchecked-color;
                }
            }

            &:not(.disabled) label {
                &:active, &:focus, &:hover {
                    &::after {
                        box-shadow: 0px 0px 2px 0 $border-color;
                        -webkit-box-shadow: 0px 0px 2px 0 $border-color;
                    }
                    &::before {
                        background-color: $mainForm_field-radio-toggle-slider-hover-color;
                    }
                }
            }
        }

        @content;
    }
}

@mixin mc-form-dropdown-field($text-color, $bg-color, $border-color, $font-size: 1rem) {
    .dropdown-field {
        margin: $mainForm_field-vertical-margin 0;
        font-size: $font-size;
        line-height: $font-size;

        $single-height: 32px;
        $multiple-min-height: 46px;
        $dd-padding-vert: 5px;
        $dd-padding-horiz: 10px;
        .ui.dropdown {
            font-size: inherit;
            line-height: inherit;
            min-height: $single-height;
            border-color: $border-color;
            background-color: $bg-color;
            border-radius: 0;

            .text {
                color: $text-color;
            }

            .default.text {
                color: $main_placeholderColor;
            }

            .menu {
                padding: $dd-padding-vert 0 !important;
                max-height: 200px;
                overflow-y: auto;
                background-color: $bg-color;
                border-color: $border-color;
                border-radius: 0;
                border-top-width: 1px !important;

                .item {
                    padding-top: 6px !important;
                    padding-bottom: 6px !important;
                    border-color: $bg-color;
                    background-color: inherit;

                    &:hover {
                        background-color: darken($bg-color, 15%);
                    }

                    span.text {
                        padding-left: 0px !important;
                    }
                }
            }

            a {
                color: $text-color !important;
            }

            i.delete.icon {
                color: $text-color !important;   
            }

            i.dropdown.icon {
                color: $text-color !important;
                margin: 0px;
                padding: 0;
                padding-left: 5px;
                top: 50%;
                transform: translateY(-50%);
                height: 1em;
            }

            & > input.search {
                color: $text-color !important;
                height: $single-height;
                font-family: inherit;
                font-size: inherit;
                line-height: inherit;
            }

            & > div.text {
                font-size: inherit;
                line-height: inherit;
                margin: 0 1.2em 0 0;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:not(.multiple) {
                height: $single-height;
                padding: $dd-padding-vert $dd-padding-horiz;

                & > input.search {
                    padding: $dd-padding-vert $dd-padding-horiz;
                }

                .text {
                    padding-top: calc(#{$dd-padding-vert} - 1px);
                }
            }

            &.multiple {
                min-height: $multiple-min-height;
                padding: $dd-padding-vert $dd-padding-horiz;
                
                &.search {
                    padding-bottom: 0;
                }

                & > input.search {
                    margin-top: 2px;
                    margin-left: 0px;
                    padding: 0 0;
                }

                .default.text {
                    padding-left: $dd-padding-horiz;
                    margin-top: 11px;
                }

                a.ui.label {
                    margin-top: 5px !important;

                }
            }

            &:active, &:focus, &:hover {
                &, &:not(.active), & .menu {
                    border-color: lighten($border-color, 10%);
                }
            }
        }

        span {
            background-color: transparent !important;
            color: $text-color !important;
        }

        @include form-style-controls-autofill("select", $text-color, 0 0 0px 30px $bg-color inset, $text-color, $font-size);
        @include form-style-controls-selection("select", $main_selectionColor, $text-color);
        @include form-style-controls-placeholder("select", $main_placeholderColor);

        @content;
    }
}

@mixin mc-form-radio-field-resize($new-radio-size: $mainForm_field-radio-size) {
    .radio-field {
        $radio-fontsize: calc(#{$new-radio-size} - 4px);
        $radio-label-left-space: calc(#{$new-radio-size} + 8px);
        .ui.radio {
            min-width: $new-radio-size !important;
            min-height: $new-radio-size !important;
            line-height: $new-radio-size !important;
            
            & label {
                padding-left: $radio-label-left-space !important;
                height: $new-radio-size !important;

                &:before, &:after { 
                    height: $new-radio-size !important;
                    width: $new-radio-size !important;
                }
                &:after { 
                    font-size: $radio-fontsize !important;
                }
            }
        }
    }
}

@mixin mc-form-principal($font-size: $mainForm_base-font-size) { // Estilos dos campos dos formulários da aplicação
    font-size: $font-size;

    @include mc-form-input-float-label($appCard_forms-color, $appCard_forms-bgColor, $appCard_forms-color, $appCard_forms-color, $font-size);

    @include mc-form-textarea-field($appCard_forms-color, $appCard_forms-bgColor, $appCard_forms-color, $font-size);

    @include mc-form-checkbox-field($appCard_forms-color, $appCard_forms-color, $appCard_forms-color, $mainForm_field-checkbox-size);

    @include mc-form-radio-field($appCard_forms-color, $appCard_forms-color, $appCard_forms-color, $appCard_forms-color, $mainForm_field-radio-size);

    @include mc-form-dropdown-field($appCard_forms-color, $appCard_forms-bgColor, $appCard_forms-color, $font-size);

    .ui.form {
        font-size: inherit;
    }

    .field-required-mark {
        color: $mainForm_field-required-mark-color !important;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: inherit;
    }

    .field-error {
        text-align: left;
        font-size: 0.85em;
    }

    .field-label {
        margin-bottom: 0.33em;
    }

    @content;
}

/* ---------------------------------
** External Services - Specific 
** --------------------------------- */
@mixin es-form-button() {
    @include mc-form-button($servicos_button-bgColor, $formButton_color,
        $servicos_button-hover-bgColor, $formButton_hover-color, 
        $formButton_disable-bgColor, $formButton_disable-color, 
        $servicos_button-active-border-shadow);

    &:disabled {
        opacity: 1;
    }

    @content;
}

@mixin es-form-servicos(
        $font-size: $servicos_default-base-font-size,
        $fld-bgcolor: $servicos_form-field-bgColor, $fld-border-color: $servicos_form-field-border-color,
        $fld-border-hover-color: $servicos_form-field-border-hover-color, $fld-text-color: $servicos_form-field-text-color, 
        $fld-placeholder-color: $servicos_form-field-placeholder-color, $fld-disabled-color: $servicos_form-field-disabled-color, 
        $fld-error-message-color: $servicos_form-field-error-message-color, $fld-success-message-color: $servicos_form-field-success-message-color,
        $fld-vertical-margin: $servicos_form-field-vertical-margin, 
        $fld-input-height: $servicos_form-field-input-height,
        $fld-checkbox-size: $servicos_form-field-checkbox-size, $fld-checkbox-color: $servicos_form-field-checkbox-color, 
        $fld-checkbox-label-color: $servicos_form-field-checkbox-label-color,
        $fld-dropdown-height: $servicos_form-field-dropdown-height,
        $fld-radio-size: $servicos_form-field-radio-size, $fld-radio-bullet-color: $servicos_form-field-radio-bullet-color,
        $fld-radio-label-color: $servicos_form-field-radio-label-color, $fld-radio-toggle-slider-color: $servicos_form-field-radio-toggle-slider-color,
        $fld-required-mark-color: $servicos_form-field-required-mark-color) { // Estilos dos campos dos formulários de serviços extermos

    & {
        font-size: $font-size;
    }

    .ui.form {
        font-size: inherit;
    }

    .field-required-mark {
        color: $fld-required-mark-color !important;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: inherit;
    }

    .field-error {
        text-align: left;
        font-size: 0.85em;
    }

    .input-field, .dropdown-field, .textarea-field {
        margin-top: $fld-vertical-margin;
        margin-bottom: $fld-vertical-margin;
        font-family: inherit;
        font-size: inherit;
    }

    .input-field {
        .input-wrapper, .masked-input-wrapper {
            width: 100%;
        }
    }

    .textarea-field {
        .input-counter {
            text-align: right;
        }

        .input-wrapper:not(.disabled) {
            .input-counter {
                color: $fld-text-color;

                .count-current {
                    &.zero { color: $fld-text-color; }
                    &.valid { color: $fld-success-message-color; }
                    &.invalid { color: $fld-error-message-color; }
                }
    
                .count-max {
                    color: $fld-success-message-color;
                }
            }
        }

        .input-wrapper.disabled {
            .input-counter {
                color: $fld-disabled-color;
                .count-current, .count-max {
                    color: inherit;
                }
            }
        }

    }

    .checkbox-field {
        $checkbox-fontsize: calc(#{$fld-checkbox-size} - 4px);
        $checkbox-label-left-space: calc(#{$fld-checkbox-size} + 8px);
        font-size: inherit;
        
        .ui.checkbox {
            margin-top: $fld-vertical-margin;
            margin-bottom: $fld-vertical-margin;
            line-height: $fld-checkbox-size;
            font-size: inherit;

            & label {
                padding-left: $checkbox-label-left-space;
                color: $fld-checkbox-label-color;

                &::before, &::after { 
                    height: $fld-checkbox-size;
                    width: $fld-checkbox-size;
                }
                
                &::before {
                    border-color: $fld-border-color;
                }

                &::after { 
                    font-size: $checkbox-fontsize;
                    color: $fld-checkbox-color !important;
                }
            }

            &:not(.disabled) label {
                &:active, &:focus, &:hover {
                    &::before {
                        border-color: $fld-border-hover-color;
                        @include es-shadow-thin(true);
                    }
                }
            }
        }
    }

    .radio-field {
        margin-top: $fld-vertical-margin;
        margin-bottom: $fld-vertical-margin;
        font-size: inherit;

        $radio-fontsize: calc(#{$fld-radio-size} - 4px);
        $radio-label-left-space: calc(#{$fld-radio-size} + 8px);
        .ui.radio, .ui.toggle, .ui.slider {
            margin-top: $fld-vertical-margin;
            margin-bottom: $fld-vertical-margin;
        }

        .ui.radio {
            margin-top: $fld-vertical-margin;
            margin-bottom: $fld-vertical-margin;
            min-width: $fld-radio-size;
            min-height: $fld-radio-size;
            line-height: $fld-radio-size;
            font-size: inherit;
            
            & label {
                padding-left: $radio-label-left-space;
                color: $fld-radio-label-color;
                height: $fld-radio-size;

                &::before, &::after { 
                    height: $fld-radio-size;
                    width: $fld-radio-size;
                }
                
                &::before {
                    border-color: $fld-border-color;
                }

                &::after { 
                    font-size: $radio-fontsize;
                    background-color: $fld-radio-bullet-color;
                }
            }

            &:not(.disabled) label {
                &:active, &:focus, &:hover {
                    &::before {
                        border-color: $fld-border-hover-color;
                        @include es-shadow-thin(true);
                    }
                }
            }
        }

        .ui.toggle, .ui.slider {
            font-size: inherit;

            & input:checked~label::before {
                background-color: $fld-radio-toggle-slider-color !important;
            }

            & label {
                padding-left: 60px;
                color: $fld-radio-label-color !important;

                &::after {
                    box-shadow: 0px 0px 4px 0 $fld-border-color;
                    -webkit-box-shadow: 0px 0px 4px 0 $fld-border-color;
                }
            }

            &:not(.disabled) label {
                &:active, &:focus, &:hover {
                    &::after {
                        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px rgba(0, 0, 0, 0.4);
                        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px rgba(0, 0, 0, 0.4);
                    }
                }
            }
        }
    }

    .dropdown-field {
        margin-top: $fld-vertical-margin;
        margin-bottom: $fld-vertical-margin;
        font-size: inherit;

        .ui.dropdown {
            padding: 5px 10px;
            border-color: $fld-border-color;
            background-color: $fld-bgcolor;
            font-size: inherit;

            .text {
                color: $fld-text-color;
            }

            .default.text {
                color: $fld-placeholder-color;
            }

            .menu {
                padding: 5px 0 !important;
                max-height: 200px;
                overflow-y: auto;
                border-color: $fld-border-color;
                background-color: $fld-bgcolor;
                font-size: inherit;

                .item {
                    padding-top: 6px !important;
                    padding-bottom: 6px !important;
                    border-color: $fld-bgcolor;
                    font-size: inherit;

                    span.text {
                        padding-left: 0px !important;
                        font-size: inherit;
                    }
                }
            }

            a {
                color: $fld-text-color !important;
                font-size: inherit;
            }

            i.delete.icon {
                color: $fld-text-color !important;   
            }

            i.dropdown.icon {
                margin: 0px;
                padding: 0;
                padding-left: 5px;
                
                color: $fld-text-color !important;
            }

            & > input.search {
                font-size: inherit;
                line-height: inherit;
                color: $fld-text-color !important;
            }

            & > div.text {
                font-size: inherit;
                margin-top: 4px;
                margin-right: 25px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            $offset-top: calc(calc(#{$fld-dropdown-height} - 32px) / 2);
            &:not(.multiple) {
                min-height: $fld-dropdown-height;

                & > input.search {
                    padding-top: 2px;
                    padding-left: 10px;
                }

                .text {
                    margin-top: calc(3px + #{$offset-top});
                }

                i.dropdown.icon {
                    padding-top: calc(1px + #{$offset-top});
                }
            }

            &.multiple {
                & > input.search {
                    margin-top: -5px;
                    margin-left: 1px;
                    padding-top: 12px;
                    padding-left: 0px;
                }

                .default.text {
                    padding-left: 2px;
                    margin-top: 7px;
                }

                a.ui.label {
                    margin-top: 3px !important;
                }
                
                i.dropdown.icon {
                    padding-top: 5px;
                }
            }

            &:active, &:focus, &:hover {
                border-color: $fld-border-hover-color;

                &:not(.active) {
                    @include es-shadow-thin(true);
                }

                .menu {
                    border-color: $fld-border-hover-color;
                }
            }
        }

        span {
            background-color: transparent !important;
            color: $fld-text-color !important;
        }
    }

    input, select, textarea {
        font-family: inherit;
        font-size: inherit;
        border-color: $fld-border-color;
        border-style: solid;
        background-color: $fld-bgcolor;
        color: $fld-text-color;
        border-width: 1px;
        border-radius: 4px;

        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s; /* Ignore SonarQube duplication - required because -webkit-box-shadow x box-shadow */
        transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;

        &:focus, &:hover {
            outline: 0;
        }
    }

    input {
        padding: 5px 12px;

        &, &:focus, &:not([type]):focus {
            font-family: inherit;
            font-size: inherit;
            height: $fld-input-height;
            border-color: $fld-border-color;
            background-color: $fld-bgcolor;
            color: $fld-text-color !important;
        }
    }

    option {
        color: $fld-placeholder-color !important;
        font-family: inherit;
        font-size: inherit;
    }

    textarea {
        padding: 7px 10px;
    }

    .input-wrapper:not(.disabled), .masked-input-wrapper:not(.disabled) {
        input, textarea, select {
            @include form-style-controls-states() {
                border-color: $fld-border-hover-color;
                @include es-shadow-thin(true);
            }
        }    
    }

    input, textarea, select {
        &:disabled {
            opacity: 0.45;
        }
    }

    @include form-style-controls-autofill("input, textarea, select", $fld-border-color, 0 0 0px 30px $fld-bgcolor inset, $fld-text-color, $font-size) {
        & {
            border-color: $fld-border-hover-color;
            @include es-shadow-thin(true);
        }
    }
    @include form-style-controls-selection("input, textarea, select", $main_selectionColor, $fld-text-color);
    @include form-style-controls-placeholder("input, textarea, select", $fld-placeholder-color);
   

    @content;
}

@mixin es-toggle($toggle_size: $servicos_form-toggle-size,
                 $toggle_bullet-margin: $servicos_form-toggle-bullet-margin,
                 $toggle_border-color: $servicos_form-toggle-border-color,
                 $toggle_bullet-color: $servicos_form-toggle-bullet-color,
                 $toggle_active-color: $servicos_form-toggle-active-color,
                 $toggle_inactive-color: $servicos_form-toggle-inactive-color) {
    @include toggle-change-size($toggle_size, $toggle_bullet-margin) {
        label {
            background-color: $toggle_border-color;
        }

        .switch--shadow + label::before {
            background-color: $toggle_inactive-color;
        }

        .switch--shadow:checked {
            & + label::before {
                background-color: $toggle_active-color;
            }

            & + label::after {
                background-color: $toggle_bullet-color;
            }
        }

        @content;
    }
}