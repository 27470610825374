/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'recuperarSenha' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('recuperar-senha') {
    & > .content-form {
        text-align: center;

        @include mc-form-input-float-label($appCard_normal-color, $appCard_normal-bgColor, $appCard_normal-color, $appCard_normal-color);

        & > .imagem-principal {
            display: inline-block;
            margin-top: 1em;
            width: 275px;

            img {
                height: auto;
                width: 100%;
            }
        }

        & > .info-principal {
            margin: 2.5em 0;
            font-size: 1.3em;
            @include appFont-bold();
        }

        & > .info-adicional {
            margin: 1.5em 0;
            font-size: inherit;
        }

        & > .main-action {
            margin-top: 2rem;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
