/* Import common definition styles */
@import '../../styles/common.scss';
@import '../../components/general/laudosAtestadoView/laudosAtestadosView.scss';

/* ---------------------------------------------------------
 *  'laudos-atestados' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('laudos-atestados') {
    & > .results-section {
        font-size: 0.94em;
        margin-top: 2em;
    }

    & > .laudos-atestados-section {
        font-size: 0.94em;
        margin-top: 2em;

        & .laudos-atestados-body {
            & > .body-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            & > .col-left, & > .col-right {
                display: inline-block;

                &> div:not(:first-child) {
                    margin-top: 0.5em;
                }
            }

            .col-left {
                &> div {
                    display: flex;
                    flex-direction: row;
                }

                .info-image-wrapper {
                    flex-shrink: 0;
                    align-self: center;
                    height: auto;
                    width: $laudos-atestados_card-info-icon-width;
                    img {
                        height: auto;
                        width: 100%;

                        &.emphasis {
                            filter: invert(26%) sepia(15%) saturate(4512%) hue-rotate(119deg) brightness(98%) contrast(102%);
                        }

                        &:not(.emphasis) {
                            filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(266deg) brightness(99%) contrast(107%);
                        }
                    }
                }

                .info-text {
                    align-self: center;
                    margin-left: 0.5em;
                    display: inline-block;

                    time {
                        @include appFont-bold();
                    }
                    
                }

                .info-data-consulta {
                    margin-top: 0.5em;
                }
            }

            .col-right {
                margin-left: 1.5em;
            }
        }

        .lista-documentos-wrapper {
            margin: 0.75em 0;

            .lista-laudos-wrapper {
                display: flex;
                flex-direction: row;

                .buttons-wrapper {
                    flex-shrink: 0;
                    display: inline-block;
                    height: $downloadViewButton_height;
                    & :not(:first-child) {
                        margin-left: 0.4em;
                    }
                }

                .info-wrapper {
                    margin-left: 0.75em;
                    align-self: center;
                }                    

                &:not(:first-child) {
                    margin-top: 1rem;                    
                }

                .download-error {
                    color: $main_warning-color;
    
                    &:not(:last-child) {
                        margin-bottom: 0.75em;
                    }
                }
            }
        }

        & .accordion.ui.fluid > .accordion-item .accordion-item {
            margin-top: 0;
        }
    }

    & > .laudos-atestados-viwer-wrapper {
        .titulo-modal {
            display: inline-block;
            padding-left: 1.5rem;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.4em;
        }

        .vw-modal-wrapper {
            .vw-modal-body { 
                .vw-modal-body-content {
                    & img {
                        margin-left: 3%;
                        height: auto !important;
                        width: 94%;
                    }
                }
            }
        }

        $content-heigth: calc(100% - #{$viewerModal_default-header-height} - #{$viewerModal_default-footer-height});
        & > div#viwerModalId {
            @include laudos-atestados-content($content-heigth, 30px, 20px, 94%,
                    $laudosAtestadosView_bgColor, $laudosAtestadosView_color);
        }
        
        .result-pagespace {
            height: 25px;
            width: 100%;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .laudos-atestados-wrapper {
        & > .content-wrapper {
            & > .content-box {
                
                .laudos-atestados-section {
                    @media screen and (max-width: 350px) {
                        font-size: 0.75rem;

                        .laudos-atestados-body {
                            .col-left .info-image-wrapper {
                                width: calc(0.85 * #{$laudos-atestados_card-info-icon-width}) !important;
                            }

                            .col-right {
                                margin-left: 1.2em;
                            }
                        }
                    }

                    @media screen and (min-width: 350px) and (max-width: 410px) {
                        font-size: 0.88rem;

                        .laudos-atestados-body {
                            .col-left .info-image-wrapper {
                                width: calc(0.9 * #{$laudos-atestados_card-info-icon-width}) !important;
                            }

                            .col-right {
                                margin-left: 1.25em;
                            }
                        }
                    }
                }

                .laudos-atestados-viwer-wrapper {
                    @include laudos-atestados-resposivo(15px, 15px);

                    /* Visualizador */
                    @media screen and (max-width: 350px) {
                        .titulo-modal { font-size: 0.85em; }
                    }
            
                    @media screen and (min-width: 350px) and (max-width: 415px) {
                        .titulo-modal { font-size: 0.98em; }
                    }
        
                    @media screen and (min-width: 415px) and (max-width: 475px) {
                        .titulo-modal { font-size: 1.2em; }
                    }
                }
            }
        }
    }

    &.mobile {
        .laudos-atestados-viwer-wrapper {
            .vw-modal-wrapper {
                .vw-modal-body {
                    @include scrollbar-hide();

                    .vw-modal-body-content {
                        & img {
                            margin-left: 1%;
                            width: 98%;
                        }
                    }
                }
            }
        }
    }
}
