/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'editarDados' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('editar-dados') {
    .edit-form {
        @include mc-form-principal();

        .field-error {
            text-align: left;
            font-size: 0.75em;
            color: $main_warning-color;
            padding: 0 5px;
        }
    }
    
    .main-action {
        padding-top: 1.5rem;

        &:not(:empty) {
            padding-bottom: 1.5rem;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
