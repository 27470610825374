/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'smsValidationModal' module specific styles
 * ---------------------------------------------------------
**/

#smsValidationModalWrapperId {
    font-size: 0.9rem;

    & > #wrapperAppModalId .app-modal-floating-box .app-modal-box {
        background-color: $main_color !important;
        color: $main_bgColor !important;

        & > .app-modal-header .modal-header-wrapper {
            height: calc(#{$appCard_action-bar-buttonSize} + 1em);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
    
            & > .close-button {
                margin-right: 1em;
                width: $appCard_action-bar-buttonSize;
                height: $appCard_action-bar-buttonSize;
    
                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }
    
        & > .app-modal-content-wrapper {
            & > .app-modal-content .sms-validation-wrapper {
                & > .info-principal {
                    @include appFont-bold();
        
                    text-align: center;
                    margin-top: 1em;
                    font-size: 1.1em;
                }
        
                & > .sms-validation-form {
                    margin-top: 2.0em;
        
                    & > .token-input-wrapper {
                        display: flex;
                        justify-content: center;
        
                        & > .sms-token {
                            display: inline-block;
                            text-align: center;
                            width: 200px;
                            
                            & > .ui.input input {
                                text-align: center;
                                background-color: transparent !important;
                                color: $main_bgColor;
                                caret-color: darken($main_selectionColor, 20%);
                                border-color: $main_bgColor;
        
                                @include form-style-controls-selection("&", $main_selectionColor, darken($appCard_normal-color, 20%));
        
                                &, &:focus, &:active {
                                    letter-spacing: 0.5rem !important;
                                    font-size: 3rem !important;
                                    height: 5rem !important;
                                    padding: 0 0 0 10px !important;
                                    border: 1px solid;
                                }
        
                                &:disabled {
                                    border: 0 !important;
        
                                    &::placeholder {
                                        color: $main_selectionColor;
                                    }
                                }
                            }
                        }
                    }    

                    .main-action {
                        button:not(:first-of-type) {
                            margin-top: 1em;
                        }
                    }
                }
            }
        }
    }
}

