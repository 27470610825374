/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'obterLocalizador' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('obter-localizador') {
    text-align: center;

    .obter-localizador-instrucoes {
        margin-top: 1em;

        .instrucoes-content {
            text-align: left;
            font-size: 1em;
        }
    }

    .obter-localizador-form {
        @include mc-form-principal();
    }

    .confirmacao-envio {
        text-align: center;

        & > .info-principal {
            margin: 2.0em 0;
            font-size: 1.3em;
            @include appFont-bold();

            h2 {
                color: inherit;
            }
        }

        & > .info-adicional {
            margin: 2em 0;
            font-size: inherit;
        }

        & > .localizador-enviado {  
            height: 120px;
            margin: 1.5rem 0;
            text-align: center;

            & > img {
                display: inline-block;
                height: 100%;
                width: auto;
            }
        }
    }

    .contato-central {
        .central-phone-number {
            display: inline-block;
            white-space: nowrap;
            text-decoration: underline;
            margin-left: 0.3em;
        }
    }

    .main-action {
        padding: 1.5rem 0;

        button:not(:first-of-type) {
            margin-top: 1rem;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
