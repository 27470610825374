/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appDateFilter' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    div#appFilterWrapperId {
        & > .filter-section {
            & > .filter-form {
                .filter-box {
                    background-color: $appDateFilter_bgColor;
                    padding: 1em;
    
                    .filter-values {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .inicio-wrapper, .fim-wrapper {
                            width: 49%;

                            @include mc-filter-input($appDateFilter_input-color, $appDateFilter_input-bgColor, $appDateFilter_border-color, $mainForm_base-font-size);
                        }
                    }

                    .filter-error {
                        text-align: center;
                        margin-top: 0.5em;
                        color: $main_warning-color;
                    }
                }

                .filter-action {
                    margin-top: 1em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    & div {
                        align-self: center;
                        width: 250px;
    
                        &:not(:first-child) {
                            margin-top: 0.75em;
                        }
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    div#appFilterWrapperId {
        & > .filter-section > .filter-form > .filter-box > .filter-values {
            @media screen and (max-width: 375px) {
                flex-direction: column;
                .inicio-wrapper, .fim-wrapper {
                    width: 100%;
                }
                .fim-wrapper {
                    margin-top: 0.5em;
                }
            }
        }
    }
}