/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'newsEditModal' module specific styles
 * ---------------------------------------------------------
**/

div#newsEditModalId {
    $news-edit_modal-content-max-height: 600px;
    $news-edit_image-selection-height: 320px;

    .file-select-input {
        height: 0px;
        width: 0px;
        overflow: hidden;
    }

    & > div#wrapperAppModalId {
        .app-modal-floating-box {
            & > .app-modal-box {
                background-color: $servicos_default-header-color;

                & > .app-modal-content-wrapper {
                    max-height: $news-edit_modal-content-max-height !important;
                }
            }

            @media screen and (max-width: 525px) {
                min-width: 480px;
                left: 20px !important;
                transform: translate(0, -50%);
            }

            @media screen and (max-height: 650px) {
                $reducao: 100px;
                & > .app-modal-box > .app-modal-content-wrapper { max-height: calc(#{$news-edit_modal-content-max-height} - #{$reducao}) !important; }
                .news-data .image-selection-wrapper { height: calc(#{$news-edit_image-selection-height} - #{$reducao}) !important; }
            }

            @media screen and (min-height: 650px) and (max-height: 700px) {
                $reducao: 50px;
                & > .app-modal-box > .app-modal-content-wrapper { max-height: calc(#{$news-edit_modal-content-max-height} - #{$reducao}) !important; }
                .news-data .image-selection-wrapper { height: calc(#{$news-edit_image-selection-height} - #{$reducao}) !important; }
            }
        }

        .modal-header-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: calc(#{$appCard_action-bar-buttonSize} + 1em);

            & > .modal-header-title {
                display: inline-block;
                color: $main_bgColor;
                text-align: center;
                width: 100%;
                margin-left: 1em;
                padding-left: $appCard_action-bar-buttonSize;

                @include appFont-bold();
                font-size: 1.1em;
                text-transform: uppercase;
            }

            & > .modal-close-button {
                margin-right: 1em;
                width: $appCard_action-bar-buttonSize;
                height: $appCard_action-bar-buttonSize;

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }

        .news-editor-wrapper {
            .news-form-wrapper {
                background-color: $servicos_default-header-color;
                padding: 1em;
                font-size: 1em;
                border: solid 1px $main_bgColor;

                .news-data {
                    @include es-form-servicos(0.9rem);

                    .form-instructions {
                        margin-bottom: 1em;
                        padding-bottom: 0.5em;
                        border-bottom: 1px solid $servicos_default-header-bgColor;
                    }

                    .form-row {
                        position: relative;

                        & input, & select {
                            padding: 4px 10px;
                            height: 28px;
                        }

                        & select {
                            padding-left: 0px !important;  /* Saffari o padding é sempre zero */
                            text-indent: 6px; /* Funciona em todos os browsers */
                        }

                        i.field-required {
                            position: relative;

                            &:not(:first-child) {
                                margin-left: 0.2em;
                            }
                            
                            &::after {
                                content: '*';
                                color: $servicos_form-field-required-mark-color;
                                font-style: normal;
                            }
                        }

                        i.field-information {
                            background-color: $servicos_form-info-button-bgColor;
                            position: relative;
                            top: -1px;                              
                            margin-left: 0.5em;
                            padding: 1px 5px;
                            border-radius: 7px;
                            font-size: 9px;  

                            &::after {
                                content: 'i';
                                color: $servicos_form-info-button-color;
                                font-style: normal;
                            }
                        }

                        i.image-add {
                            position: relative;
                            top: 0px;
                            margin-left: 1em;
                            padding: 2px 6px;
                            border-radius: 3px;
                            font-size: 0.9em;
                            line-height: 1em;
                            background-color: $servicos_default-header-bgColor;
                            cursor: pointer;

                            &::after {
                                content: 'Adicionar';
                                font-style: normal;
                                color: $servicos_form-info-button-color;
                            }
                        }

                        .field-group {
                            &:first-child {
                                margin-top: 0;
                            }

                            .field-control {
                                display: block;
                                width: 100%;
                                line-height: 1.42em;
                                border-radius: 4px;
                                border-width: 1px;
                            }

                            .field-message {
                                margin-top: 0.33em;
                                font-size: 0.95em;
                                line-height: 1em;
                            }

                            .fld-param-modulo {
                                position: relative;

                                textarea {
                                    resize: vertical;
                                    width: 100%;
                                }

                                & > .field-label {
                                    display: inline-block;
                                    position: absolute;
                                    top: 0;
                                }
                            }
                        }
                    }

                    .new-item-order {
                        margin-top: $servicos_form-field-vertical-margin;

                        span {
                            color: $servicos_default-information-color;
                        }
                    }

                    .item-visibility {
                        display: flex;

                        .visibility-toggle {
                            @include es-toggle();
                            & {
                                margin-left: 0.5em;
                            }
                        }
                    }

                    .item-exhibit-location {
                        display: flex;

                        .field-name-block {
                            margin-top: 0.4em;
                            white-space: nowrap;
                        }

                        .location-list-wrapper {
                            margin-left: 0.5em;
                            width: 100%;

                            .dropdown-field {
                                margin: 0;
                            }
                        }
                    }

                    .image-selection-wrapper {
                        height: $news-edit_image-selection-height;
                        padding-left: 0;
                        margin-top: 0.5em;

                        display: flex;
                        flex-direction: column;
                        min-height: 0;
    
                        & > .image-selection-box {
                            flex-grow: 1;
                            min-height: 0;
                            overflow-y: auto;

                            .image-item-wrapper {
                                position: relative;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
    
                                &:not(:first-child) {
                                    margin-top: 0.4em;
                                }
    
                                &.changed {
                                    .item-content-wrapper {
                                        background-color: $servicos_default-item-changed-color;
                                    }
                                }

                                &.has-error {
                                    .item-content-wrapper {
                                        background-color: $servicos_default-item-error-color;
                                    }
                                }
    
                                &.deleted {
                                    .item-content-wrapper {
                                        background: repeating-linear-gradient(-45deg, $main_warning-color, $main_warning-color 5px, $main_color 5px, $main_color 15px);
                                        opacity: 0.5;
                                        .img-box, .info-box {
                                            opacity: 0.6;
                                        }
                                    }
                                }
                        
                                $item-button_size: 20px;
                                .item-action-wrapper {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    width: $item-button_size;
                                    padding: 0.5em 0;
    
                                    .order-change-wrapper {
                                        display: flex;
                                        flex-direction: column;
                                        align-self: center;
                
                                        .order-up, .order-down {
                                            width: 15px;
                                            color: $servicos_form-info-button-color;
                                            cursor: pointer;
                
                                            img {
                                                width: 100%;
                                                height: auto;
                                            }
                                        }
                
                                        & > div:not(:first-child) {
                                            margin-top: 0.3em;
                                        }
                                    }
        
                                    .action-buttons-wrapper {
                                        margin-top: 1.0em;
    
                                        .image-delete, .image-restore {
                                            width: $item-button_size;
                                            height: $item-button_size;
                                            font-size: 0.8em;
                                            cursor: pointer;
                            
                                            &:not(:first-child) {
                                                margin-top: 0.33em;
                                            }
                                        }
                                    }
                                }
    
                                .item-content-wrapper {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    padding: 0.5em 0;
                                    padding-left: 0.375em;
                                    margin-left: 0.375em;
                                    width: 100%;
    
                                    .item-content-box {
                                        display: flex;
                                        flex-direction: row;
                                        width: 100%;
    
                                        & > div:not(:first-child) {
                                            margin-left: 0.75em;
                                        }
            
                                        .img-box {
                                            position: relative;
                                            display: flex;
                                            flex-shrink: 0;
                                            align-self: flex-start;
                                            flex-direction: column;
                                            width: 170px;
    
                                            .image-selection-frame {
                                                position: relative;
                                                display: none;
                                                height: 115px;
                                                border: 1px dashed $servicos_default-header-bgColor;
                                                cursor: pointer;
                                            }

                                            img {
                                                height: auto;
                                                width: 100%;
                                            }
    
                                            .pick-image-message {
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                                background-color: $servicos_default-header-bgColor;
                                                color: $main-color;
                                                padding: 0.3em 0.75em;
                                            }
                                            
                                            &.no-img {
                                                .image-selection-frame {
                                                    display: block;
                                                }
                                            }
                                        }
    
                                        .info-box {
                                            width: 100%;
                                            padding-right: 0.5em;
                                        }
                                    }
    
                                    .item-error-box {
                                        width: 100%;
                                    }
                                }
                            }
    
                            .end-of-items {
                                height: 0;
                                width: 0;
                            }
                        }
                    }

                    .form-action {
                        margin-top: 20px;
                        text-align: center;

                        .btn-ok {
                            @include es-form-button();
                        }
                    }
                }
            }
        }
    }
}