/* ------------------------------------
 *  Estilos específicos do 'dashboard'
 * ------------------------------------
**/
@import '../../styles/common.scss';


@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style : normal;
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?v=4.7.0");
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
}

@include es-default-service-login-template(".dashboard-wrapper") {
    .section-header-wrapper {
        &:first-of-type {
            margin-top: 2.5rem;
        }

        .refresh-wrapper {
            display: inline;
            position: absolute;
            bottom: 0;
            right: 0;
    
            .btn-refresh {
                cursor: pointer;

                @include mc-form-button($servicos_button-secundary-bgColor, $formButton_color,
                        $servicos_button-secundary-hover-bgColor, $formButton_hover-color, 
                        $formButton_disable-bgColor, $formButton_disable-color, none) {
        
                    & {
                        font-size: 0.9em;
                        padding: 3px 1em;
                        max-width: 170px;
                    }

                    &:disabled {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .ui.fluid.accordion {
        .accordion-item {           
            .title {
                background-color: $servicos_default-header-bgColor;
                .title-info-wrapper {
                    @include appFont-bold();
                }
            }

            .content {
                background-color: $dashboard_accordion-content-bgColor;
                padding: 0px;

                .fetching-data {
                    padding: 1rem;
                    text-align: center;
                    color: $main_warning-color;
                    @include appFont-bold();
                }
            }
        }
    }

    & > .statistics-info-data {
        padding: 0.66rem 0;

        .statistic-users, .statistic-services, .statistic-accesses {
            .statistic-content-wrapper {
                padding: 0 1em;

                & > .statistic-row {
                    padding: 0.2em 0 0.2em 1rem;

                    &.ident {
                        padding-left: 2rem;
                    }

                    & > .sub-group {
                        @include appFont-bold();
                        font-style: italic;
                    }

                    & > .item-title {
                        display: inline-block;
                        font-size: 0.95em;
                        @include appFont-bold();
                    }

                    & > .item-info {
                        display: inline;
                        margin-left: 0.5em;
                        font-size: 0.9em;
                    }
                }
            }
        }

        .statistic-accesses {
            .statistic-content-wrapper {
                background-color: $servicos_default-bgColor;
                padding-top: 1rem;

                .graphic-wrapper {
                    max-width: 900px;
                    margin: 0 auto;
                }
            }
        }
    }

    & > .build-info-data {
        padding: 0.66rem 0;

        .ui.fluid.accordion .accordion-item .content {
            ul {
                margin: 1rem 1rem;
                list-style-type: none;
                padding-inline-start: 1rem;
                list-style-position: inside;

                & > li{
                    margin: 5px;
                    position: relative;
                    display: table-row;

                    &::before {    
                        font-family: 'FontAwesome', sans-serif;
                        content: "\f192";
                        margin: 0;
                        width: 30px;
                        display: table-cell;
                        text-align: center;
                    }

                    .label {
                        padding-left: 0;
                        padding-right: 0.5rem;
                        font-size: 0.92em;
                        font-weight: 700;
                    }

                    a, .text {
                        color: darken($main_color, 10%);
                        font-size: 0.9em;
                        font-weight: 0;
                    }

                    a:hover {
                        color: $main_color;
                    }
                }

                .service-name::before { content: "\f2bb"; }
                .service-version::before { content: "\f02b"; } 
                .api-version::before { content: "\f061"; } 
                .branch::before { content: "\f126"; }
                .commit-id::before { content: "\f292"; }
                .commit-date-hour::before { content: "\f017"; }
                .commit-message::before { content: "\f11c"; } 
                .commit-author::before { content: "\f007"; }  
            }
        }
    }
}
