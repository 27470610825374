/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appController' module specific styles
 * ---------------------------------------------------------
**/

#root {
    & .app-error {
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        &.main-app-bg {
            background-color: $main_bgColor;
        }
        
        &:not(.main-app-bg) {
            background-color: $appErrorPage_bgColor;
        }

        img.mh {
            height: 100%;
            width: auto;
        }

        img:not(.mh) {
            height: auto;
            width: 100%;
        }
    }

    & .missing-configuration-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2em;
        background-color: $appErrorPage_missingCfg-bgColor;

        .app-logo {
            margin: 0;

            img {
                display: inline-block;
                height: 70px;
                width: auto;
                vertical-align: middle;
            }
        }

        .message-wrapper {
            margin-top: 6em;
            max-width: 500px;
        }

        .reload-button-wrapper {
            margin-top: 5em;
            width: 270px;
        }
    }
}