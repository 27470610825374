@import '../../../styles/common.scss';


.switch__container {
    $toggle_size: 48px;
    $toggle_height: calc(#{$toggle_size} / 2);

    margin: 0px auto;
    width: $toggle_size;

    .switch {
        visibility: hidden;
        position: absolute;
        margin-left: -9999px;

        & + label {
            display: block;
            position: relative;
            cursor: pointer;
            outline: none;
        
            @include no-select();
        }

        &.switch--shadow + label {
            background-color: $toggleButton_border-color;
            padding: 2px;
            width: $toggle_size;
            height: $toggle_height;
            border-radius: $toggle_height;

            &::before, &::after {
                display: block;
                position: absolute;
                top: 1px;
                left: 1px;
                bottom: 1px;
                content: "";
            }

            &::before {
                right: 1px;
                background-color: $toggleButton_unchecked-bgColor;
                border-radius: $toggle_height;
                transition: background 0.4s;
            }

            $toggle_bullet-margin: 0px;
            $toggle_bullet-size: calc(#{$toggle_height} - 2px - calc(2 * #{$toggle_bullet-margin}));
            &::after {
                margin-top: $toggle_bullet-margin;
                margin-left: calc(#{$toggle_bullet-margin} + 0px);
                height: $toggle_bullet-size;
                width: $toggle_bullet-size;
                background-color: $toggleButton_switch-bgColor;
                border-radius: 100%;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
                transition: all 0.4s;
            }
            
        }
        
        &.switch--shadow:checked + label {
            &::before {
                background-color: $toggleButton_checked-bgColor;
            }
            
            &::after {
                transform: translateX($toggle_height);
            }
        }
    }

    &.disabled {
        .switch + label {
            cursor: not-allowed;
        }
    }
}
