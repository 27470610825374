/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'allServices' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .all-services-wrapper {
        background-color: transparent;

        div#menuButtonsSectionId {
            & > div#navigationButtonsWrapperId {
                padding-bottom: 1rem;
            }
        }
    }

    div#menuButtonsSectionId {
        & > div#navigationButtonsWrapperId {
            margin: 0; /* use: '0 auto' to center when all buttons fit in one line */
            overflow: hidden;

            ul {
                display: grid;
                grid-template-columns: repeat(auto-fit, $allSvr_buttonSize);
                grid-gap: $allSvr_button-spacing;
                justify-content: space-between;
                align-content: flex-start;
                list-style: none;
                margin: 0;
                padding: 0;
            }

            li {
                margin: 0 !important;
                padding: 0 !important;
                height: auto !important;
                width: auto !important;
            }

            .nav-button {
                margin: 0;
                height: $allSvr_buttonSize;
                width: $allSvr_buttonSize;
            }
        }
    }
}


/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
@media screen and (min-width: $allSvr_minWidth-buttonChange) {
    #root > .app-viewport {
        .all-services-wrapper {
            div#menuButtonsSectionId {
                & > div#navigationButtonsWrapperId {
                    ul {
                        grid-template-columns: repeat(auto-fit, $allSvr_large-buttonSize) !important;
                    }

                    .nav-button {
                        height: $allSvr_large-buttonSize !important;
                        width: $allSvr_large-buttonSize !important;
                    }
                }
            }
        }
    }
}
