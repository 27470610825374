/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'visualizaEstudoPACS' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .external-link-redirect-wrapper {
        font-size: $servicos_default-base-font-size;
        height: 100%;
        width: 100%;
        margin: 0px;
        padding: 0px;

        background-color: $main_bgColor;

        & > .content-box {
            padding: 0;
        }
    }
}