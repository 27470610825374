/* Import common definition styles */
@import '../../../styles/common.scss';

@import './documentoValidadoViewer.scss';

/* ---------------------------------------------------------
 *  'validadorDocumentos' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .documento-validador-wrapper {
        background-color: $validadorDoc_main-bgColor;
        color: $validadorDoc_main-color;
        font-size: $servicos_default-base-font-size;
        height: 100%;
        width: 100%;
        margin: 0px;
        padding: 0px;

        .btn-servicos {
            @include es-form-button();
        }

        .information-card {
            &.info-success {
                @include message-box($main_information-bgColor, $main_color) {
                    margin-top: $appContent_defaultVerticalPadding;
                }
            }

            &.info-error {
                @include message-box($main_warning-color, $main_color) {
                    margin-top: $appContent_defaultVerticalPadding;
                }
            }
        }

        .field-group {
            color: $validadorDoc_form-default-color;
            label {
                color: inherit;
            }

            .field-control {
                display: block;
                width: 100%;
                line-height: 1.42em;
                border-radius: 4px;
                border-width: 1px;
            }
        }

        .data-nascimento-modal-wrapper {
            & > #wrapperAppModalId .app-modal-floating-box {
                @media screen and (max-width: 290px) {
                    visibility: hidden;
                }
    
                & {
                    max-width: 410px;
                }
        
                .app-modal-box {
                    background-color: $main_color;
                    padding: 0;
        
                    & > .app-modal-header .modal-header-wrapper {
                        position: relative;
                        background-color: $main_bgColor;
                        color: $main_color;
                        height: calc(#{$appCard_action-bar-buttonSize} + 1.2em);
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        padding-left: 0.75em;
                        
                        @include appFont-bold();
                
                        & > .close-button {
                            position: absolute;
                            top: 0.6em;
                            right: 0.75em;
                            width: $appCard_action-bar-buttonSize;
                            height: $appCard_action-bar-buttonSize;
                
                            &:hover {
                                cursor: pointer;
                                opacity: 0.8;
                            }
                        }
                    }
        
                    & > .app-modal-content-wrapper {
                        padding: 0.75em;
        
                        .app-modal-content {
                            font-size: inherit;

                            .data-nascimento-form {
                                width: 100%;
                        
                                .data-nascimento-wrapper {
                                    margin-top: 0.5em;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: center;

                                    .field-wrapper {
                                        width: 100%;
                                    }

                                    .masked-input-wrapper {
                                        width: 100%;
                                    }
                                    
                                    button {
                                        margin-top: 0;
                                        margin-left: 0.5em;
                                        width: 50px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Landing Page (Main) */
        .landing-page {
            background-color: inherit;
            color: inherit;
            font-size: inherit;
            padding: $appContent_defaultVerticalPadding $appContent_horizontalPadding;

            @include es-form-servicos();  // Estilos dos campos dos formulário
    
            .main-wrapper {
                display: block;
                height: 100%;
                width: 100%;

                overflow-x: hidden !important;
                overflow-y: auto !important;

                .main-content {
                    .header-card {
                        @include es-card-envelope(0 0 15px 0, 15px 20px, 8px) {
                            background-color: $servicos_default-header-bgColor;

                            @include es-default-header-content(auto, 120px, 2rem, $servicos_default-header-color);
                        }
                    }
            
                    .primary-card {
                        @include es-card-envelope(15px 0px, 20px 20px, 6px) {
                            background-color: $servicos_default-card-bgColor;
                        }
                    }
            
                    .field-group {
                        text-align: left;
                        label {
                            font-size: 1.1em;
                        }
                    }
                }        
            }
        }

        /* Vizualizador e importação mixin 'documento-validado-viewer' */
        @include documento-validado-viewer();
    }

    &.mobile {
        .documento-validador-wrapper {
            .landing-page {
                .main-wrapper {
                    @include scrollbar-hide();
                }
            }
    
            div.documento-valido-viwer-wrapper {
                .vw-modal-wrapper {
                    .vw-modal-body {
                        @include scrollbar-hide();
    
                        .vw-modal-body-content {
                            & > .content-wrapper {
                                max-width: 100% !important;
                                margin-left: 0% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
@mixin header-responsive($logo-width, $font-size-title, $font-size, $title-margin-bottom) {
    .header-wrapper {
        .logo-wrapper .logo-box { 
            width: $logo-width !important;
        }
        .header-content {
            h1 { font-size: $font-size-title !important; margin-bottom: $title-margin-bottom !important; }
            h2 { font-size: $font-size !important; }
        }
    }
}

@mixin viwer-responsive($title-size) {
    .vw-modal-header_custom {
        .vwhc-wrapper {
            .vwhc-content {
                h2 { font-size: $title-size; }
            }
        }
    }
}

/* 
 * Include mixin de responsividade para seções do componente 
 */
 #root > .app-viewport.external-service {
    .documento-validador-wrapper {
        /* Página principal */
        .landing-page { 
            @media screen and (max-width: 376px) {
                @include header-responsive(80px, 1.6rem, 1rem, 0.33rem);
                .information-card { div { font-size: 0.85em !important; } }
            }

            @media screen and (min-width: 376px) and (max-width: 440px) {
                @include header-responsive(95px, 1.7rem, 1rem, 0.5rem);
            }

            @media screen and (min-width: 440px) and (max-width: 580px) {
                @include header-responsive(110px, 2.3rem, 1.2rem, 0.5rem);
            }

            @media screen and (min-width: 580px) {
                @include header-responsive(120px, 3rem, 1.8rem, 0.6rem);
            }
        }

        /* Exibição da Receita Validada */
        .documento-valido-viwer-wrapper {
            @include receita-reposivo(15px, 15px);

            @media screen and (max-width: 376px) {
                @include viwer-responsive(1em);
                .floating-form-dispensacao { width: 92% !important; }
                .wrapper-download-error { .download-error-box { max-width: 260px; } }
            }

            @media screen and (min-width: 376px) and (max-width: 414px) {
                @include viwer-responsive(1.2em);
                .floating-form-dispensacao { width: 85% !important; }
                .wrapper-download-error { .download-error-box { max-width: 300px; } }
            }

            /* Formulário de Dispensação */
            .floating-form-dispensacao {
                @media screen and (max-height: 350px) {
                    transform: translate(-50%, -50%) !important;
                    .card-box { max-height: 200px !important; }
                }

                @media screen and (min-height: 351px) and (max-height: 450px) {
                    transform: translate(-50%, -50%) !important;
                    .card-box { max-height: 220px !important; }
                }

                @media screen and (min-height: 451px) and (max-height: 480px) {
                    .card-box { max-height: 290px !important; }
                }

                @media screen and (min-height: 481px) and (max-height: 650px) {
                    .card-box { max-height: 350px !important; }
                }
            }
        }
    }
}

/* --------------------------------------------------
 * RESPONSIVO - ESPECÍFICO de Receitas (Watermark)
 * --------------------------------------------------
 */
#root > .app-viewport.external-service {
    .documento-validador-wrapper {
        @include watermark-receita-dispensada(".watermark-text");
    }
}

#root > .app-viewport.external-service {
    .documento-validador-wrapper {
        .documento-valido-viwer-wrapper > div#viwerModalId {
            @media screen and (max-width: 400px) {
                font-size: 0.85em;
            }

            @media screen and (min-width: 400px) and (max-width: 600px) {
                font-size: 0.90em;
            }
        }
    }
}