/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'anexosModal' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('teleatendimento') {
    #anexosModalId {
        font-size: 0.9rem;
        height: 0;

        & > #wrapperAppModalId .app-modal-floating-box {
            @media screen and (max-width: 290px) {
                visibility: hidden;
            }

            & {
                max-width: calc(0.8 * #{$app_default-card-module-content-max-width});
                width: 80%;
            }
    
            .app-modal-box {
                background-color: $main_color;
                color: $main_bgColor;
                padding: 0;
    
                & > .app-modal-header .modal-header-wrapper {
                    position: relative;
                    background-color: $main_bgColor;
                    color: $main_color;
                    height: calc(#{$appCard_action-bar-buttonSize} + 1.2em);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 0.75em;
                    
                    @include appFont-bold();
            
                    & > .close-button {
                        position: absolute;
                        top: 0.6em;
                        right: 0.75em;
                        width: $appCard_action-bar-buttonSize;
                        height: $appCard_action-bar-buttonSize;
            
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }
    
                & > .app-modal-content-wrapper {
                    padding: 0.75em;
                    max-height: calc(80 * var(--vh, 1vh) - 2em) !important;
    
                    .app-modal-content {
                        font-size: inherit;
    
                        .message-upload {
                            color: inherit;
                        }
    
                        $fbFullWidth: calc(#{$multiFileSelect_fileButton-width} + #{$multiFileSelect_fileButton-spacing});
                        .file-selection {
                            margin: 0 auto;
                            margin-top: 0.75em;
                            max-width: calc(7 * #{$fbFullWidth});
                        }

                        .action-buttons {
                            margin-top: 1.5em;
                            font-size: 1rem;
                            display: flex;
                            justify-content: space-between;

                            .modal-button {
                                width: calc(50% - 0.25em);

                                &.single {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .wrapper-confirmacao-upload {
            & > .confirmation-box {
                max-width: 300px;

                .dialog-header {
                    background-color: $main_bgColor;
                    color: $main_color;
                    padding: 0.3em 0.5em;

                    .header-title {
                        @include appFont-bold();
                        font-size: 1.1em;
                    }
                }
            }
        }
    }
}