/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appConfirmationDialog' module specific styles
 * ---------------------------------------------------------
**/
div#confirmationDlgWrapperId {
    @include floating-container(fixed, 9200) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 1em;
        background-color: $main_shadowfog-bgColor;
    }

    @include no-select();

    & > .confirmation-box {
        min-width: 260px;
        max-width: 320px;

        .dialog-header {
            padding: 0.4em 1rem;
            background-color: $appConfirmationDlg_header-bgColor;
            color: $appConfirmationDlg_header-color;
            font-size: 1.15em;
            line-height: 1.15em;
            @include appFont-bold();

            @include round-cross-btn(1, fixed, $main_color) {
                top: 0.3em;
                right: 0.3em;
                transform: scale(0.8, 0.8);
    
                &:hover, &:focus {
                    .close {
                        opacity: 0.8;
                    }
                }
            }
        }
    
        & > .dialog-content {
            padding: 1rem;
            background-color: $appConfirmationDlg_bgColor;
            color: $appConfirmationDlg_color;
        
            .message {
                color: inherit;
                font-size: 0.95em;
                margin-bottom: 0;
            }

            .dialog-actions {
                padding-top: 1rem;
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
