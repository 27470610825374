/* ----------------------------------------------
 *  Estilos específicos de 'aceiteTermosSessao'
 * ----------------------------------------------
**/
@import '../../../styles/common.scss';


#aceiteTermosWrapperId {
    #aceiteTermosContentId {
        font-size: 0.9rem;
        & > .termos {
            color: inherit;
            background-color: inherit;
            h3 { 
                color: inherit !important;
                font-size: 1.2rem;
            }
        }
    
        & > .aceite {
            display: inline-block;
            margin-top: 1rem;

            color: inherit;
            label {
                color: inherit;
                font-size: 0.90rem;
                &::after, &::before { margin-top: -1px; }
            }
        }
        
        & > .error-message {
            @include message-box($main_warning-color) {
                margin-top: $appContent_defaultVerticalPadding;
            }
        }
    
        & > .action-buttons {
            padding-top: 1.2rem;
            .left-btn, .right-btn { width: 49%; }
            .left-btn { float: left; }
            .right-btn { float: right; }
            .btn-aceite {
                @include mc-form-button(); 
            }
        }
    }
    
    /*
    ** Override 'appModal' styles */
    #wrapperAppModalId {
        background-color: $aceitaTermos_shadowfog-bgColor;
        .app-modal-floating-box {
            .app-modal-box {
                .app-modal-content-wrapper {
                    .app-modal-content {
                        color: $main_color;
                    }
                }
            }
        }
    }
}