/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appController' module specific styles
 * ---------------------------------------------------------
**/

#root > .app-viewport.layout-wifi:not(.auth) {
    & > .app-viewport-row.header {
        background-color: $wifi_main-bgColor;
    }

    & > .app-viewport-row.content {
        background-color: $wifi_content-bgColor;
    }
    
    & > .app-viewport-row.content.with-header {
        background-color: $wifi_main-bgColor;
    }
}