/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'receitas' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('receitas') {
    & > .receitas-section {
        font-size: 0.94em;
        margin-top: 2em;

        .accordion-item {
            & > .title {
                font-size: 1em;

                .title-info-wrapper {
                    padding-right: 1em;
                    @include appFont-default();

                    .indicativo-novo, .information, 
                    .information > .data, .information > .especialidade {
                        display: inline-block;
                    }

                    .indicativo-novo {
                        margin-right: 0.5em;
                    }

                    .information {
                        text-overflow: ellipsis;

                        & > .data {
                            margin-right: 0.4em;

                            &.separator::after {
                                content: '|';
                                margin-left: 0.4em;
                            }
                        }
                    }
                }
            }
        
            & > .content {
                font-size: 0.95em;

                .dados-receita {
                    margin-top: 0.33em;

                    .nome-servidor, .tipo-receita {
                        display: flex;
                        flex-direction: row;

                        .info {
                            margin-left: 0.33em;
                        }
                    }

                    &.novo {
                        color: $main_emphasis-color;
                    }
                }

                .content-row {
                    .receitas-grupo-wrapper {
                        display: flex;
                        flex-direction: row;

                        .buttons-wrapper {
                            flex-shrink: 0;
                            display: inline-block;
                            height: $downloadViewButton_height;
                            & :not(:first-child) {
                                margin-left: 0.4em;
                            }
                        }

                        .info-wrapper {
                            margin-left: 0.75em;
                        }
                    }

                    .download-error {
                        color: $main_warning-color;

                        &:not(:last-child) {
                            margin-bottom: 0.75em;
                        }
                    }

                    &:not(:first-child) {
                        margin-top: 0.5em;
                    }
                }
            }
        }
    }

    & > .receita-viwer-wrapper {
        .titulo-modal {
            display: inline-block;
            padding-left: 1.5rem;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.4em;
        }

        $content-heigth: calc(100% - #{$viewerModal_default-header-height} - #{$viewerModal_default-footer-height});
        & > div#viwerModalId {
            @include receita-content($content-heigth, 30px, 20px, 94%,
                $receitaView_bgColor, $receitaView_color,
                $receitaView_grd-disp-header-bgcolor, $receitaView_grd-disp-header-color);
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/

#root > .app-viewport {
    .receitas-wrapper {
        & > .content-wrapper {
            & > .content-box {
                .receita-viwer-wrapper {
                    /* Receita / Watermark */
                    @include receita-reposivo(15px, 15px);
                    @include watermark-receita-dispensada(".watermark-text");

                    & > div#viwerModalId {
                        @media screen and (max-width: 400px) {
                            font-size: 0.85em;
                        }
                
                        @media screen and (min-width: 400px) and (max-width: 600px) {
                            font-size: 0.90em;
                        }
                    }

                    /* Visualizador */
                    @media screen and (max-width: 350px) {
                        .titulo-modal { font-size: 1.1em; }
                    }

                    @media screen and (min-width: 350px) and (max-width: 415px) {
                        .titulo-modal { font-size: 1.3em; }
                    }
                }
            }
        }
    }

    &.mobile {
        .receita-viwer-wrapper {
            .vw-modal-wrapper {
                .vw-modal-body {
                    @include scrollbar-hide();

                    .vw-modal-body-content {
                        & > .content-wrapper {
                            max-width: 98% !important;
                            margin-left: 1% !important;
                        }
                    }
                }
            }
        }
    }
}
