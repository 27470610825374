/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'AppBooking' module specific styles
 * ---------------------------------------------------------
**/

#appBookingId > .booking-wrapper {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    width: 100%;

    & > .booking-navbar-wrapper {
        & > .booking-navbar-box {
            background-color: transparent;
            height: auto;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .go-back, .go-forward, .empty-button {
                margin-top: 0;
                margin-bottom: 5px;
                height: auto;
                width: 20px;
                overflow: hidden;

                img {
                    height: auto;
                    width: 100%;
                }

            }

            .go-back, .go-forward {
                background-color: $formButton_bgColor;
                border-radius: 6px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    $grid-half-margin: 0.25em;
    & > .booking-grid-wrapper {
        & > .booking-grid-box {
            margin: 0 calc(#{$grid-half-margin} * -1);
            display: flex;
            flex-direction: row;
            
            &.has-navigation {
                margin-top: 0;
            }

            .grid-day-column, .grid-day-empty {
                padding: 0 $grid-half-margin;
            }

            .grid-day-empty {
                background-color: transparent;

            }

            .grid-day-column {
                & > .date, & > .hour {
                    text-align: center;
                    line-height: 1.2em;
                    border-radius: 0;

                    &.clickable {
                        cursor: pointer;
                    }

                    &.selected {
                        background-color: $appBooking_selected-bgColor;
                    }
                }

                & > .date {
                    background-color: $appBooking_day-bgColor;
                    padding: 0.5em 0.25em;
                    margin-bottom: 0.5em;
                }

                & > .hour {
                    background-color: $appBooking_hour-bgColor;
                    padding: 0.25em;
                    font-size: 0.95em;

                    &:not(:first-child) {
                        margin-top: 0.33em;
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
/* @media (hover: hover) esta apresentando comportamento invertido sendo assim para garantir foi usado o seletor '.desktop' */
#root > .app-viewport.desktop {
    #appBookingId > .booking-wrapper {
        & > .booking-navbar-wrapper {
            .go-back, .go-forward {
                &:hover {
                    background-color: $formButton_hover-bgColor;
                }
            }
        }

        & > .booking-grid-wrapper > .booking-grid-box {
            .grid-day-column {
                & > .date, & > .hour {
                    &.clickable:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

