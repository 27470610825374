/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'faq' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('faq') {
    .sub-titulo-faq {
        margin: 1em 0;
        text-align: center;

        @include appFont-black();
    }

    & > .faq-content-wrapper {
        margin-top: 1em;
    }

    & > .filter-tags-card {
        background-color: $appDateFilter_bgColor;
        padding: 1em;
        margin-bottom: 1.5em;

        .filter-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }

        .title-column {
            display: inline-block;

            &.filter-label {
                margin-top: 12px;
                @include appFont-bold();
                font-size: inherit;
            }
        }

        .filter-add-tag {
            display: flex;
            flex-direction: row;
            margin: 0 auto 0 0;
            padding: 5px 0px;

            .filter-add-input {
                display: inline-block;
                width: 165px;

                @include mc-filter-input($appDateFilter_input-color, $appDateFilter_input-bgColor, $appDateFilter_border-color, $mainForm_base-font-size) {
                    .filter-input-text {
                        display: block;
                        width: 100%;
                        border-width: 1px;
                        line-height: 1.42em;

                        &:focus, &:hover {
                            outline: 0;
                            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
                            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
                        }
                    }
                }
            }

            .filter-add-btn-wrapper {
                margin-left: 3px;
                @include round-cross-btn(1, relative, $faq_tags-round-add-btn-cross-color, "div.btn-add-wrapper", ".add") {
                    top: 4px;
                    right: 0;
                    transform: scale(0.8, 0.8) rotate(45deg);
        
                    &:hover, &:focus {
                        .add {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .tags-column {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &.filter-tag-list {
                margin: 4px 0 0 0;

                .filter-item {
                    align-self: center;
                    display: inline-block;
                    padding: 5px 10px;
                    margin: 3px 0 3px 10px;
                    border-radius: 0px;
                    color: inherit;
                    background-color: $faq_tags-item-bgColor;

                    .remove {
                        display: inline;
                        margin-left: 7px;
                        background-color: $faq_tags-minus-bgColor;
                        padding: 0 7px 0 6px;
                        border-radius: 10px;
                        color: $faq_tags-minus-color;
                        font-size: inherit;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    & > .faq-footer {
        background-color: $faq_footer_bgColor;
        color: $faq_footer_color;
        margin: 1.5rem 0;
        padding: 1.5em;

        & > .footer-box {
            color: inherit;
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            & > .content-wrapper {
                display: flex;

                .logo {
                    height: auto;
                    width: 29px;

                    img {
                        height: auto;
                        width: 100%;
                    }
                }

                .moreinfo-card {
                    margin-left: 0.75em;
                    color: inherit;
                    display: inline-block;

                    a {
                        color: inherit;
                    }
    
                    .phone-number {
                        display: inline-block;
                        white-space: nowrap;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
@mixin faq-responsive($font-size, $footer-vertical-padding) {
    font-size: $font-size !important;
    & > .content-box .faq-footer {
        padding: $footer-vertical-padding !important;
    }
}

#root > .app-viewport {
    .faq-wrapper {
        & > .content-wrapper {
            @media screen and (max-width: 320px) {
                @include faq-responsive(0.82rem, 1.1em);
            }

            @media screen and (min-width: 320px) and (max-width: 375px) {
                @include faq-responsive(0.85rem, 1.2em);
            }
        }
    }
}
