/* Import common definition styles */
@import '../../../../styles/common.scss';


/* Datepicker customization */
.date-with-datepicker {
    ._djfb_grid-column {
        .input-wrapper {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .date-picker-wrapper {
                z-index: 1;
                position: absolute;
                display: inline-block;
                left: 0;
    
                &.not-visible {
                    display: none;
                }
    
                & > .triangle-up, & > .triangle-down {
                    margin-left: 35px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-image: initial;
                    filter: drop-shadow(0 0 1px $formBuilder_date-picker-triangle-border-color);
    
                    &.not-visible {
                        display: none;
                    }
                }
    
                & > .triangle-up {
                    border-width: 0 10.5px 12px 10.5px;
                    border-color: transparent transparent $formBuilder_date-picker-triangle-color transparent;
                }
                
                & > .triangle-down {
                    margin-top: -7px;
                    border-width: 12px 10.5px 0 10.5px;
                    border-color: $formBuilder_date-picker-triangle-color transparent transparent transparent;                
                }
            }
    
            $date-picker-wrapper-width: calc(#{$formBuilder_date-picker-button-width} + #{$formBuilder_date-picker-button-margin-left});
            .date-picker-trigger {
                position: relative;
                width: $date-picker-wrapper-width;
            
                & > .button-wrapper {
                    position: absolute;
                    height: $formBuilder_date-picker-button-height;
                    width: $formBuilder_date-picker-button-width;
                    right: 0;

                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
    
                    & > button {
                        position: absolute;
                        padding: 0;
                        height: $formBuilder_date-picker-button-height;
                        width: $formBuilder_date-picker-button-width;
                        right: 0;
                        cursor: pointer;
    
                        background-color: $formBuilder_date-picker-trigger-button-bgColor;
                        border: 1px solid $formBuilder_date-picker-trigger-button-border-color;
                        border-radius: $formBuilder_date-picker-trigger-button-border-radius;
    
                        &:hover {
                            background-color: $formBuilder_date-picker-trigger-button-hover-bgColor;
                        }
    
                        &:active, &:focus {
                            outline: none;
                            @include es-shadow-thin(true);
                        }
    
                        .trigger-icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            -webkit-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }
}