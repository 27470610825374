/* 
 * MIXINs para vizualização da receita:
 *
 *      receita-content: conteúdo da receita
 *      receita-reposivo: definicoes de responsividade para o componente de validação
 *      pdf-mode: definições para quando gera PDF (no momneto, em uso apenas no 'receita-services')
 *      
 * Importar (receita-content e pdf-mode) para SASS do 'receita-services'.
 */

@mixin receita-content($content-height: auto, $content-margin: 30px, $items-padding: 20px, $wrapper-width: 94%,
                       $receita-bgcolor: #fff, $receita-color: #000,
                       $grd-disp-head-bgcolor: #29473d, $grd-disp-head-color: #ffffff,
                       $tootip-bgcolor: #647432, $tooltip-border-color: #b9c78c, $tootip-color: #fff,
                       $tootip-anchor-color: #b9c78c, $tootip-anchor-hover-color: #9aa577,
                       $table-border: #555) {

    $content-min-width: 300px;
    $content-tipouso-margin: $items-padding;
    $content-item-margin: 2 * $content-tipouso-margin;
    
    $separator-height: calc(1px + calc(2 * #{$items-padding}));
    $separator-margin: $items-padding;

    $head-logo-width: 300px;
    $head-content-margin: 2 * $items-padding;
    $head-content-unuseable: $head-logo-width + $head-content-margin;
    $head-content-maxwidth: calc(100% - #{$head-content-unuseable});

    $qrcode-size-mobile: 250px;
    $qrcode-size-desktop: 0.6 * $qrcode-size-mobile;

    $wrapper-margin: calc(calc(100% - #{$wrapper-width}) / 2);
    .content-wrapper {
        max-width: $wrapper-width;
        margin-left: $wrapper-margin;
        padding-bottom: $content-margin;
        min-height: $content-height;
        min-width: $content-min-width;
        background-color: $receita-bgcolor;
        color: $receita-color;

        & img {
            width: auto;
            height: auto;
            background-color: inherit;
        }

        & label {
            color: inherit;
        }

        .flex-break-line {
            flex-basis: 100%;
            height: 0;
        }

        .content-separator {
            padding: $separator-margin $content-margin;
            margin: 0px !important;

            hr {
                border-top: 1px solid $receita-color !important;
                border-left: none !important;
                border-right: none !important;
                border-bottom: none !important;
                padding: 0px !important;
                margin: 0px !important;
            }
        }

        .content-header-wrapper {
            margin: 0px;

            ._head {
                display: flex;
                flex-wrap: wrap;
                padding: $content-margin $content-margin 0px $content-margin;

                .img-wrapper {
                    display: block;
                    text-align: left;
                    width: $head-logo-width;

                    .img-logo {
                        display: unset;
                        vertical-align: middle;
                        height: auto !important;
                        width: $head-logo-width;
                    }
                }

                .head-content {
                    display: inline-block;
                    text-align: left;
                    margin-left: $head-content-margin;
                    margin-top: auto;
                    max-width: $head-content-maxwidth;
                    
                    & label {
                        font-size: 1.2em;
                        font-weight: normal;
                    }
    
                    & label:last-child {
                        margin-bottom: 0px;
                    }
                }

                .tipo-receita {
                    display: block;
                    width: 100%;
                    margin-top: 20px;
                    text-align: center;
                    font-size: 1.5em;
                    font-weight: bold;

                }

            }
        }

        .content-body-wrapper {
            padding: 0px $content-margin;
            margin: 0px;

            ._header-receita-especial {
                .hre-bottom-line {
                    hr {
                        border-left: none !important;
                        border-right: none !important;
                        padding: 0px;
                        margin: 0px;

                        border-top: 1px solid $receita-color !important;
                        border-bottom: none !important;
                        margin-top: 15px !important;
                        padding-bottom: 15px !important;
                    }
                }
    
                .hre-title {
                    padding: 0px;
                    margin-bottom: 10px;
                    text-align: center;
                    font-size: 1.3em;
                    font-weight: bold;
                }

                .hre-info {
                    padding: 0px;

                    .fld-row {
                        margin-top: 5px;
                        
                        .fld-name {
                            float: left;
                            text-align: left;
                            font-weight: bold;
                        }

                        .fld-info {
                            float: left;
                            margin-left: 10px;
                            text-align: left;

                            & label {
                                margin: 0px;
                            }
                        }

                        .clear {
                            clear: both;
                            line-height: 0px;
                        }
                    }
                }

                &.ocultar {
                    display: none !important;
                }
            }

            ._body {
                .paciente {
                    padding: 5px 0px calc(1.5 * #{$items-padding}) $items-padding;
                    text-align: left;
                    font-size: 1.2em;
                    font-weight: bold;
                }

                .items {
                    text-align: left;

                    & div.continuo-row {
                        margin-left: 0px;
                        font-size: 1.05em;
                        font-weight: bold;
                        font-style: italic;
                        margin-bottom: 15px;
                    }

                    & div.interno-externo-row {
                        margin-left: $content-tipouso-margin;
                        font-size: 1em;
                        font-weight: bold;
                        margin-bottom: 12px;
                    }

                    & li.item-row {
                        margin-left: $content-item-margin;
                        margin-bottom: 12px;
                    }

                    & ul {
                        list-style-type: none;
                        padding-left: $items-padding;
                        padding-right: $items-padding;

                        & ul {
                            list-style-type: none;
                            padding-left: $content-margin;
                            padding-right: 0px !important;
                        }
                    }

                    & ul:first-of-type {
                        margin-bottom: 0px !important;
                    }
                }
            }

            .wrapper-registro-dispensacao {
                padding-top: $items-padding;
                font-size: 1em;

                .separador-registro {
                    height: 0.7em;
                }

                .registro-dispensacao-horiz, .registro-dispensacao-vert {
                    .title {
                        font-weight: bold;
                        text-align: left;
                        font-size: inherit;
                        margin-bottom: 2px;

                        .situacao-periodo {
                            font-weight: normal;
                        }
                    }
    
                    .no-wrap {
                        white-space: nowrap;
                    }

                    th, td {
                        border: 1px solid $table-border;
                    }

                    table {
                        border-spacing: 0px;
                        width: 100%;
                        font-size: inherit;
                    }

                    th {
                        background-color: $grd-disp-head-bgcolor;
                        color: $grd-disp-head-color;
                        padding: 2px 4px;
                        font-size: inherit;
                        font-weight: 600;
                    }

                    td {
                        text-align: left;
                        padding: 1px 4px;
                        font-size: 90%;
                        vertical-align: top
                    }
                }

                .registro-dispensacao-horiz {
                    display: inline-block;
                    font-size: inherit;
                    width: 100%;

                    table {
                        border: 1px solid $table-border;
                    }
                }

                .registro-dispensacao-vert {
                    margin: 0 auto;
                    display: none;
                    font-size: 95%;
                    min-width: 270px;
                    max-width: 400px;

                    td:first-child:not(.separador) {
                        background-color: $grd-disp-head-bgcolor;
                        color: $grd-disp-head-color;
                        font-size: 1em;
                        font-weight: 600;
                    }

                    td.separador {
                        border: none;
                    }
                }
            }
        }

        .content-footer-wrapper {
            .validacao {
                padding: $items-padding $content-margin 0px $content-margin;
                display: flex;
                justify-content: space-between;

                .validacao-msgs-wrapper {
                    margin-right: $content-margin;
                    width: 100%;
                    text-align: left;
                    margin-top: auto;
                    font-size: 1em;

                    .elaborado {
                        display: inline-block;
                        text-align: left;
                        font-size: inherit;
                    }

                    .mensagem-validacao {
                        margin-top: $content-margin;
                        display: block;
                        font-size: inherit;
                        cursor: text;

                        .enphasis {
                            font-weight: bold !important;
                        }
                    }
                }

                .qrcode-pv , .qrcode-lv {
                    overflow: visible;

                    img {
                        height: auto;
                    }

                    .qrcode-block {
                        display: inline-block;
                    }

                    .qrcode-block.pointer:hover {
                        cursor: pointer;
                    }

                }

                .qrcode-pv {
                    display: none;
                    margin-bottom: 20px;
                    height: auto;
                    width: $qrcode-size-mobile;

                    img {
                        width: $qrcode-size-mobile;
                    }
                }

                .qrcode-lv {
                    display: block;
                    height: $qrcode-size-desktop;
                    width: $qrcode-size-desktop;

                    img {
                        width: $qrcode-size-desktop;
                    }
                }

                .clear {
                    display: none;
                }
            }

            ._footer {
                padding: 0px $content-margin;
                text-align: left;

                & label {
                    font-size: 1.15em;
                    font-weight: normal;
                }

            }
        }

        .tooltip-wrapper {
            background-color: $main_shadowfog-bgColor;
            z-index: 9;
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .tooltip-box {
                background-color: $tootip-bgcolor !important;
                border: 1px solid $tooltip-border-color;
                color: $tootip-color;
                display: inline-block;
                z-index: 10;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 15px 20px;
                max-width: 600px;
                min-width: $app_minWidth;
                text-align: left;
                border-radius: 6px;
                opacity: 1;
                transition: opacity .5s linear;
                font-size: 1em;
                -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
                box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);

                a {
                    & {
                        text-decoration: underline;
                        color: $tootip-anchor-color !important;
                    }

                    &:hover {
                        text-decoration: underline;
                        color: $tootip-anchor-hover-color !important;
                    }
                }
            }
        }
    }
}

@mixin pdf-mode($wrapper-width: 695px, $content-margin: 20px, $separator-margin: 15px, $qrcode-size-desktop: 150px,
                $grid-header-bgcolor: rgb(200, 200, 200), $grid-bgcolor: rgb(0, 0, 0)) {
	.pdf-document {
		width: $wrapper-width;
		.content-wrapper {
			margin: 0px !important;
			max-width: 100% !important;
			padding-bottom: 0px !important;
			width: $wrapper-width;

            .content-separator {
                padding: $separator-margin $content-margin !important;
            }

			.content-header-wrapper {
				$logo-height: 108px;
				$logo-width: 300px;
				$tipo-receita-height: 25px;
                ._head {
					display: block !important;
					padding-top: $content-margin;
                    padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
		            
		            position: relative;
		            height: $logo-height;
		            
					.img-wrapper {
						float: left;
						display: block !important;
						width: $logo-width !important;
						
			            .img-logo {
			                width: $logo-width !important;
			            }
					}
					
					$left-content-margin: 40px;
					$max-content-width: $wrapper-width - 2*$content-margin - $logo-width - $left-content-margin;
					$left-content-position: $logo-width + $left-content-margin;
					.head-content {
						position: absolute;
						bottom: 0px;
						left: $left-content-position;
						margin-left: 0px !important;
						
						float: left;
						display: block !important;
						width: $max-content-width; 
						max-width: unset !important;
					}
				
					.flex-break-line {
						clear: both !important;
						line-height: 0px !important;
						flex-basis: unset !important;
						height: unset !important;
					}
				
					.tipo-receita {
						display: block !important;
						margin-top: $separator-margin !important;
						line-height: $tipo-receita-height;
					}
				
				}
			
				$separator-top-margin: $separator-margin + $tipo-receita-height;
				.content-separator {
					margin-top: $separator-top-margin !important;			
				}
			}

	        .content-body-wrapper {
	        	._header-receita-especial {
	        		margin-left: $content-margin !important;
	        		margin-right: $content-margin !important;
	        	}
	        	
	            ._body {
	            	padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
                }

                .wrapper-registro-dispensacao {
                    .registro-dispensacao-horiz {
                        th {
                            background-color: $grid-header-bgcolor !important;
                            color: $grid-bgcolor !important;
                        }
                    }
                }
            }
		
			.content-footer-wrapper {
				.validacao {
					display: block !important;
	            	padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
    
                    $validacao-line-height: 20px;
                    $msgs-separator-height: 30px;
                    $msgs-wrapper-width: $wrapper-width - $qrcode-size-desktop - 30px;
                    $msgs-wrapper-margin-top: $qrcode-size-desktop - (3 * $validacao-line-height) - $msgs-separator-height;

                    .validacao-msgs-wrapper {
                        float: left !important;
                        width: $msgs-wrapper-width !important;
                        margin-right: 0px !important;
                        margin-top: $msgs-wrapper-margin-top !important;

                        .elaborado {
                            line-height: $validacao-line-height !important;
                        }
                        .mensagem-validacao {
                            display: block !important;
                            margin-top: $msgs-separator-height !important;
                            padding-left: $content-margin !important;
                            padding-right: $content-margin !important;
                            line-height: $validacao-line-height;
                        }
                    }

					.qrcode-pv {
						display: none !important;
					}
					
					.qrcode-lv {
						float: right;
					}
					
					.clear {
						display: block !important;
						clear: both;
						line-height: 0px;
					}
				}			

				._footer {
	            	padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
				}
            }
            
            .tooltip-wrapper {
                display: none !important;
            }
		}
	}
}

@mixin receita-reposivo($sm-content-margin: 15px, $sm-items-padding: 12px) {
    $content-tipouso-margin: $sm-items-padding;
    $content-item-margin: 2 * $content-tipouso-margin;
    $separator-margin: $sm-items-padding;

    @media screen and (max-width: 320px) {
        .content-wrapper {
            .content-header-wrapper {
                ._head {
                    .head-content label { font-size: 0.98em !important; }
                    .tipo-receita { font-size: 1.25em !important; }
                }
            }

            .content-body-wrapper {
                ._header-receita-especial {
                    .hre-title { font-size: 1.12em !important; }
                    .hre-info { 
                        .fld-name { font-size: 0.88em !important; }
                        .fld-info { font-size: 0.88em !important; }
                    }
                }

                ._body {
                    .paciente { font-size: 98% !important; }
                    .items {
                        & div.continuo-row { font-size: 0.96em !important; }
                        & div.interno-externo-row { font-size: 0.93em !important; }
                        & li.item-row { font-size: 0.90em !important; }
                    }
                }

                .registro-dispensacao-vert { font-size: 0.85em !important; }
            }

            .content-footer-wrapper {
                .validacao { .validacao-msgs-wrapper { font-size: 0.85em !important; } }
                ._footer label { font-size: 1.02em !important; }
            }

            .tooltip-wrapper { .tooltip-box { font-size: 0.8em !important; } }
        }
    }

    @media screen and (min-width: 321px) and (max-width: 767px) {
        .content-wrapper {
            .content-header-wrapper {
                ._head {
                    .head-content label { font-size: 1em !important; }
                    .tipo-receita { font-size: 1.35em !important; }
                }
            }

            .content-body-wrapper {
                ._header-receita-especial {
                    .hre-title { font-size: 1.20em !important; }
                    .hre-info {
                        .fld-name { font-size: 0.9em !important; }
                        .fld-info { font-size: 0.9em !important; }
                    }
                }

                ._body {
                    .paciente { font-size: 1em !important; }
                    .items {
                        & div.continuo-row { font-size: 0.98em !important; }
                        & div.interno-externo-row { font-size: 0.95em !important; }
                        & li.item-row { font-size: 0.92em !important; }
                    }
                }

                .registro-dispensacao-vert { font-size: 0.95em !important; }
            }

            .content-footer-wrapper {
                .validacao { .validacao-msgs-wrapper { font-size: 0.9em !important; } }
                ._footer label { font-size: 1.1em !important; }
            }

            .tooltip-wrapper { .tooltip-box { font-size: 0.9em !important; } }
        }
    }

    @media screen and (max-width: 767px) {
        .content-wrapper {
            .content-header-wrapper {
                ._head {
                    display: block !important;
            
                    $logo-width: 250px !important;
                    .img-wrapper {
                        display: block;
                        width: auto !important;
                        text-align: center !important;
            
                        .img-logo {
                            height: auto !important;
                            width: $logo-width;
                        }
                    }
            
                    .head-content {
                        display: block !important;
                        margin: 20px 0px 0px 0px !important;
                        max-width: unset !important;
                        text-align: center !important;
                    }
                }
            }

            .content-body-wrapper {
                .registro-dispensacao-horiz { display: none !important; }
                .registro-dispensacao-vert { display: block !important; }
            }

            .content-footer-wrapper {
                .validacao {
                    flex-direction: column !important;
                    align-items: center !important;
                    margin-top: 20px !important;
                    .qrcode-pv { display: block !important; }
                    .qrcode-lv { display: none !important; }
                    .validacao-msgs-wrapper { 
                        text-align: center !important;
                        margin: 0px !important;
                        .mensagem-validacao { text-align: center !important; }
                    }
                }
            }

            .tooltip-wrapper { .tooltip-box { max-width: 85% !important; } }
        }
    }

    @media screen and (max-width: 414px) {
        .content-wrapper {
            .content-separator { padding: $separator-margin $sm-content-margin !important; }
    
            .content-header-wrapper {
                ._head {
                    padding-top: $sm-content-margin !important;
                    padding-left: $sm-content-margin !important;
                    padding-right: $sm-content-margin !important;
                }
            }

            .content-body-wrapper {
                padding: 0px $sm-content-margin !important;
                ._body {
                    .paciente { padding: 0px 0px calc(1.5 * #{$sm-items-padding}) $sm-items-padding !important; }

                    .items {
                        & div.interno-externo-row { margin-left: $content-tipouso-margin !important; }
                        & li.item-row { margin-left: $content-item-margin !important; }
                        & ul {
                            padding-left: $sm-items-padding !important;
                            padding-right: $sm-items-padding !important;
                            & ul { padding-left: $sm-content-margin !important; }
                        }
                    }
                }
            }
        
            .content-footer-wrapper {
                .validacao { 
                    padding: $sm-items-padding $sm-content-margin 0px $sm-content-margin !important; 
                    .validacao-msgs-wrapper {
                        .mensagem-validacao { margin-top: $sm-content-margin !important; }
                    }
                }

                ._footer {
                    padding-left: $sm-content-margin !important;
                    padding-right: $sm-content-margin !important;
                }
            }
            
            .tooltip-wrapper { 
                .tooltip-box { 
                    min-width: 240px !important;
                    padding: 10px 15px !important;
                }
            }
        }
    }
}
