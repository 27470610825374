/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'newsManager' module specific styles
 * ---------------------------------------------------------
**/

@include es-default-service-login-template(".news-manager-wrapper") {
    .action-buttons {
        margin-top: 1.5em;

        .btn-save {
            @include es-form-button();
        }
    }
}

#root > .app-viewport.external-service {
    .news-manager-wrapper {
        & > .content {
            .header-card .header-wrapper > .header-content{
                h1 {
                    font-size: 2.70em;
                }
            }
        }
    }
}
