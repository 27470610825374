/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appNavigationControls' module specific styles
 * ---------------------------------------------------------
**/

@mixin calc-nav-button-height() {
    @media screen and (max-width: $navButton_small-size-maxWidth) {
        height: $navButton_small-size;
    }
    @media screen and (min-width: $navButton_small-size-maxWidth) {
        height: $navButton_size;
    }
}

@mixin calc-nav-button-width() {
    @media screen and (max-width: $navButton_small-size-maxWidth) {
        width: $navButton_small-size;
    }
    @media screen and (min-width: $navButton_small-size-maxWidth) {
        width: $navButton_size;
    }
}

/*
** Accordion Colapsible */
div#accordionCollapsibleWrapperId {
    height: auto;
    width: $navAccordionColapse_width;

    &.active {
        .collapse { display: block; }
        .expand { display: none; }
    }

    &:not(.active) {
        .collapse { display: none; }
        .expand { display: block; }
    }

    img {
        height: auto;
        width: 100%;
    }
}

/*
** Navigation Button */
div#navigationButtonsWrapperId {
    .nav-button {
        display: block;
        border-radius: $navButton_radius;
        overflow: hidden;

        @include calc-nav-button-height();
        @include calc-nav-button-width();

        & img {
            height: 100%;
            width: 100%;
        }

        &.hidden {
            display: none !important;
        }

        &.disabled {
            &.card-style {
                background-color: $navButton_cardStyle-disabled-bgColor;
            }

            &:not(.card-style) {
                background-color: $navButton_default-disabled-bgColor;
            }
        }

        &:not(.disabled) {
            &:hover {
                cursor: pointer;
            }
    
            &.card-style {
                background-color: $navButton_cardStyle-bgColor;
            }

            &:not(.card-style) {
                background-color: $navButton_default-bgColor;
            }
        }
        
    }
}

/*
** Navigation Bar */
div#navigationBarWrapperId {
    position: fixed;
    bottom: 0;
    margin-bottom: $navBar_marginBottom;

    width: 100%;

    &.hide-nav-bar {
        visibility: hidden;
    }

    & > div.navbar-buttons-wrapper {
        background-color: $main_bgColor;
        padding-top: $navBar_paddingTop;
        padding-bottom: $navBar_paddingBottom;

        &> .nav-left {
            float: left;
        }

        &> .nav-right {
            float: right;
        }

        &> .nav-left, &> .nav-right {

            &.disabled> .nav-scroll-button {
                background-color: $navBar_scrollButton-disabled-bgColor;
            }

            &:not(.disabled)> .nav-scroll-button {
                background-color: $navBar_scrollButton-bgColor;

                &:hover {
                    cursor: pointer;
                }
            }

            &> .nav-scroll-button {
                display: block;
                overflow: hidden;
                border-radius: $navButton_radius;
                width: $navBar_scrollButton-width;

                @include calc-nav-button-height();
        
                & img {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &> .nav-middle {
            &.scroll-left {
                margin-left: calc(#{$navBar_scrollButton-width} + #{$navBar_button-spacing});
            }

            &.scroll-right {
                margin-right: calc(#{$navBar_scrollButton-width} + #{$navBar_button-spacing});
            }

            &> .nav-services-box {
                display: flex;
                text-align: left;
                justify-content: flex-start;
                white-space: nowrap;
                overflow: hidden;

                &.centered {
                    justify-content: center;
                }

                &:not(.centered) {
                    justify-content: flex-start;
                }

                div#navigationButtonsWrapperId {
                    display: flex;
                    flex: 0 0 auto;
                    overflow: hidden;

                    &> .nav-button:not(:first-child) {
                        margin-left: $navBar_button-spacing;
                    }
                
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    &:not(.mobile) {
        div#navigationBarWrapperId {
            & > div.navbar-buttons-wrapper {
                margin: 0 $navBar_horizontalPadding;
            }
        }
    }

    &.mobile {
        &.ios {
            div#navigationBarWrapperId {
                background-color: $main_bgColor;
            }
        }
    
        div#navigationBarWrapperId {
            padding: 0 $navBar_mobile-horizontalPadding;

            & > div.navbar-buttons-wrapper > .nav-middle > .nav-services-box {
                overflow-x: scroll !important;
                @include scrollbar-hide();
            }
        }
    }
}

div#accordionCollapsibleWrapperId {
    @media screen and (max-width: 375px) {
        width: calc(#{$navAccordionColapse_width} * 0.75) !important;
    }
}