/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appCard' module specific styles
 * ---------------------------------------------------------
**/
$card-bgColor: $main_bgColor;

@keyframes mountAnimationFade {
    0% { transform: scale(0.1); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes unmountAnimationFade {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.1); opacity: 0; }
}

@keyframes mountAnimationVerticalSlide {
    0% { transform: translateY($appCard_height); opacity: 0.1; background-color: transparent; }
    20% { opacity: 0.9; }
    98% { opacity: 0.99; background-color: transparent; }
    100% { transform: translateY(0); opacity: 1; background-color: $card-bgColor; }
}

@keyframes unmountAnimationVerticalSlide {
    0% { transform: translateY(0); opacity: 1; background-color: $card-bgColor; }
    2% { opacity: 0.99; background-color: transparent; }
    80% { opacity: 0.9; }
    100% { transform: translateY($appCard_height); opacity: 0.1; background-color: transparent; }
}

#root > .app-viewport {
    & > .card-wrapper {
        position: fixed;
        display: block;
        top: $appCard_top;
        background-color: $card-bgColor;
        width: 100%;
        min-width: $app_minWidth;

        text-align: left;

        &.card-mont-animation {
            animation: 'mountAnimation' 500ms ease-in;
        }

        &.card-umount-animation {
            animation: 'unmountAnimation' 500ms ease-in;
        }

        & > .card-box {
            height: $appCard_height;
            border-top-left-radius: $appCard_radius;
            border-top-right-radius: $appCard_radius;

            &.normal {
                background-color: $appCard_normal-bgColor;
                color: $appCard_normal-color;
            }

            &.forms {
                background-color: $appCard_forms-bgColor;
                color: $appCard_forms-color;
            }

            & > .card-header {
                &> .action-bar-wrapper {
                    border-top-left-radius: inherit;
                    border-top-right-radius: inherit;
                    height: $appCard_action-bar-height;

                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: space-between;

                    &> .action-box {
                        margin-bottom: $appCard_action-button-margin-bottom;
                        width: $appCard_action-bar-buttonSize;
                        height: $appCard_action-bar-buttonSize;
                    }

                    &> .action-button {
                        &:hover {
                            cursor: pointer;
                            opacity: 0.8;
                        }
                    }
                }

                & > .card-title {
                    margin: 0;
                    text-align: center;
                    height: $appCard_title-height;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
            
                    @include appFont-black();
                }
            }

            &> .module-wrapper {
                position: absolute;
                width: 100%;
                
                top: 0;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: column;

                &:not(.card-has-title) {
                    margin-top: $appCard_action-bar-height;
                }
                &.card-has-title {
                    margin-top: calc(#{$appCard_action-bar-height} + #{$appCard_title-height});
                }

                & > .module-box {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    min-height: 0;

                    & > .module-content {
                        flex-grow: 1;
                        min-height: 0;
                        overflow-y: auto;
                    }
                }
            }
        }
    }

    &.layout-wifi {
        & > .card-wrapper {
            background-color: $wifi_main-bgColor;

            & > .card-box {
                &.normal {
                    background-color: $appCard_wifi-bgColor;
                    color: $appCard_wifi-color;
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    &:not(.mobile) {
        & > .card-wrapper > .card-box {
            & > .card-header > .action-bar-wrapper, 
            & > .card-header > .card-title, 
            & > .module-wrapper > .module-box > .module-content {
                padding: 0 $appContent_horizontalPadding;
            }
        }
        .module-wrapper > .module-box > .module-content {
            @include scrollbar-style();
        }
    }

    &.mobile {
        & > .card-wrapper > .card-box {
            & > .card-header > .action-bar-wrapper,
            & > .card-header > .card-title, 
            & > .module-wrapper > .module-box > .module-content {
                padding: 0 $appContent_mobile-horizontalPadding;
            }
        }

        .module-wrapper > .module-box > .module-content {
            @include scrollbar-hide();
        }
    }
}
