/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'faqContent' module specific styles
 * ---------------------------------------------------------
**/
div#faqContentId {
    font-size: 0.95em;

    .ui.fluid.accordion {
        .accordion-item {
            .title {
                .title-info-wrapper {
                    padding-right: 1em;
                    @include appFont-bold();
                }
            }

            .content {
                font-size: 0.9em;

                .pergunta {
                    margin-top: 0.33em;
                }

                .tags-list {
                    margin-top: 10px;

                    a:first-of-type {
                        margin-left: 7px;
                    }
                }

                .image-carousel {
                    margin-top: 1em;
                    max-width: 750px;
                }
            }
        }
    }
}
