/* -----------------------------------------------
 * APPLICATION - FONT FACE DEFINITION
 * -----------------------------------------------
 */
@font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'), url(../fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'), url(../fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Black';
    src: local('Montserrat-Black'), url(../fonts/Montserrat/Montserrat-Black.ttf) format('truetype');
}
