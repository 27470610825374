/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'connectionIndicator' module specific styles
 * ---------------------------------------------------------
**/
@mixin indicator-box($size, $shadowSize, $borderSize) {
    $boxSize: calc(#{$size} - calc(2 * #{$shadowSize}));
    $interiorSize: calc(#{$boxSize} - #{$borderSize});
    & > .indicator-box {
        position: relative;
        border-radius: 50%;
        width: $boxSize;
        height: $boxSize;
        box-shadow: 0 0 0 $shadowSize;
        background-color: transparent;

        & > .interior {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: $interiorSize;
            height: $interiorSize;
            border: none;
        }
    }
}    

$indicatorBorder: 2px;
$indicatorShadow: 2px;
#connectionIndicatorId {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    z-index: 1;
    padding: $indicatorShadow;

    &.connected {
        $connected-color: $teleatendimento_connection-indicator-connectedColor;
        & > .indicator-box {
            color: $connected-color;
            & > .interior {
                background-color: $connected-color;
            }
        }
    }

    &.disconnected {
        $disconnected-color: $teleatendimento_connection-indicator-disconnectedColor;
        & > .indicator-box {
            color: $disconnected-color;
            & > .interior {
                background-color: $disconnected-color;
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    &:not(.mobile) {
        #connectionIndicatorId {
            @include indicator-box($teleatendimento_connection-indicator-desktop-size, $indicatorShadow, $indicatorBorder);
        }
    }

    &.mobile {
        #connectionIndicatorId {
            @include indicator-box($teleatendimento_connection-indicator-mobile-size, $indicatorShadow, $indicatorBorder);
        }
    }
}
