/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'newsEditList' module specific styles
 * ---------------------------------------------------------
**/

div#newsEditListId {
    .news-content-row {
        border-left: none;
        border-right: none;
        border-bottom: solid 1px;
        border-color: $servicos_default-header-bgColor;
        padding: 0.5em 0;

        &:first-child {
            border-top: solid 1px;
            margin-top: 1em;
        }

        &:not(.visible) {
            .news-item-block {
                .news-thumbnail, .carousel-wrapper {
                    opacity: 0.5;
                }
            }
        }

        &.changed {
            background-color: $servicos_default-item-changed-color;
        }

        .news-item-block {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0.3em;
            margin-bottom: 0.3em;
    
            .action-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-right: 0.5em;

                $order-block-height: 1.43em;
                & > .order-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: $order-block-height;
                    margin-right: 0.75em;

                    .current-order-wrapper {
                        position: relative;
                        width: 35px;
                        line-height: $order-block-height;

                        .order-value {
                            background-color: darken($servicos_default-bgColor, 5%);
                            font-size: 0.8em;
                            cursor: pointer;
                        }

                        .edit-order-wrapper {
                            background-color: $servicos_default-header-bgColor;
                            position: absolute;
                            padding: 5px 0.5em;
                            border: none;
                            border-radius: 5px;
                            top: -5px;
                            left: 100%;
                            z-index: 1;

                            display: flex;

                            &:not(.enabled) {
                                display: none;
                            }

                            input {
                                border: solid 1px;
                                width: 40px;
                                font-size: 0.8em;

                                &:focus {
                                    outline: none;
                                }
                            }

                            .change-order-ok {
                                background-color: $servicos_default-header-color;
                                color: $servicos_default-header-bgColor;
                                border-radius: 6px;
                                padding-left: 0.5em;
                                padding-right: 0.5em;
                                margin-left: 0.4em;
                                line-height: 1.8em;

                                @include appFont-bold();

                                font-size: 0.8em;
                                text-transform: uppercase;

                                &.enabled {
                                    cursor: pointer;
                                }

                                &:not(.enabled) {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }

                    .order-change-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-self: center;
                        margin-left: 0.4em;

                        .order-up, .order-down {
                            width: 10px;
                            height: 8px;
                            line-height: 10px;
                            color: $servicos_form-info-button-color;

                            img {
                                height: 100%;
                                width: 100%;
                            }

                            &.disabled {
                                visibility: hidden;
                                cursor: not-allowed;
                            }

                            &:not(.disabled) {
                                cursor: pointer;
                            }
                        }

                        .order-down {
                            margin-top: 0.3em;
                        }
                    }
                }

                & > .right-block-wrapper {
                    display: flex;
                    flex-direction: column;
                    font-size: 0.95em;

                    .info-row {
                        display: block;
                        text-align: left;

                        &.item-visibility {
                            display: flex;
                            align-items: center;
                            margin-bottom: 0.5em;

                            .visibility-toggle {
                                @include es-toggle();
                            }
                        }

                        & > .caption {
                            @include appFont-bold();
                            margin-right: 0.5em;
                        }

                        & > .value {
                            display: inline-block;
                            white-space: nowrap;
                        }
                    }

                    .missing-value {
                        color: $main_warning-color;    
                    }

                    .image-error {
                        display: block;
                        text-align: left;
                        margin-top: 1em;
                        color: $main_warning-color;
                    }

                    .buttons-wrapper {
                        margin-top: 1.5em;
                        display: flex;
                        flex-direction: row;
                        justify-content:flex-start;

                        $item-button_size: 40px;
                        .item-preview, .item-edit, .item-delete {
                            display: inline-block;
                            width: $item-button_size;
                            height: $item-button_size;
                            font-size: 0.8em;
                            cursor: pointer;

                            &:not(:first-child) {
                                margin-left: 0.33em;
                            }
                        }
                    }
                }
            }

            $news-thumbnail-width: 300px;
            .news-wrapper {
                position: relative;

                .news-thumbnail {
                    display: inline-block;
                    width: $news-thumbnail-width;
                    visibility: hidden;

                    &.peview-allowed {
                        cursor: zoom-in;
                    }

                    &.visible{
                        visibility: visible;
                    }

                    img {
                        height: auto;
                        width: 100%;
                    }
                }

                div#previewWrapperId {
                    position: absolute;
                    left: 0;
                    top: 0;
            
                    &.full-preview {
                        position: fixed;
                        width: 100%;
                        height: 100%;

                        background-color: $appModal_shadowfog-bgColor;
                        z-index: 100;

                        $preview-horiz-padding: 1.5em;
                        .carousel-wrapper {
                            opacity: 1;
                            width: 100%;
                            max-width: calc(#{$app_default-card-module-content-max-width} + 2 * #{$preview-horiz-padding});
                            padding: 1.5em $preview-horiz-padding;
                            background-color: $main_bgColor;

                            position: fixed;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    & > .carousel-wrapper {
                        display: inline-block;
                        width: $news-thumbnail-width;
            
                        .carousel-root {
                            .axis-horizontal {
                                height: auto !important;
                            }
                        }
                    }
                }
            }

            & > .inset-before, & > .inset-after {
                background-color: $servicos_default-header-bgColor;
                color: $servicos_form-info-button-color;
    
                position: absolute;
                display: inline-block;
                border-radius: 7px;
                width: 14px;
                height: 14px;
                line-height: 14px;
                left: 50%;
                padding-left: 1px;
                cursor: pointer;
            }
    
            & > .inset-before {
                top: -1.3em;
            }
    
            & > .inset-after {
                bottom: -1em;
            }
        }
    }
}
