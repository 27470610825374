/* -----------------------------------------
 *  Estilos específicos de appModal
 * -----------------------------------------
**/
@import '../../../styles/common.scss';


#wrapperAppModalId {
    color: inherit;

    @include floating-container(fixed, 9100) {
        top: 0;
        left: 0px;
        height: 100%;
        width: 100%;

        background-color: $appModal_shadowfog-bgColor;
    }

    & > .app-modal-floating-box {
        color: inherit;

        @include floating-container(fixed) {
            width: 90%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        & > .app-modal-box {
            background-color: $appModal_bgColor;
            color: inherit;
            margin: 0px;
            padding:  15px 5px;
            border-radius: 4px;
            width: 100%;
            text-align: left;
            font-size: 1em;

            & > .app-modal-header {
                background-color: inherit;
                color: inherit;
                display: block;
            }
            
            & > .app-modal-content-wrapper {
                padding: 0px 15px;
                max-height: 450px;
                overflow-x: none !important;
                overflow-y: auto !important;
                @include scrollbar-style();

                @media screen and (max-height: 350px) { max-height: 200px !important; }
                @media screen and (min-height: 350px) and (max-height: 450px) { max-height: 220px !important; }
                @media screen and (min-height: 450px) and (max-height: 480px) { max-height: 290px !important; }
                @media screen and (min-height: 480px) and (max-height: 520px) { max-height: 350px !important; }
                @media screen and (min-height: 520px) and (max-height: 560px) { max-height: 400px !important; }
    
                & > .app-modal-content {
                    border: none;
                    background-color: transparent;
                }
            }
        }
    }
}
