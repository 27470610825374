/* Import common definition styles */
@import '../../styles/common.scss';


/* ---------------------------------------------------------
 *  'redefinirSenha' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .redefinir-senha-wrapper {
        background-color: $main_bgColor;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        width: 100%;

        & > .content-wrapper {
            & > .content-box {
                display: inline-block;
                padding: $appContent_defaultVerticalPadding 0;
                width: 75%;
                max-width: $app_default-auth-max-width;

                & > .header {
                    flex-grow: 0;
                    padding: 0;
                    font-size: inherit;

                    .app-logo {
                        margin: 0;
                        width: 100%;
                        text-align: center;

                        img {
                            display: inline-block;
                            height: 70px;
                            width: auto;
                            vertical-align: middle;
                        }
                    }
                }

                & > .body {
                    text-align: center;
                    padding: 1.5rem 0 0 0;
                    font-size: inherit;

                    @include mc-form-input-float-label($main_color, $main_bgColor, $main_color, $main_color);

                    .info-principal {
                        margin-top: 2rem;
                        font-size: inherit;
                        
                        h2 {
                            font-size: 1.7em !important;
                        }
                    }

                    .nova-senha {
                        margin-top: 4rem;
                    }

                    .main-action {
                        margin-top: 2.5rem;
                    }
    
                    .cancelar-redefinicao-senha {
                        margin-top: 1rem;
    
                        a {
                            font-size: 0.9em;
                        }
                    }
                }        
            }
        }
    }
}


/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .redefinir-senha-wrapper {
        & > .content-wrapper {
            & > .content-box {
                @media screen and (max-width: 390px) {
                    width: 90% !important;
                    font-size: 0.9rem;
                }
            }
        }
    }
}