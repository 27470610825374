/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'visualizaEstudoPACS' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport.external-service {
    .visualizador-estudo-pacs-wrapper {
        font-size: $servicos_default-base-font-size;
        height: 100%;
        width: 100%;
        margin: 0px;
        padding: 0px;

        & > .content-box {
            background-color: inherit;
            color: inherit;
            font-size: inherit;
            min-width: 320px;
            
            padding: calc(#{$appContent_defaultVerticalPadding} * 0.66) $appContent_horizontalPadding;

            .information-card {
                &.info-success {
                    @include message-box($main_information-bgColor, $main_color) {
                        margin-top: $appContent_defaultVerticalPadding;
                    }
                }

                &.info-error {
                    @include message-box($main_warning-color, $main_color) {
                        margin-top: $appContent_defaultVerticalPadding;
                    }
                }
            }

            .header-card {
                @include es-card-envelope(0px, 15px 20px, 8px) {
                    background-color: $servicos_default-header-bgColor;

                    @include es-default-header-content(auto, 70px, 1.7rem, $servicos_default-header-color);
                }
            }

            .action-buttons {
                padding: 2em 0;

                .btn-servicos {
                    @include es-form-button();
                }
    
                .retry-button {
                    max-width: 280px;
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
@mixin header-responsive($space-between, $font-size-title, $font-size, $title-margin-bottom) {
    .header-wrapper {
        .header-content {
            margin-left: $space-between !important;
            h1 { font-size: $font-size-title !important; margin-bottom: $title-margin-bottom !important; }
            h2 { font-size: $font-size !important; }
        }
    }
}

/* 
 * Include mixin de responsividade para seções do componente 
 */
 #root > .app-viewport.external-service {
    .visualizador-estudo-pacs-wrapper {
        @media screen and (max-width: 376px) {
            @include header-responsive(1.2rem, 1.5rem, 0.88rem, 0.33rem);
            .information-card { div { font-size: 0.85em !important; } }
        }

        @media screen and (min-width: 376px) and (max-width: 440px) {
            @include header-responsive(1.4rem, 1.6rem, 0.95rem, 0.33rem);
        }

        @media screen and (min-width: 440px) and (max-width: 580px) {
            @include header-responsive(1.6rem, 1.9rem, 1rem, 0.45rem);
        }

        @media screen and (min-width: 580px) {
            @include header-responsive(1.7rem, 2.2rem, 1.1rem, 0.45rem);
        }
    }
}
