/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'cartaoPaciente' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('cartao-paciente') {
    div#cardInfoWrapperId {
        & > .primary-section {
            text-align: center;
            color: $cartaoPaciente_text-color;

            & > div.info-row {
                margin-top: 1em;
            }

            & > .info-title {
                @include appFont-black();
                font-size: 1.6em;
                padding: 1em 0;
            }

            .titulo {
                @include appFont-bold();
                font-size: 1.05em;
            }

            .descricao {
                font-size: inherit;
            }
        }

        & > .qrcode-section {
            margin-top: 2em;
            padding-bottom: 2em;

            .qrcode-wrapper {
                display: flex;
                justify-content: center;

                .qrcode-content {
                    background-color: $main_qrCode-wrapper-color;
                    border-radius: 10px;
                    box-sizing: content-box;
                    overflow: hidden;
                    display: table;
                    margin: auto;

                    canvas {
                        margin: 0.7em;
                    }
                }
            }
        }
    }

    & > .download-patient-card {
        text-align: center;
        font-size: 0.9em;

        a {
            &, &:active, &:focus, &:visited {
                color: $cartaoPaciente_text-color;
            }

            &:hover {
                color: darken($cartaoPaciente_text-color, 5%);
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .cartao-paciente-wrapper {
        & > .content-wrapper {
            & > .content-box {
                .primary-section {
                    & > .info-title { 
                        @media screen and (max-width: 375px) {
                            font-size: 1.45em !important;
                        }
                    }
                }
            }
        }
    }
}
