/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'compartilharEstudo' module specific styles
 * ---------------------------------------------------------
**/

@include card-module-basic-wrapper-block('compartilhar-estudo') {
    & > .sections-wrapper {
        & > .primary-section {
            text-align: center;

            & > div.info-row {
                margin-top: 1em;
            }

            .titulo {
                @include appFont-bold();
                font-size: 1.05em;
            }


            .descricao, .message {
                font-size: inherit;
            }
        }

        & > .qrcode-section {
            margin-top: 2em;
            padding-bottom: 2em;

            .qrcode-wrapper {
                display: flex;
                justify-content: center;

                .qrcode-content {
                    background-color: $main_qrCode-wrapper-color;
                    border-radius: 10px;
                    box-sizing: content-box;
                    overflow: hidden;
                    display: table;
                    margin: auto;

                    canvas {
                        margin: 0.7em;
                    }
                }
            }
        }

        & > .section-link {
            text-align: center;

            .copyable-field {
                display: inline-block;
                width: 100%;
                max-width: 480px;

                @include select-enabled();
            }

            .copy-message {
                color: $main_emphasis-color;
                margin-top: 0.5em;
                font-size: 0.9em;

                &.error {
                    color: $main_warning-color;
                }
            }

            .action {
                margin-top: 1.5em;

                & > div {
                    display: inline-block;
                    width: 250px;
                }
            }
        }
    }
}
