/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'vincularCadastro' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('vincular-cadastro') {
    .information-section {
        margin-top: 1em;

        .identificacao-usuario {
            @include appFont-black();

            text-align: center;
        }

        .link-user-message {
            margin-top: 1em;

        }

        .link-user-form {
            margin-top: 1.5em;
            background-color: transparent;
            color: $appCard_forms-color;

            @include mc-form-principal();

            .form-content {
                color: inherit;

                .group-card { /* Estilos e lay-out dos agrupamentos e grid */
                    margin: 0.6em 0;
                    padding:  0.6em 0;

                    background-color: $appCard_forms-bgColor;
                    overflow: hidden;
                    color: inherit;
                    text-align: left;

                    &:last-child {
                        margin-bottom: 0;
                    }
            
                    & > ._djfb_grid-group-title {
                        margin-bottom: 0.75em;
                        margin-top: 5px;
                        color: $mainForm_grid-group-base-text-color;
                        @include appFont-bold();
                    }

                    & > ._djfb_grid-group-description {
                        margin-bottom: 0.3em;
                        color: lighten($mainForm_grid-group-base-text-color, 15%);
                    }

                    & > ._djfb_grid-group-title, & > ._djfb_grid-group-description, & > ._djfb_grid-group-content {
                        padding: 0 1em;
                    }

                    & > ._djfb_grid-group-content {
                        /* fields specific styles */
                        ._djfb_grid-column {
                            .email-usuario, .senha-usuario {
                                margin: $mainForm_field-vertical-margin 0;
                            }

                            .email-usuario .field-content {
                                color: $mainForm_grid-group-base-text-color;
                            }

                            .senha-usuario {
                                .float-label-field.non-floating-placeholder {
                                    margin-top: 0;
    
                                    .ui.label.label {
                                        color: lighten($mainForm_grid-group-base-text-color, 25%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main-action {
        padding-top: 1.5rem;

        &:not(:empty) {
            padding-bottom: 1.5rem;
        }
    }
}

#root > .app-viewport {
    .vincular-cadastro-wrapper {
        background-color: transparent;

        & > .content-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;

            & > .content-box {
                width: 100%;
                max-width: $app_default-card-module-content-max-width;

            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
