/* Import common definition styles */
@import '../../../components/general/laudosAtestadoView/laudosAtestadosView.scss';

@mixin documento-validado-viewer() {
    /* Vizualizador e importação mixin 'receita-content' */
    $min-width: 310px;
    $wrapper-heigth: calc(var(--vh, 1vh) * 100);
    $wrapper-heigth-fallback: 100vh;
    .documento-valido-viwer-wrapper {
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $wrapper-heigth-fallback;
        height: $wrapper-heigth;
        min-width: $min-width;

        @media print { 
            height: 100% !important;
            width: 100% !important;
            overflow: hidden !important;
        }

        $content-heigth: calc(100% - #{$viewerModal_default-header-height} - #{$viewerModal_default-footer-height});
        & > div#viwerModalId {
            @include receita-content($content-heigth, 30px, 20px, 94%,
                $receitaView_bgColor, $receitaView_color,
                $receitaView_grd-disp-header-bgcolor, $receitaView_grd-disp-header-color);

            @include laudos-atestados-content($content-heigth, 30px, 20px, 94%,
                $laudosAtestadosView_bgColor, $laudosAtestadosView_color);
        }

        & > div#watermarkID {
            & > .watermark-wrapper {
                height: calc(#{$content-heigth} - 3rem);
            }
        }

        .vw-modal-header_custom {
            background-color: $servicos_default-header-bgColor;
            color: $servicos_default-header-color;

            @media print {
                display: none !important;
            }

            .vwhc-wrapper {
                background-color: inherit;
                color: inherit;
                display: flex;
                margin: 0px 0px;
                height: $viewerModal_default-header-height;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                @include round-cross-btn(10) {
                    top: calc(#{$viewerModal_default-header-height} / 2);
                    right: 10px;
                    transform: translateY(-50%);

                    &:hover, &:focus {
                        .close {
                            opacity: 0.8;
                        }
                    }
                }
        
                .vwhc-content {
                    margin: 0 40px;
                    width: 100%;
                    min-width: 160px;

                    h2 {
                        color: inherit;
                        font-size: 1.3em;
                    }
                }
            }
        }

        .vw-modal-body {
            background-color: darken($validadorDoc_main-bgColor, 10%);

            @media print { 
                @include scrollbar-hide();
            }

            @include es-scrollbar-style();                

            .vw-modal-body-content {
                background-color: inherit;
            }
        }

        .panel-action-buttons {
            @include floating-container() {
                height: 38px;
                width: 300px;
                left: 50%;
                bottom: 15px;
                transform: translateX(-50%);
            }

            .btn-pdf, .btn-dispensa {
                padding: 0;
                margin: 4px;
                font-size: 0.75rem;

                &:disabled {
                    opacity: 1;
                }
            }

            .btn-pdf {
                width: 90px;
            }

            .btn-dispensa {
                width: 190px;
            }
        }

        $wrapper-heigth: calc(100% - #{$viewerModal_default-header-height});
        $wrapper-top: $viewerModal_default-header-height;
        $viewer-heigth-compensation: calc(#{$viewerModal_default-header-height} / 2);
        .wrapper-form-dispensacao {
            @include floating-container(fixed, 91000) {
                top: $wrapper-top;
                left: 0px;
                height: $wrapper-heigth;
                width: 100%;

                background-color: $validadorDoc_modal-shadowfog-color;
            }

            .wrapper-confirmacao-dispensacao {
                background-color: $validadorDoc_modal-shadowfog-color;

                .confirmation-box {
                    max-width: 300px;
                }

                .title {
                    color: $main_warning-color;
                    font-size: 1.1rem;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .message {
                    color: $validadorDoc_main-color;
                    font-size: 0.9rem;
                }

                .btn-confirmacao {
                    font-size: 0.9rem;
                }
            }

            .floating-form-dispensacao {
                @include floating-container(fixed) {
                    width: 80%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, calc(-50% + #{$viewer-heigth-compensation}));
                }
    
                .card-dispensacao {
                    @include es-card-envelope(0px, 15px 5px) {
                        background-color: $servicos_default-card-bgColor;
                        width: 100%;
                        text-align: left;
                        font-size: 0.8em;
                    }
                    
                    & > .card-box {
                        padding: 0px 10px;
                        max-height: 450px;
                        overflow-x: none !important;
                        overflow-y: auto !important;

                        .card-content {
                            @include es-form-servicos(
                                0.9rem,
                                $servicos_form-field-bgColor, $servicos_form-field-border-color,
                                $servicos_form-field-border-hover-color, $servicos_form-field-text-color, 
                                $servicos_form-field-placeholder-color, $servicos_form-field-disabled-color, 
                                $servicos_form-field-error-message-color, $servicos_form-field-success-message-color,
                                $servicos_form-field-vertical-margin, 
                                28px); // Estilos dos campos dos formulário
                    
                            .input-field, .textarea-field, .dropdown-field {
                                margin: 0;

                                input, select {
                                    padding: 4px 10px;
                                }

                                input, textarea, select {
                                    width: 100%;
                                }

                                select {
                                    padding-left: 0px !important;  /* Saffari o padding é sempre zero */
                                    text-indent: 6px; /* Funciona em todos os browsers */
                                }

                                .input-wrapper, .masked-input-wrapper {
                                    display: flex;
                                }

                                .field-error {
                                    color: $main_warning-color;
                                }
                            }

                            .form-row {
                                position: relative;
                                margin-top: 0.6em;
                                margin-bottom: 0px;

                                &.anotacao-dispensacao {
                                    position: relative;

                                    textarea {
                                        resize: none;
                                    }

                                    .input-counter {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        font-size: 1.1em;

                                        .count-current {
                                            &.zero { color: $main_warning-color !important; }
                                        }
                                    }
                                }
        
                                &.conselho {
                                    ._djfb_grid-group-content {
                                        display: flex;
                                    }

                                    ._djfb_grid-cell.children:last-child {
                                        margin-left: 0.75em;
                                        width: 100%;
                                    }

                                    .conselho-uf {
                                        width: 105px;
                                    }

                                    .conselho-crf {
                                        width: 100%;
                                    }
                                }
                                
                                .field-group {
                                    text-align: left;
                                    margin: 0;
            
                                    label {
                                        font-size: inherit;
                                        margin-bottom: 0px;
                                    }
        
                                    select option:not([value="XX"]) {
                                        color: $validadorDoc_form-default-color !important;
                                    }
            
                                    .placeholder {
                                        color: $servicos_form-field-placeholder-color;
                                    }
            
                                    .field-message {
                                        margin-top: 5px;
                                        font-size: inherit;
                                        line-height: 1em;
                                    }
                                }
                            }
                        }
                    }

                    .action-buttons {
                        padding: 12px 10px 0px 10px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .btn-wrapper {
                            width: 49%;
                        }

                        .btn-form-dispensacao {
                            padding-top: 4px;
                        }
                    }
                }
            }
        }

        .wrapper-download-error {
            @include floating-container(fixed) {
                width: 100%;
                height: 100%;
                font-size: 1em;
                background-color: $validadorDoc_modal-shadowfog-color;

                top: 50%;
                left: 50%;
                transform: translate(-50%, calc(-50% + #{$viewerModal_default-header-height}));
            }

            @include no-select();

            .download-error-box {
                max-width: 360px;
            }

            .message {
                color: $main_warning-color;
                font-size: 1.1em;
            }

            .btn-download-error {
                padding-left: 25px !important;
                padding-right: 25px !important;
            }
        }
        
        .dialog-shadow {
            @include es-card-shadow(0px, 0px, 10px, 5px, $color: rgb(150, 150, 150));
        }
    }
}
