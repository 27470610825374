/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'meusDados' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('meus-dados') {

    & > div#confirmationDlgWrapperId > .confirmation-box {
        max-width: 420px;
    }

    & > .user-information-section {
        margin-top: 0;
        padding: 0;
        overflow: visible;

        .section-box {
            padding: 0;
            .section-content {
                margin: 1em 0;
                text-align: center;

                .titulo {
                    @include appFont-bold();
                    font-size: 1.05em;
                }

                & > div:not(:first-child) {
                    margin-top: 1em;
                }
            }
        }

        .action-cartao {
            .logo-cartao-wrapper {
                margin: 0 auto;
                height: auto;
                width: 50px;

                &:hover {
                    cursor: pointer;
                }

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .legenda {
                font-size: 0.9em;
            }
        }
    }

    & > .main-action {
        margin-top: 1.5em;
        padding-bottom: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & div {
            align-self: center;
            width: 250px;

            &:not(:first-child) {
                margin-top: 1em;
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
