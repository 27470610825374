/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'faqEditModal' module specific styles
 * ---------------------------------------------------------
**/

div#faqEditModalId {
    & > div#wrapperAppModalId {
        .app-modal-floating-box {
            @media screen and (max-width: 525px) {
                min-width: 480px;
                left: 20px !important;
                transform: translate(0, -50%);
            }

            & > .app-modal-box {
                background-color: $main_bgColor;

                & > .app-modal-content-wrapper {
                    max-height: 600px !important;
                    @media screen and (min-height: 560px) and (max-height: 650px) { max-height: 500px !important; }
                    @media screen and (min-height: 650px) and (max-height: 700px) { max-height: 550px !important; }
                }
            }
        }

        .modal-header-wrapper {
            height: calc(#{$appCard_action-bar-buttonSize} + 1em);
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & > .header-title {
                display: inline-block;
                color: $main_color;
                margin-left: 1em;
                padding-left: $appCard_action-bar-buttonSize;
                width: 100%;
                text-align: center;

                @include appFont-bold();
                font-size: 1.1em;
                text-transform: uppercase;
            }

            & > .close-button {
                margin-right: 1em;
                width: $appCard_action-bar-buttonSize;
                height: $appCard_action-bar-buttonSize;

                &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }

        .faq-editor-wrapper {
            min-height: 250px;

            .faq-form-wrapper {
                background-color: $servicos_default-header-color;
                padding: 1em;
                font-size: 1em;

                .ui.form.editor-content {
                    @include es-form-servicos(0.9rem);

                    .form-instructions {
                        padding-bottom: 0.5em;
                        margin-bottom: 0.5em;
                        border-bottom: 1px solid $servicos_default-header-bgColor;

                        .aviso {
                            margin-bottom: 0.5em;
                            color: $servicos_default-information-color;
                        }
                    }

                    .form-row {
                        position: relative;
                        text-align: left;
                        margin-bottom: 5px;

                        i.field-required {
                            position: relative;

                            &:not(:first-child) {
                                margin-left: 0.2em;
                            }
                            
                            &::after {
                                content: '*';
                                font-style: normal;
                                color: $servicos_form-field-required-mark-color;
                            }
                        }

                        i.field-information {
                            position: relative;
                            background-color: $servicos_form-info-button-bgColor;
                            margin-left: 0.5em;
                            padding: 1px 5px;
                            border-radius: 7px;
                            font-size: 9px;  
                            top: -1px;                              

                            &::after {
                                content: 'i';
                                font-style: normal;
                                color: $servicos_form-info-button-color;
                            }
                        }

                        $disabled-color: $servicos_form-field-disabled-color;
                        .disabled {
                            color: $disabled-color !important;
                            -webkit-text-fill-color: $disabled-color !important;
                            border-color: $disabled-color !important;
                            &:hover {
                                border-color: $disabled-color !important;
                                box-shadow: none !important;
                                -webkit-box-shadow: none !important;
                            }
                        }

                        .field-block-row {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                        }
                    
                        .field-error {
                            color: $main_warning-color;
                            margin-top: 2px;
                            font-size: inherit;
                        }

                        .item-visibility {
                            display: flex;
    
                            .visibility-toggle {
                                @include es-toggle();
                                & {
                                    margin-left: 0.5em;
                                }
                            }
                        }

                        .textarea-field {
                            .input-counter {
                                position: absolute;
                                top: 0;
                                right: 3px;
                                font-size: 1.1em;
                            }
                        }
                    }

                    .new-item-order {
                        margin-bottom: $servicos_form-field-vertical-margin;

                        span {
                            color: $servicos_default-information-color;
                        }
                    }

                    .fld-question-id {
                        width: 300px;
                    }

                    .fld-titulo, .fld-resposta {
                        & textarea {
                            resize: none;
                        }
                    }

                    .fld-tags-id {
                        max-width: 100%;
                    }

                    .form-action {
                        float: right;
                        width: 100px;
                        margin-top: $servicos_form-field-vertical-margin;

                        .btn-save {
                            @include es-form-button();
                        }
                    }
                }
            }

            .faq-preview-wrapper {
                padding: 0;
        
                .preview-content {
                    margin-top: 1em;
                }
            }
        }
    }
}
