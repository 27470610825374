/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'invalidPage' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .page-not-found-wrapper {
        background-color: $main_bgColor;
        color: $main_color;
        height: 100%;

        & > .page-content {
            background-color: inherit;
            color: inherit;
            padding: 2.5rem 0;
            font-size: 1rem;
            
            & > .image-wrapper {
                display: inline-block;
                width: 80%;
                max-width: 375px;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            & > .link-wrapper {
                display: block;
                padding-top: 1rem;

                #link-pagina-inicial {
                    font-size: 1rem;
                    text-decoration: underline;
                    color: $main_anchor-color;

                    &:visited {
                        color: $main_anchor-color;
                    }
                
                    &:active, &:focus, &:hover {
                        text-decoration: underline;
                        color: $main_anchor-hover-color;
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
