/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'exames' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('exames') {
    & > .results-section {
        font-size: 0.94em;
        margin-top: 2em;

        .accordion-item {
            & > .title {
                font-size: 1em;

                .title-info-wrapper {
                    padding-right: 1em;
                    @include appFont-default();

                    .indicativo-novo, .information, 
                    .information > .data, .information > .solicitacao {
                        display: inline-block;
                    }

                    .indicativo-novo {
                        margin-right: 0.5em;
                    }

                    .information {
                        & > .data {
                            margin-right: 0.4em;

                            &.separator::after {
                                content: '|';
                                margin-left: 0.4em;
                            }
                        }
                    }
                }
            }
            
            & > .content {
                font-size: 0.95em;

                .content-row {
                    .titulo-solicitacao, .titulo-resultados, .titulo-anexo {
                        @include appFont-bold();
                        margin-bottom: 0.2em;
                    }
    
                    .titulo-resultados, .titulo-anexo {
                        margin-top: 0.75em;
                    }
                        
                    .solicitacao-wrapper, .resultados-wrapper, .estudos-wrapper {
                        display: flex;
                        flex-direction: row;

                        .buttons-wrapper {
                            flex-shrink: 0;
                            display: inline-block;
                            height: $downloadViewButton_height;
                            & :not(:first-child) {
                                margin-left: 0.4em;
                            }
                        }

                        .info-wrapper {
                            margin-left: 0.75em;
                        }
                    }

                    .download-error {
                        color: $main_warning-color;

                        &:not(:last-child) {
                            margin-bottom: 0.75em;
                        }
                    }

                    &:first-child {
                        margin-top: 0.33em;
                    }

                    &:not(:first-child) {
                        margin-top: 0.5em;
                    }
                }
            }
        }
    }

    & > .exames-viwer-wrapper {
        .titulo-modal {
            display: inline-block;
            padding-left: 1.5rem;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.4em;
        }

        .vw-modal-wrapper {
            .vw-modal-body { 
                .vw-modal-body-content {
                    & img {
                        margin-left: 3%;
                        height: auto !important;
                        width: 94%;
                    }
                }
            }
        }
        
        .result-pagespace {
            height: 25px;
            width: 100%;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .exames-wrapper {
        & > .content-wrapper {
            & > .content-box {
                & > .results-section {
                    @media screen and (max-width: 350px) {
                        font-size: 0.85em;
                    }
                }

                .exames-viwer-wrapper {
                    /* Visualizador */
                    @media screen and (max-width: 350px) {
                        .titulo-modal { font-size: 0.85em; }
                    }
            
                    @media screen and (min-width: 350px) and (max-width: 415px) {
                        .titulo-modal { font-size: 0.98em; }
                    }
        
                    @media screen and (min-width: 415px) and (max-width: 475px) {
                        .titulo-modal { font-size: 1.2em; }
                    }
                }
            }
        }
    }

    &.mobile {
        .exames-viwer-wrapper {
            .vw-modal-wrapper {
                .vw-modal-body {
                    @include scrollbar-hide();

                    .vw-modal-body-content {
                        & img {
                            margin-left: 1%;
                            width: 98%;
                        }
                    }
                }
            }
        }
    }
}
