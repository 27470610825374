/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'pesquisaExperiencia' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('pesquisa-experiencia') {
    & > .selecao-section, & > .pesquisa-section, & .information-section {
        margin-top: 1em;
        padding: 0;
        overflow: visible;

        .section-box {
            padding: 0;

            .section-title {
                background-color: $accordion_default-title-bgColor;
                color: $accordion_default-text-color;
                padding: 0.66em 1em;
                text-align: center;
                font-size: 1.1em;
                @include appFont-bold();
            }
        
            .section-content {
                padding: 1em 0;
            }

            .instruction {
                color: inherit;
                font-size: inherit;
                text-align: left;

                .instruction-message {
                    font-size: inherit;
                    color: inherit;
                }

                .instruction-required {
                    margin-top: 10px;
                    font-size: inherit;
                    color: $mainForm_field-required-mark-color;
                }
            }
        }
    }

    & > .selecao-section, & > .pesquisa-section {
        .section-box {
            .section-content {
                .instruction {
                    font-size: inherit;
                    text-align: left;
    
                    &:not(:first-child) {
                        margin-top: 1.3em;
                    }
    
                    .instruction-message {
                        font-size: inherit;
                        color: inherit;
                    }
    
                    .instruction-required {
                        margin-top: 0.66em;
                        font-size: inherit;
                        color: $mainForm_field-required-mark-color;
                    }
                }
        
                .footer {
                    margin: 0.66em 0px;
                    padding:  0.66em;
    
                    background-color: $appCard_forms-bgColor;
                    color: $mainForm_grid-group-base-text-color;
                    text-align: left;
                    font-size: inherit;
                    overflow: hidden;
            
                    .footer-content {
                        font-size: 0.9em;
    
                        .link {
                            display: inline-block;
                            white-space: nowrap;
                            text-decoration: underline;
                            color: $main_bgColor;
    
                            &:hover {
                                color: lighten($main_bgColor, 15%);
                            }
                        }
                    }
                }
    
                .form-wrapper {
                    background-color: transparent;
                    color: $appCard_forms-color;
                    margin: 1em -1em 0 -1em;
                    padding: 0 1em;

                    @include mc-form-principal();
    
                    .form-content {
                        color: inherit;

                        .field-error {
                            color: $main_warning-color;
                            margin-top: 0.5em;
                            padding: 0;
                        }
    
                        .group-card { /* Estilos e lay-out dos agrupamentos e grid */
                            margin: 0.6em 0;
                            padding:  0.6em 0;
        
                            background-color: $appCard_forms-bgColor;
                            color: inherit;
                            text-align: left;
                            overflow: visible;

                            &:last-child {
                                margin-bottom: 0;
                            }
                    
                            ._djfb_grid-group-title {
                                margin-bottom: 0.75em;
                                @include appFont-bold();
                            }
        
                            &.highlight-title {
                                padding-top: 0 !important;
    
                                & > ._djfb_grid-group-title {
                                    padding-top: 0.5em;
                                    padding-bottom: 0.5em;
                                    background-color: $mainForm_grid-group-highlight-title-bgColor;
                                    color: $mainForm_grid-group-highlight-title-color;
                                }
    
                                & > ._djfb_grid-group-description {
                                    @include appFont-bold();
                                    margin-bottom: 0.7em;
                                }
                            }

                            &:not(.highlight-title) > ._djfb_grid-group-title {
                                margin-top: 5px;
                                color: $mainForm_grid-group-base-text-color;
                            }
    
                            & > ._djfb_grid-group-title, & > ._djfb_grid-group-description, & > ._djfb_grid-group-content {
                                padding: 0 1em;
                            }
    
                            & > ._djfb_grid-group-description {
                                margin-bottom: 0.3em;
                                color: lighten($mainForm_grid-group-base-text-color, 15%);
                            }

                            .field-label {
                                font-weight: inherit;
                            }

                            .convenio-wrapper {
                                margin-top: 0.33em;
                            }

                            .radio-group-score {
                                text-align: left;
                    
                                .field-error {
                                    text-align: left;
                                }
                    
                                .score-grades, .radio-field {
                                    overflow: hidden;
                                }
                    
                                .score-grades {
                                    margin-top: 8px;
                                }
                    
                                .ui.radio {
                                    margin-top: 0 !important;
                                }
                    
                                .grades-wrapper {
                                    text-align: center;
                                    display: inline-block;
                                    min-width: 275px;
                                }
                    
                                .rb-score-wrapper {
                                    display: inline-block;
                                    width: $mainForm_field-radio-size;
                                    font-size: inherit;
                    
                                    &:not(:last-child) {
                                        margin-right: 4px;
                                    }
    
                                    &.disabled {
                                        color: $mainForm_field-disabled-color;
                                    }
                                }
                                
                                &.responsive {
                                    @media screen and (max-width: 390px) {
                                        $small-radio-size: 16px;
                                        @include mc-form-radio-field-resize($small-radio-size);
                    
                                        .grades-wrapper {
                                            min-width: 210px;
                                        }
                    
                                        .rb-score-wrapper {
                                            width: $small-radio-size;
                                            font-size: 0.9em;
                    
                                            &:not(:last-child) {
                                                margin-right: 3px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        /* Estilos dos campos/grupos */
                        .dd-pesquisa {
                            width: 310px;
                        }
    
                        .amb-avaliacao-servicos, .amb-avaliacao-estrutura, .amb-avaliacao-geral,
                        .exm-avaliacao-agendamento-recepcao, .exm-avaliacao-realizacao-entrega,
                        .int-avaliacao-servicos,
                        .indicacao-hospital {
                            &, &.highlight-title {
                                ._djfb_grid-group-description {
                                    @include appFont-default();
                                }
                            }
                        }
    
                        .group-date {
                            ._djfb_grid-row {
                                $date-field-width: 140px;
                                $date-picker-wrapper-width: calc(#{$formBuilder_date-picker-button-width} + #{$formBuilder_date-picker-button-margin-left});
                                $date-input-width: calc(#{$date-field-width} - #{$date-picker-wrapper-width});
                                ._djfb_grid-field-wrapper > ._djfb_grid-column {
                                    min-width: $date-field-width;
                                    width: $date-field-width;
                
                                    input {
                                        width: $date-input-width;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & > .information-section {
        text-align: center;

        .section-content {
            text-align: left;

            .sucesso, .warning {
                margin-bottom: 1em;
                font-size: 1.3em;
                @include appFont-bold();
            }

            .sucesso {
                color: $main_success-color;
            }

            .warning {
                color: $main_warning-color;
            }

            p {
                overflow: hidden;
            }

            span {
                display: inline-block;
                margin: 0;
                padding: 0;
                color: inherit;
                font-size: inherit;
            }
        }
    }

    & > .main-action {
        margin-top: 1em;
        padding-bottom: 1.5em;

        .btn-voltar {
            margin-top: 1em;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/

#root > .app-viewport {
    .pesquisa-experiencia-wrapper {
        & > .content-wrapper {
            & > .content-box {
                .selecao-section .section-box .section-content {
                    .form-wrapper .form-content{
                        .dd-pesquisa {
                            @media screen and (min-width: 351px) and (max-width: 380px) {
                                width: 275px;
                            }
                            @media screen and (max-width: 350px) {
                                width: 240px;
                            }
                        }
                    }
                }
            }
        }
    }
}
