/* ----------------------------------------------
 *  Estilos específicos de 'aceiteCookies'
 * ----------------------------------------------
**/
@import '../../../styles/common.scss';


#aceiteCookiesWrapperId {
    @include floating-container(fixed, 9900) {
        top: 0;
        left: 0px;
        height: 100%;
        width: 100%;

        background-color: $appModal_shadowfog-bgColor;
    }

    #aceiteCookiesContentId {

        .aceite-cookies-box {
            background: $aceiteCookies_bar-bgColor;
            position: fixed;
            z-index: 10000;
            display: flex;
            width: 100%;
            bottom: 0;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 600px) {
                flex-direction: column;
            
                .aceite-cookies-btn-wrapper {
                    padding-bottom: 1.2em;
                }
            }

            &.hide {
                display: none !important;
            }

            .message {
              text-align: center;
              padding: 1.2em;
              color: $main_color;
              font-size: 1rem;
            }

            .aceite-cookies-btn-wrapper {
                align-self: center;
                min-width: 100px;
                margin: 0 1em;
            }
        }

    }
}
