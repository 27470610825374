/*
** @name: Meu Clínicas - basicConstants
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
**
** @date: Janeiro 2022 - Refatoramento da sistematica de temas
** @description: Arquivo com todas constantes báscias de estilos usados na apicação. Pode ser 'override' pelas constants do tema.
** Agrupadas por uso geral, componentes, módulos ou serviços externos
*/

/* -----------------------------------
 * GENERAL
 * -----------------------------------
 */
 $main_bgColor: #006338; /* cor de fundo padrão da aplicação */
 $main_color: #ffffff; /* cor padrão do texto da aplicação */
 $main_warning-color: #ff2b2b; /* cor padrão para indicativo de erro */
 $main_success-color: #1eb400; /* cor padrão para indicativo de sucesso  */
 $main_emphasis-color: #6fd921; /* cor padrão para indicativo de destaque */
 $main_information-bgColor: #295dcd; /* cor padrão para fundo de caixa de informação */
 $main_selectionColor: #afafaf; /* cor padrão para seleção de texto */
 $main_placeholderColor: #909090; /* cor padrão para placeholder */
 $main_anchor-color: $main_color; /* cor padrão para 'anchor tag' */
 $main_anchor-hover-color: darken($main_anchor-color, 15%); /* cor padrão para 'hover' da 'anchor tag' */
 $main_shadowfog-bgColor: rgba(0, 0, 0, 0.5); /* cor de fundo da sombra/névoa principal da aplicação */
 $main_viewer-bgColor: #ccc; /* cor de fundo padrão para os visualizadores */
 $main_qrCode-wrapper-color: #fff; /* cor de fundo da 'caixa' ao redor do QRCode */
 $wifi_main-bgColor: #1cb7a4; /* cor de fundo padrão para layout wifi */
 $wifi_content-bgColor: $wifi_main-bgColor; /* cor de fundo do conteúdo para layout wifi */
 $wifi_content-color: $main_color; /* cor padrão do texto do conteúdo para layout wifi */
 
$app_minWidth: 310px; /* largura minima em caso de 'rescale' */
$app_base-fontSize: 15px; /* tamanho de fonte base da aplicação */
$app_default-form-field-height: 32px; /* altura padrão para campos de formulários */
$app_default-scrollbar-width: 8px; /* largura padrão da barra de rolagem na aplicação */
$app_default-auth-max-width: 510px; /* largura máxima do conteúdo te telas relacionadas à autenticação (login, ativacao e redefinicao senha) */
$app_default-card-module-content-max-width: 650px; /* largura máxima padrão para o conteúdo dos modulos em card */

$appContent_defaultVerticalPadding: 20px; /* 'padding' vertical para conteúdo da aplicação */
$appContent_horizontalPadding: 20px; /* 'padding' horizontal para conteúdo da aplicação */
$appContent_mobile-horizontalPadding: 15px; /* (Versão mobile) - 'padding' horizontal para conteúdo da aplicação */

$appHeader_verticalPadding: 20px; /* 'padding' vertical do cabeçalho da aplicação */
$appHeader_logoHeight: 40px; /* altura do logo no cabeçalho da aplicação */
$appHeader_fullHeight: calc(#{$appHeader_logoHeight} + (2 * #{$appHeader_verticalPadding})); /* CALCULADO: altura total do header */

$appMessage_box-padding: 10px; /* 'padding' das caixas de mensagem */
$appMessage_box-borderRadius: 10px; /* raio da borda das caixas de mensagem */
$appMessage_font-size: 1.0rem; /* fonte das caixas de mensagem */

/*
** Accordion **/
$accordion_emphasis-title-bgColor: $main_emphasis-color; /* cor de fundo de enfase/destaque para título do item do 'accordion' */
$accordion_emphasis-title-color: $main_bgColor; /* cor de texto de enfase/destaque para título do item do 'accordion' */
$accordion_default-title-bgColor: #295dcd; /* cor padrão para fundo para título do item do 'accordion' */
$accordion_default-content-bgColor: transparent; /* cor padrão para fundo para conteúdo do item do 'accordion' */
$accordion_default-text-color: $main_color; /* cor do texto padrão para o 'accordion' */
$accordion_default-toggle-color: $main_color; /* cor padrão do 'accordion toggle (+/-)' */

/*
** Botão */
$formButton_bgColor: #0a8c4c; /* cor de fundo padrão para 'mixin form button'  */
$formButton_color: #ffffff; /* cor de texto padrão para 'mixin form button'  */
$formButton_hover-bgColor: lighten($formButton_bgColor, 5%); /* cor de fundo padrão para 'on hover' do 'mixin form button' */
$formButton_hover-color: $formButton_color; /* cor de texto padrão para 'on hover' do 'mixin form button' */
$formButton_disable-bgColor: #9a9a9a; /* cor de fundo padrão para 'mixin form button' desabilitado */
$formButton_disable-color: $formButton_color; /* cor de texto padrão para 'mixin form button' desabilitado */
$formButton_active-border-shadow: inset 0px 0px 0px 1px #aaa; /* cor da sombra da borda indicativa de ativo/foco do 'mixin form button' */

$downloadViewButton_height: 30px; /* altura do botão de download (pdf) e de visualização */

/*
** Formularios/Questionarios */
$mainForm_base-font-size: 0.95rem; /* tamanho base para fontes dos formularios */
$mainForm_grid-group-base-text-color: #444; /* cor do texto base para os texto dos agrupamentos dos formulários */
$mainForm_grid-group-highlight-title-bgColor: $main_bgColor; /* cor de fundo de destaque do grupo de campos do formulario */
$mainForm_grid-group-highlight-title-color: #e9e9e9; /* cor de texto de destaque do grupo de campos do formulario */

$mainForm_field-disabled-color: #888;  /* cor padrão de campo desabilitado */
$mainForm_field-checkbox-size: 20px; /* tamanho padrão dos 'checkbox' */
$mainForm_field-radio-size: 21px; /* tamanho padrão dos 'radio button'  */
$mainForm_field-radio-toggle-slider-hover-color: #dfdfdf; /* cor da barra do 'radio' (toggle and slider) 'on hover' */
$mainForm_field-radio-toggle-slider-unchecked-color: rgba(0, 0, 0, 0.1); /* cor da barra do 'radio' (toggle and slider) não marcado */
$mainForm_field-vertical-margin: 0.9em; /* margin vertical padrão (antes e depois) dos campos */
$mainForm_field-required-mark-color: #ff2020; /* cot do indicativo de campo obrigatório */
$mainForm_field-border-width: 1px; /* largura da borda dos campos do formulário */
$mainForm_field-label-left-margin: 8px; /* margem esqueda para labels em controles checkbox/radio */

/*
** Other */
$roundCloseButton_cross-color: rgba(240, 240, 240, 0.8); /* mixin round-cross-btn 'cross' color */


/* -----------------------------------
 * COMPONENTS
 * -----------------------------------
 */
/*
** aceiteTermosSessao Component */
$aceiteCookies_bar-bgColor: rgba(0, 0, 0, 0.8); /* cor de fundo da barra de menssagem do 'aceiteCookies' */

/*
** aceiteTermosSessao Component */
$aceitaTermos_shadowfog-bgColor: rgba(0, 0, 0, 1); /* cor de fundo da sombra/névoa do 'aceiteTermosSessao' */

/*
** appBooking Component */
$appBooking_day-bgColor: #025e2f; /* cor de fundo da caixa do dia */
$appBooking_hour-bgColor: #012615; /* cor de fundo da caixa do horário */
$appBooking_selected-bgColor: $main_emphasis-color; /* cor de fundo para horário selecionado */
$appBooking_mark-teleatendimento-color: orangered; /* cor da marca indicativa de teleatendimento */
$appBooking_navbar-bgColor: $main_information-bgColor; /* cor da marca indicativa de teleatendimento */


/*
** appCard Component */
$appCard_radius: 20px; /* tamanho do raio para contorno nos cantos superiores do 'appCard' */
$appCard_top: $appHeader_fullHeight; /* CALCULADO: posição superior do 'card' aberto */
$appCard_height: calc((var(--vh, 1vh) * 100) - #{$appCard_top}); /* CALCULADO: altura total do 'card' aberto */
$appCard_action-bar-height: 40px; /* Altura da barra de ações do 'card' */
$appCard_action-bar-buttonSize: 20px; /* Tamanho dos botões de ação do 'card' */
$appCard_action-button-margin-bottom: 5px; /* Margen inferior dos botões da barra de ações */
$appCard_title-height: 35px; /* Altura da barra de titulo do 'card' */
$appCard_normal-bgColor: #00321c; /* cor de fundo para 'card' com 'schema' normal */
$appCard_normal-color: $main_color; /* cor de texto para 'card' com 'schema' normal */
$appCard_forms-bgColor: #ffffff; /* cor de fundo para 'card' com 'schema' de cadastro */
$appCard_forms-color: #006338; /* cor de texto para 'card' com 'schema' de cadastro */
$appCard_wifi-bgColor: $appCard_normal-bgColor; /* cor de fundo para 'card' com 'schema' wifi */
$appCard_wifi-color: $appCard_normal-color; /* cor de texto para 'card' com 'schema' wifi */

/*
** appConfirmationDialog */
$appConfirmationDlg_header-bgColor: $main_bgColor; /* cor de fundo do cabeçalho da janela de confirmacao */
$appConfirmationDlg_header-color: $main_color; /* cor do texto do cabeçalho da janela de confirmacao */
$appConfirmationDlg_bgColor: #fff; /* cor de fundo da janela de confirmacao */
$appConfirmationDlg_color: #222; /* cor do texto da janela de confirmacao */

/*
** appDateFilter */
$appDateFilter_bgColor: #81988e; /* cor de fundo da area do filtros */
$appDateFilter_input-bgColor: #fff; /* cor de fundo da area do filtros */
$appDateFilter_input-color: #333; /* cor do texto do input nos filtros */
$appDateFilter_border-color: #999; /* cor da borda do input nos filtros */

/*
** appErrorPage */
$appErrorPage_bgColor: #000; /* cor de fundo de página de erro da aplicação (cokies/ browser not supported) */
$appErrorPage_missingCfg-bgColor: $main_bgColor; /* cor de fundo de página de erro 'missing-configuration' */

/*
** appModal */
$appModal_bgColor: $main_bgColor; /* cor de fundo do conteúdo de 'appModal'*/
$appModal_shadowfog-bgColor: $main_shadowfog-bgColor; /* cor de fundo da sombra/névoa do 'appModal' */

/*
** appNavigationControls Component */
$navAccordionColapse_width: 20px; /* tamanho do 'AccordionCollapsible' */

$navButton_size: 70px; /* tamanho do botão 'navigationButton' na barra de navegação 'navigationBar' */
$navButton_small-size: 60px; /* tamanho reduzido para o botão 'navigationButton' na barra de navegação 'navigationBar' */
$navButton_small-size-maxWidth: 375px; /* largura máxima para usar botão na versão reduzida */
$navButton_radius: 8px; /* tamanho do raio para contorno nos cantos do botão 'navigationButton' */
$navButton_default-bgColor: $appCard_normal-bgColor; /* cor de fundo do botão 'navigationButton' na barra de navegação 'navigationBar' */
$navButton_default-disabled-bgColor: #32443c; /* cor de fundo do botão 'navigationButton' desabilitado na da barra de navegação 'navigationBar' */
$navButton_cardStyle-bgColor: $appCard_forms-color; /* cor de fundo do botão 'navigationButton' para cards */
$navButton_cardStyle-disabled-bgColor: #48685a; /* /* cor de fundo do botão 'navigationButton' desabilitado para cards */

$navBar_scrollButton-bgColor: #ffffff; /* cor de fundo do botão de 'scroll' da barra de navegação 'navigationBar' */
$navBar_scrollButton-disabled-bgColor: #afafaf;  /* cor de fundo do botão de 'scroll' desabilitado da barra de navegação 'navigationBar' */
$navBar_scrollButton-width: 25px; /* largura do botão de 'scroll' da barra de navegação 'navigationBar' */
$navBar_paddingTop: 10px; /* 'padding' superior da barra de navegação 'navigationBar' */
$navBar_paddingBottom: 20px; /* 'padding' inferior da barra de navegação 'navigationBar' */
$navBar_marginBottom: 0px; /* margin inferior da barra de navegação 'navigationBar' */
$navBar_button-spacing: 5px; /* espaçamento dos botões da barra de navegação 'navigationBar' */
$navBar_horizontalPadding: $appContent_horizontalPadding; /* CALCULADO: 'padding' horizontal da barra de navegação 'navigationBar' */
$navBar_mobile-horizontalPadding: $appContent_mobile-horizontalPadding; /* CALCULADO: (Versão mobile) - 'padding' horizontal da barra de navegação 'navigationBar' */
$navBar_cover-height: calc(#{$navButton_size} + #{$navBar_paddingTop} + #{$navBar_paddingBottom} + #{$navBar_marginBottom}); /* CALCULADO: altura total de cobertura da barra de navegação 'navigationBar' */
$navBar_cover-small-height: calc(#{$navButton_small-size} + #{$navBar_paddingTop} + #{$navBar_paddingBottom} + #{$navBar_marginBottom}); /* CALCULADO: altura total de cobertura da barra de navegação 'navigationBar' com botão pequeno */

/*
** FormBuilder component */
$formBuilder_default-form-input-height: $app_default-form-field-height; /* altura padrão do elemento 'input' */
$formBuilder_date-picker-button-height: 26px; /* altura do botão do 'date picker' */
$formBuilder_date-picker-button-width: 26px; /* largura do botão do 'date picker' */
$formBuilder_date-picker-button-margin-left: 4px; /* margem esquerda do botão do 'date picker' */
$formBuilder_date-picker-triangle-color: #fff; /* cor do triângulo de ligacao do 'popup' do 'datepicker' */
$formBuilder_date-picker-triangle-border-color: #555; /* cor da borda do triângulo de ligacao do 'popup' do 'datepicker' */
$formBuilder_date-picker-trigger-button-bgColor: #fff; /* cor de fundo do botão para chamar o 'datepicker' */
$formBuilder_date-picker-trigger-button-border-color: #aaa; /* cor da borda do botão para chamar o 'datepicker' */
$formBuilder_date-picker-trigger-button-hover-bgColor: #ddd; /* cor de fundo quando 'on hover' do botão para chamar o 'datepicker' */
$formBuilder_date-picker-trigger-button-border-radius: 3px; /* raio da borda do botão para chamar o 'date picker' */

/*
** password Component */
$password_strength-info-meter-weak: red; /* cor da barra de indicação de senha fraca */
$password_strength-info-meter-medium: yellow; /* cor da barra de indicação de senha média */
$password_strength-info-meter-strong: $main_success-color; /* cor da barra de indicação de senha forte */
$password_strength-info-content-darken-bgColor: #d0d0d0; /* cor de fundo no quadro informativo da força da senha em versão mais escura para formularios brancos */
$password_strength-info-content-bgColor: #fff; /* cor de fundo no quadro informativo da força da senha */
$password_strength-info-content-color: #222; /* cor do texto no quadro informativo da força da senha */
$password_strength-info-error-color: $main_warning-color; /* cor do texto quando error na validação no quadro informativo da força da senha */
$password_strength-info-sucess-color: green; /* cor do texto quando sucesso na validação no quadro informativo da força da senha */

/*
** toggleButton Component */
$toggleButton_border-color: #b0b0b0; /* cor da borda do 'toggleButton' */
$toggleButton_unchecked-bgColor: #fff; /* cor de fundo do 'toggleButton' não ligado */
$toggleButton_checked-bgColor: #8ce196; /* cor de fundo do 'toggleButton' ligado */
$toggleButton_switch-bgColor: #ddd; /* cor de fundo do 'switch' do 'toggleButton' */

/*
** viewerModal */
$viewerModal_default-header-height: 52px; /* altura padrão do cabecalho */
$viewerModal_default-footer-height: 0px; /* altura padrão do rodapé */

$viewerModal_sbtrack: rgb(190, 190, 190); /* viewer moral scrollbar track color */
$viewerModal_sbtrack-shadow: rgb(150, 150, 150); /* viewer moral scrollbar track shadow color */
$viewerModal_sbthumb: #96a59e; /* viewer moral scrollbar thumb color */
$viewerModal_sbthumb-hover: darken($viewerModal_sbthumb, 15%); /* viewer moral scrollbar thumb on hover color */


/*
** AghuseToast */
$aghuseToast_headerSectionBgColor_info: #0065a8;
$aghuseToast_headerTextColor_info: #ffffff;
$aghuseToast_contentBgColor_info: #b7def8;
$aghuseToast_severityIconPosition_info: 2px 245px;
$aghuseToast_severityIconSize_info: 34px;

$aghuseToast_headerSectionBgColor_warn: #817702;
$aghuseToast_headerTextColor_warn: #ffffff;
$aghuseToast_contentBgColor_warn: #faf5bc;
$aghuseToast_severityIconPosition_warn: 0px 113px;
$aghuseToast_severityIconSize_warn: 30px;

$aghuseToast_headerSectionBgColor_error: #920000;
$aghuseToast_headerTextColor_error: #ffffff;
$aghuseToast_contentBgColor_error: #f1bbbb;
$aghuseToast_severityIconPosition_error: 0px 0px;
$aghuseToast_severityIconSize_error: 30px;


/* -----------------------------------
 * MODULES
 * -----------------------------------
 */

/*
** allServices Module */
$allSvr_buttonSize: 70px; /* tamanho do botão 'navigationButton' em 'allServices' */
$allSvr_button-spacing: 12px; /* espaçamento dos botões  em 'allServices' */
$allSvr_large-buttonSize: 80px; /* tamanho grande do botão 'navigationButton' em 'allServices' */
$allSvr_minWidth-buttonChange: 410px; /* largura minima para aumentar tamanho dos botões em 'allServices' */

/*
** cartaoPaciente Module */
$cartaoPaciente_text-color: $main_emphasis-color; /* cor de texto do cartão do paciente */

/*
** consultas Module */
$consultas_card-info-icon-width: 30px; /* largura do icone de informação do cartão de consultas */
$consultas_card-title-bgColor: $main_information-bgColor; /* cor de fundo do titulo do cartão de consulta */
$consultas_card-title-color: $main_color; /* cor do texto do titulo do cartão de consulta */
$consultas_card-content-bgColor: #809de1; /* cor de fundo do conteudo do cartão de consulta */
$consultas_card-content-color: $appCard_normal-bgColor; /* cor do texto do conteudo do cartão de consulta */
$consultas_card-teleatendimento-highlight-color: #A3393A; /* cor de realçe do indicativo de teleconsulta */

/*
** regMedicos Module */
$laudos-atestados_card-info-icon-width: 30px; /* largura do icone de informação da listagem de laudos e atestados */
$laudos_atestados_card-content-bgColor: #809de1; /* cor de fundo do conteudo do cartão de consulta */
$laudos_atestados_card-content-color: $appCard_normal-bgColor; /* cor do texto do conteudo do cartão de consulta */

/*
** faq Module */
$faq_tags-item-bgColor: $accordion_default-title-bgColor; /* cor de fundo do item da tag no módulo 'faq' */
$faq_tags-round-add-btn-cross-color: #fff; /* cor de fundo do sinal de adicionar no filtro do módulo 'faq' */
$faq_tags-minus-bgColor: #ddd; /* cor de fundo do sinal de menos do iten do filtro do módulo 'faq' */
$faq_tags-minus-color: #222; /* cor do sinal de menos do iten do filtro do módulo 'faq' */
$faq_footer_bgColor: #fff; /* cor de fundo do rodapé do módulo 'faq' */
$faq_footer_color: #3261a9; /* cor de fundo do rodapé do módulo 'faq' */

/*
** multiFileSelect Module */
$multiFileSelect_fileButton-height: 60px; /* altura do botão 'fileButton' em 'multiFileSelect' */
$multiFileSelect_fileButton-width: 40px; /* largura do botão 'fileButton' em 'multiFileSelect' */
$multiFileSelect_fileButton-spacing: 0.75em; /* espaçamento dos botões em 'multiFileSelect' */

/*
** teleatendimento Module */
$teleatendimento_instrucoes-max-width: 400px; /* largura máxima da midia (carrossel) na página de instrucoes*/
$teleatendimento_informacoes-max-width: 450px; /* largura máxima da midia (carrossel) na sala de espera */
$teleatendimento_meeting-wrapper-height: calc(#{$appCard_height} - #{$appCard_action-bar-height} - #{$appCard_title-height});
$teleatendimento_meeting-wrapper-min-height: 500px; /* altura mínima para sala de espera */
$teleatendimento_feedbackButton-selected-shadow: 0 0 8px rgba(108, 178, 41, 1.0), 0 1px 0 rgb(255, 255, 255, 0.4) inset !important; /* sombra para botão de 'feedback' selecionado */
$teleatendimento_connection-indicator-desktop-size: 18px; /* tamanho (altura e largura) do indicador de conexão em desktop */
$teleatendimento_connection-indicator-mobile-size: 14px; /* tamanho (altura e largura) do indicador de conexão em mobile */
$teleatendimento_connection-indicator-connectedColor: $main_success-color; /* cor do indicador quando conectado */
$teleatendimento_connection-indicator-disconnectedColor: rgb(228, 96, 8); /* cor do indicador quando desconectado */

$teleatendimento_custom-toolbar-bgColor: rgb(20, 20, 20);
$teleatendimento_custom-toolbar-button-hover-color: #515151;

/*
** userHome Module */
$userHome_notice-color: #00321C; /* cor do texto do aviso na 'home' do usuário */
$userHome_notice-bgColor: #F6AF2D; /* cor de fundo do aviso na 'home' do usuário */


/* ====================================================================
 * EXTERNAL SERVICES MODULES
 * ====================================================================
 */

/* 
** Geral para Modulos Administrativos e de Serviços */
$servicos_default-base-font-size: 0.95rem; /* tamanho base padrão para fonte dos serviços externos */

$servicos_default-logo-width: 100px; /* largura padrao do logo no cabeçalho dos serviços externos */
$servicos_default-bgColor: #d8d8d8; /* cor de fundo padrão para seriços externos */
$servicos_default-color: #333; /* cor padrão para seriços externos */
$servicos_default-header-bgColor: #006338; /* cor de fundo padrão do cabeçalho dos serviços externos */
$servicos_default-header-color: #fff; /* cor do texto padrão do cabeçalho dos serviços externos */
$servicos_default-card-shadow-color: rgb(150, 150, 150); /* cor da sobra padrão para os 'cards' dos serviços externos  */
$servicos_default-card-bgColor: #e9e9e9; /* cor de fundo padrão para os 'cards' dos serviços externos  */
$servicos_default-information-color: $main_information-bgColor; /* cor de informacao/destaque nos serviços externos */
$servicos_default-item-changed-color: #baccf5; /* cor de fundo para informacao sobre item editado em grid/lista nos serviços externos */
$servicos_default-item-error-color: #ffb9b9; /* cor de fundo para indicar error em item de grid/lista nos serviços externos */

$servicos_session-lifetime-bgColor: rgb(248, 248, 123); /* cor de fundo da barra de informação da duração da sessão */
$servicos_session-lifetime-color: $servicos_default-color; /* cor do texto barra de informação da duração da sessão */

$servicos_button-bgColor: $servicos_default-header-bgColor; /* cor de fundo dos botões serviços  */
$servicos_button-hover-bgColor: lighten($servicos_default-header-bgColor, 5%); /* cor de fundo 'on hover' dos botões serviços  */
$servicos_button-active-border-shadow: inset 0px 0px 0px 1px black; /* cor da sombra da borda indicativa de ativo/foco dos botões serviços */
$servicos_button-secundary-bgColor: $servicos_default-information-color; /* cor secundária de fundo dos botões serviços */
$servicos_button-secundary-hover-bgColor: lighten($servicos_default-information-color, 5%); /* cor secundária de fundo 'on hover' dos botões serviços  */

$servicos_form-field-bgColor: $servicos_default-card-bgColor; /* cor de fundo dos campos de formulários */
$servicos_form-field-text-color: #555; /* cor do texto dos campos de formulários */
$servicos_form-field-disabled-color: #888;  /* cor de campo desabilitado */
$servicos_form-field-error-message-color: $main_warning-color; /* cor de mensgens de erro de campo */
$servicos_form-field-success-message-color: #6bbc6c; /* cor de mensgens de sucesso de campo */
$servicos_form-field-border-color: #aaa; /* cor da borda de campos de formulários */
$servicos_form-field-border-hover-color: rgba(100, 116, 50, 0.6); /* cor da borda quando 'on hover' em campos de formulários */
$servicos_form-field-placeholder-color: #777; /* cor do texto do placeholder de campos de formulários */
$servicos_form-field-checkbox-color: #555; /* cor do indicativo de ligado do 'checkbox' no formulário */
$servicos_form-field-checkbox-label-color: $servicos_form-field-text-color; /* cor do texto do 'checkbox' no formulário */
$servicos_form-field-checkbox-size: 20px; /* tamanho do 'checkbox' no formulário */
$servicos_form-field-radio-size: 21px; /* tamanho do 'radio' no formulário */
$servicos_form-field-radio-bullet-color: #555; /* cor da bola indicativa de seleção do 'radio' no formulário */
$servicos_form-field-radio-label-color: $servicos_form-field-text-color; /* cor do texto do 'radio' no formulário */
$servicos_form-field-radio-toggle-slider-color: #1b2f1b; /* cor do 'toggle and slider' nos 'radio' do formulário */
$servicos_form-field-dropdown-height: $app_default-form-field-height; /* altura do 'dropdown' no formulário */
$servicos_form-field-input-height: $app_default-form-field-height; /* altura do 'input' no formulário */
$servicos_form-field-vertical-margin: 8px; /* margem vertical (antes e depois) dos campos do formulário */
$servicos_form-field-required-mark-color: $main_warning-color; /* cor do indicativo de campo exigido '*' */
$servicos_form-info-button-bgColor: $servicos_default-information-color; /* cor de fundo do botão de informações de formularios */
$servicos_form-info-button-color: #f0f0f0; /* cor do texto do botão de informações de formularios */

$servicos_form-toggle-size: 42px; /* tamanho do componente 'toggle' (não é o 'radio button' na configuração toggle ou slider) */
$servicos_form-toggle-bullet-margin: 2px; /* margem da bolinha do 'toggle' (não é o 'radio button' na configuração toggle ou slider)  */
$servicos_form-toggle-border-color: darken(#ffffff, 30%); /* cor da borda do componente 'toggle' */
$servicos_form-toggle-bullet-color: darken(#ffffff, 12%); /* cor da bola do componente 'toggle' */
$servicos_form-toggle-active-color: $servicos_default-header-bgColor; /* cor do componente 'toggle' quando ativo */
$servicos_form-toggle-inactive-color: darken(#ffffff, 8%); /* cor do componente 'toggle' quando não ativo */

$servicos_sbtrack: rgb(190, 190, 190); /* viewer moral scrollbar track color */
$servicos_sbtrack-shadow: rgb(150, 150, 150); /* viewer moral scrollbar track shadow color */
$servicos_sbthumb: #696969;  /* viewer moral scrollbar thumb color */
$servicos_sbthumb-hover: #555555; /* viewer moral scrollbar thumb on hover color */


/*
** Confirmacao Externa Dados module */
$confExtDados_bgColor: transparent; /* cor de fundo do módulo de confirmacao externa de dados */
$confExtDados_color: #000; /* cor de texto do módulo de confirmacao externa de dados */

/*
** Dashboard module */
$dashboard_accordion-content-bgColor: #749870; /* cor de fundo do conteúdo do 'accordion' no módulo 'dashboard' */ 

/*
** LaudosAtestadosView module */
$laudosAtestadosView_bgColor: #fff; /* cor de fundo color */
$laudosAtestadosView_color: #000; /* cor de texto color */

/*
** ReceitaView module */
$receitaView_bgColor: #fff; /* cor de fundo color */
$receitaView_color: #000; /* cor de texto color */

$receitaView_grd-disp-header-bgcolor: #29473d; /* 'grid dispensação' header backgroud color*/
$receitaView_grd-disp-header-color: #ffffff; /* 'grid dispensação' grid header text color*/

$receitaView_tootip-bgcolor: #647432; /* tooltip background color */
$receitaView_tooltip-border-color: #b9c78c; /* tooltip border color */
$receitaView_tootip-color: #fff; /* tooltip text color */
$receitaView_tootip-anchor-color: #b9c78c; /* tooltip anchor text color */
$receitaView_tootip-anchor-hover-color: #9aa577; /* tooltip anchor on hover text color */

/*
** validadorReceita module */
$validadorDoc_main-bgColor: #d8d8d8; /* main background color */
$validadorDoc_main-color: #555; /* main text color */

$validadorDoc_modal-shadowfog-color: rgba(100, 100, 100, 0.4); /* modal window shadow fog color */
$validadorDoc_form-default-color: $validadorDoc_main-color; /* form default text color */
