/* Import common definition styles */
@import '../../../styles/common.scss';


@mixin strength-info-bar($weak-color) {
    .strength-text {
        color: $weak-color;
    }

    .strength-meter .strength-bar {
        background-color: $weak-color;
    }
}

.__strength-info-wrapper__ {
    background-color: transparent;
    font-size: 0.8em;
    margin: 0.66em 0;

    & > .full-strength-info {
        margin: 0;
        padding: 0;       

        i {
            color: unset !important;
        }
    
        .strength {
            display: inline-block;
            padding: 0;
            background-color: transparent;
          
            &.weak {
                @include strength-info-bar($password_strength-info-meter-weak);
            }

            &.medium {
                @include strength-info-bar($password_strength-info-meter-medium);
            }

            &.strong {
                @include strength-info-bar($password_strength-info-meter-strong);
            }

            & > .strength-meter {
                display: inline-block;
    
                .strength-bar {
                    display: inline-block;
                    width: 14px;
                    height: 10px;
                    padding: 0;
                    opacity: 0.3;
        
                    &:not(:first-child) {
                        margin-left: 3px;
                    }
    
                    &.filled {
                        opacity: 1.0;
                    }
                }
            }

            & > .subtitle-control {
                &::before {
                    color: $main_color;
                    vertical-align: auto;
                }
            }

            & > .strength-text {
                padding-left: 0.5rem;
                padding-right: 0.25rem;
                font-weight: 700;
            }
        }

        & > .content {
            max-height: 0 ;
            overflow: hidden;
            padding: 0 !important;
            display: block !important;

            transition: all 800ms cubic-bezier(0.17, 0.04, 0.03, 0.94);          

            &.active {
                max-height: 400px !important;
            }

            & > .strength-info-box {
                padding: 0.7rem;
                opacity: 0.9;
                background-color: $password_strength-info-content-bgColor;
            
                .rules-title {
                    display: block;
                    padding: 0 0 0 0;
                    color: $password_strength-info-content-color;  
                }
            
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 10px 0 0 0;       
            
                    li {
                        padding-left: 10px;
                        i {
                            color: $password_strength-info-content-color !important;
                        }
            
                        i.icon.circle.outline {
                            &.check::before {
                                color: $password_strength-info-sucess-color;
                            }
                            &:not(.check)::before {
                                color: $password_strength-info-error-color;
                            }
                        }
            
                        &.matched, &.matched i {
                            color: $password_strength-info-sucess-color !important;
                        }
                        &.not-matched, &.not-matched i {
                            color: $password_strength-info-error-color !important;
                        }
            
                        &:not(:first-child) {
                            margin-top: 5px;
                        }
            
                        .match-result, .policy-title {
                            color: inherit;
                        }
            
                        &.not-required {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.boxed {
        background-color: $appCard_forms-color;
        padding: 0.8em;
        & > .full-strength-info > .title {
            padding-top: 0 !important;
        }
    }

    &.darker-info {
        & > .full-strength-info {
            .strength {
                & > .subtitle-control::before {
                    color: $password_strength-info-content-color;
                }

                &.medium {
                    @include strength-info-bar(darken($password_strength-info-meter-medium, 15%));
                }
            }

            & > .content > .strength-info-box {
                background-color: $password_strength-info-content-darken-bgColor;
            }
        }
    }
}
