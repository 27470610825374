/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'requerimentoDocumentosProntuario' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('requerimento-documentos-prontuario') {
    & > .user-identification-section, & > .main-section, & .information-section {
        margin-top: 1em;
        padding: 0;
        overflow: visible;

        .section-box {
            padding: 0;

            .section-title {
                background-color: $accordion_default-title-bgColor;
                color: $accordion_default-text-color;
                padding: 0.66em 1em;
                text-align: center;
                font-size: 1.1em;
                @include appFont-bold();
            }
        
            .section-content {
                padding: 1em 0;
            }

            .instruction {
                color: inherit;
                font-size: inherit;
                text-align: left;

                .instruction-message {
                    font-size: inherit;
                    color: inherit;
                }

                .instruction-required {
                    margin-top: 10px;
                    font-size: inherit;
                    color: $mainForm_field-required-mark-color;
                }
            }
        }
    }

    & > .user-identification-section {
        .section-box {
            .section-content {
                text-align: center;

                .titulo {
                    @include appFont-bold();
                    font-size: 1.05em;
                }

                & > div:not(:first-child) {
                    margin-top: 1em;
                }
            }
        }
    }

    & > .main-section {
        .section-box {
            .section-content {
                .form-wrapper {
                    background-color: transparent;
                    color: $appCard_forms-color;
                    margin: 1em -1em 0 -1em;
                    padding: 0 1em;

                    @include mc-form-principal();

                    .form-content {
                        color: inherit;
    
                        .field-error {
                            color: $main_warning-color;
                            margin-top: 0.5em;
                            padding: 0;
                        }
    
                        .group-card { /* Estilos e lay-out dos agrupamentos e grid */
                            margin: 0.6em 0;
                            padding:  0.6em 0;
        
                            background-color: $appCard_forms-bgColor;
                            color: inherit;
                            text-align: left;
                            overflow: visible;

                            &:last-child {
                                margin-bottom: 0;
                            }
                    
                            ._djfb_grid-group-title {
                                margin-bottom: 0.75em;
                                @include appFont-bold();
                            }
        
                            &.highlight-title {
                                padding-top: 0 !important;
    
                                & > ._djfb_grid-group-title {
                                    padding-top: 0.5em;
                                    padding-bottom: 0.5em;
                                    background-color: $mainForm_grid-group-highlight-title-bgColor;
                                    color: $mainForm_grid-group-highlight-title-color;
                                }
    
                                & > ._djfb_grid-group-description {
                                    @include appFont-bold();
                                    margin-bottom: 0.7em;
                                }
                            }

                            &:not(.highlight-title) > ._djfb_grid-group-title {
                                margin-top: 5px;
                                color: $mainForm_grid-group-base-text-color;
                            }
    
                            & > ._djfb_grid-group-title, & > ._djfb_grid-group-description, & > ._djfb_grid-group-content {
                                padding: 0 1em;
                            }
    
                            & > ._djfb_grid-group-description {
                                margin-bottom: 0.3em;
                                color: lighten($mainForm_grid-group-base-text-color, 15%);
                            }
                        }

                        /* Estilos dos campos/grupos */
                        .subgroup-periodo, .subgroup-outros {
                            margin-left: 28px;
                            margin-bottom: 10px;
                        }
    
                        .subgroup-periodo {
                            ._djfb_grid-group-title {
                                margin-bottom: 0;
                                color: lighten($mainForm_grid-group-base-text-color, 5%);
                                font-style: italic;
                                font-size: 0.95em;
                            }
    
                            ._djfb_grid-row.splited {
                                justify-content: start;
    
                                $periodo-field-width: 140px;
                                $periodo-field-date-picker-wrapper-width: calc(#{$formBuilder_date-picker-button-width} + #{$formBuilder_date-picker-button-margin-left});
                                $periodo-field-input-width: calc(#{$periodo-field-width} - #{$periodo-field-date-picker-wrapper-width});
                                .periodo-de, .periodo-ate {
                                    margin-left: 0.75em;
                                    min-width: $periodo-field-width;
                                    width: $periodo-field-width;
    
                                    input {
                                        width: $periodo-field-input-width;
                                    }
                                }
                            }
                        }
                    }            
                }
            }
        }
    }


    & > .information-section {
        text-align: center;

        .section-content {
            text-align: left;

            .sucesso, .warning {
                margin-bottom: 1em;
                font-size: 1.3em;
                @include appFont-bold();
            }

            .sucesso {
                color: $main_success-color;
            }

            .warning {
                color: $main_warning-color;
            }

            p {
                overflow: hidden;
            }

            span {
                display: inline-block;
                margin: 0;
                padding: 0;
                color: inherit;
                font-size: inherit;
            }
        }
    }

    & > .main-action {
        margin-top: 1em;
        padding-bottom: 1.5em;
    }

    & > .confirmacao-envio-wrapper {
        .confirmation-box {
            max-width: 80%;
            min-width: 300px;
        }

        .confirm-subtitle {
            color: $main_warning-color;
            margin-bottom: 15px;
            font-size: 1.1em;
            @include appFont-bold();
        }

        .confirm-message {
            overflow: hidden;
        }

        .phone-number {
            display: inline-block;
            white-space: nowrap;
            text-decoration: underline;
            font-weight: 700;
            color: $main_bgColor;

            &:hover {
                color: lighten($main_bgColor, 15%);
            }
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
