/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'faqManager' module specific styles
 * ---------------------------------------------------------
**/

@include es-default-service-login-template(".faq-manager-wrapper");

#root > .app-viewport.external-service {
    .faq-manager-wrapper {
        & > .content {
            .header-card .header-wrapper > .header-content{
                h1 {
                    font-size: 2.75em;
                }
            }
        }
    }
}