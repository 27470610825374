/* -----------------------------------------------
 * APPLICATION - MIXIN GENERAL LIBRARY
 * -----------------------------------------------
 */

/*
** Fonts */
@mixin appFont-default() {
    font-family: Montserrat-Medium, Verdana, Arial, sans-serif;
}

@mixin appFont-bold() {
    font-family: Montserrat-Bold, Verdana, Arial, sans-serif;
    font-weight: bold;
}

@mixin appFont-black() {
    font-family: Montserrat-Black, Verdana, Arial, sans-serif;
    font-weight: bolder;
}


/*
 * DEVICES DEFINITION
 */
$tablet-width: 768px;
$desktop-width: 1024px;
@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}
 
/*
** Controls */
@mixin floating-container($position: fixed, $z-index: 9000) {
    background-color: transparent;
    position: $position;
    z-index: $z-index;

    @content;
}

@mixin select-enabled() {
    -webkit-touch-callout: unset; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: unset; /* Konqueror HTML */
    -moz-user-select: text; /* Old versions of Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, supported by Chrome, Edge, Opera and Firefox */
}

@mixin allow-select() {
    -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
    -khtml-user-select: text; /* Konqueror HTML */
    -moz-user-select: text; /* Old versions of Firefox */
    -ms-user-select: text; /* Internet Explorer/Edge */
    user-select: text; /* Non-prefixed version, supported by Chrome, Edge, Opera and Firefox */
}

@mixin no-select() {
    & {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, supported by Chrome, Edge, Opera and Firefox */
    }
}

@mixin scrollbar-hide() {
    &::-webkit-scrollbar { display: none; }
    & {
        scrollbar-width: none;  /* Firefox - SonarQube >= v8.8 should not raise error anymore */
        -ms-overflow-style: none;  /* IE and Edge */
    }
}

@mixin scrollbar-style() {
    scrollbar-width: thin;  /* Firefox - SonarQube >= v8.8 should not raise error anymore */

    &::-webkit-scrollbar {
        width: $app_default-scrollbar-width;
    }
}

@mixin scrollbar-custom-style($width: $app_default-scrollbar-width, 
            $sbtrack: #787878, $sbtrack-shadow: #303030, 
            $sbthumb: #ff0000, $sbthumb-hover: #b30000) {
    &::-webkit-scrollbar {
        width: $width;
    }
    
    &::-webkit-scrollbar-track {
        background-color: $sbtrack;
        box-shadow: inset 0 0 4px $sbtrack-shadow; 
        border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: $sbthumb; 
        border-radius: 3px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background-color: $sbthumb-hover; 
    }
}

/*
** Elements */
@mixin card-module-basic-wrapper-block($module-name) {
    #root > .app-viewport {
        .#{$module-name}-wrapper {
            background-color: transparent;
            overflow-x: hidden;
    
            & > .content-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
    
                & > .content-box {
                    width: 100%;
                    max-width: $app_default-card-module-content-max-width;
                    & {
                        @content
                    }
                }
            }
        }
    }
}

@mixin message-box($bgColor, $color: $main_color) {
    background-color: $bgColor;
    color: $color;
    padding: $appMessage_box-padding;
    border-radius: $appMessage_box-borderRadius;
    font-size: $appMessage_font-size;
    text-align: center;

    & > .caption {
        margin-bottom: 10px;
        @include appFont-bold();
    }

    & > div:not(.caption) {
        @include appFont-default();
    }

    & > .caption, & > .message {
        background-color: inherit;
        color: inherit;
        font-size: inherit;
    }

    & {
        @content;
    }
}

@mixin round-cross-btn($z-index: inherit, $position: fixed, $cross-color: $roundCloseButton_cross-color, $wrapper-name: "div.btn-close-wrapper", $btn-name: ".close", $bg-color: rgba(0, 0 , 0, 0.3), $hover-bg-color: rgba(0, 0, 0, 0.5)) {
    #{$wrapper-name} {
        z-index: $z-index;
        display: block;
        position: $position;
        top: 10px;
        right: 10px;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background-color: $bg-color;
        cursor: pointer;

        #{$btn-name} {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 13px;
            height: 13px;
            opacity: 1;

            &::before, &::after {
                position: absolute;
                top: 5px;
                left: 10px;
                height: 13px;
                width: 4px;
                background-color: $cross-color;
                content: '';
            }
    
            &::before {
                transform: rotate(45deg);
            }
    
            &::after {
                transform: rotate(-45deg);
            }
        }

        &:hover, &:focus {
            background-color: $hover-bg-color;
        }

        & {
            @content;
        }
    }
}

@mixin toggle-change-size($toggle_size, $bullet-margin) {
    $toggle_height: calc(#{$toggle_size} / 2);
    .switch__container {
        width: $toggle_size;
        label {
            height: $toggle_height;
            width: $toggle_size;

            $toggle_bullet-size: calc(#{$toggle_height} - 2px - calc(2 * #{$bullet-margin}));
            &::after {
                margin-top: $bullet-margin;
                margin-left: $bullet-margin;
                height: $toggle_bullet-size;
                width: $toggle_bullet-size;
            }
        }

        .switch--shadow:checked + label::after {
            transform: translateX($toggle_height);
        }

        @content;
    }
}

@mixin watermark-receita-dispensada($selector) {
    @media screen and (max-width: 500px) {
        @media screen and (max-height: 550px) {
            #{$selector} {
                font-size: 2.7em !important;
            }
        }
        @media screen and (min-height: 550px) {
            #{$selector} { 
                font-size: 3.1em !important;
            }
        }
        #{$selector} { transform: rotate(-60deg) !important; }
    }

    @media screen and (min-width: 500px) and (max-width: 768px) {
        #{$selector} {
            font-size: 3.8em !important;
            transform: rotate(-50deg) !important;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        #{$selector} { 
            font-size: 4.7em !important; 
            transform: rotate(-34deg) !important;
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
        #{$selector} {
            font-size: 5em !important;
            transform: rotate(-28deg) !important;
        }
    }

    @media screen and (min-width: 1200px) {
        #{$selector} {
            font-size: 6em !important;
            transform: rotate(-25deg) !important;
        }
    }
}

/* ---------------------------------
** External Services - Specific 
** --------------------------------- */
// cards
@mixin es-card-envelope($margin: 15px 0px, $padding: 15px, $border-radius: 4px) {
    margin: $margin;
    padding:  $padding;
    border-radius: $border-radius;

    @include es-card-shadow();

    @content;
}

@mixin es-card-shadow($offset-x: 3px, $offset-y: 3px, $blur-radius: 6px, $spread-radius: 0px, $color: $servicos_default-card-shadow-color) {
    -webkit-box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
    -moz-box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
    box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
}

// scrollbar
@mixin es-scrollbar-style() {
    @include scrollbar-custom-style(10px,
        $servicos_sbtrack, $servicos_sbtrack-shadow,
        $servicos_sbthumb, $servicos_sbthumb-hover);
}

// shadows
@mixin es-shadow-narrow($is_imp: false) {
    @if $is_imp == false {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(0, 0, 0, 0.6);
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(0, 0, 0, 0.6);
    } @else {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(0, 0, 0, 0.6) !important;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 2px rgba(0, 0, 0, 0.6) !important;
    }
}

@mixin es-shadow-soft($is_imp: false) {
    @if $is_imp == false {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.4);
    } @else {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.4) !important;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.4) !important;
    }
}

@mixin es-shadow-thin($is_imp: false) {
    @if $is_imp == false {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 6px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 6px rgba(0, 0, 0, 0.4);
    } @else {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 6px rgba(0, 0, 0, 0.4) !important;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 6px rgba(0, 0, 0, 0.4) !important;
    }
}

// contents
@mixin es-default-header-content($header-height: auto, $logo-width: 120px, $space-between: 2rem, $header-color: inherit) {
    .header-wrapper {
        background-color: transparent;
        color: $header-color;
        display: flex;
        align-items: flex-start;
        height: $header-height;
        padding: 0px;
        font-family: inherit;
        font-size: inherit;

        & > .logo-wrapper {
            align-self: center;
            margin-left: 5px;

            & > .logo-box {
                width: $logo-width;

                img {
                    height: auto;
                    width: 100%;
                }
            }
        }

        & > .header-content {
            align-self: center;
            margin-left: $space-between;
            margin-right: 5px;
            color: inherit;
            word-wrap: normal;
            text-align: left;
            max-height: 85%;
            overflow: hidden;

            h1 {
                color: inherit;
                font-size: 3em;
                margin: 0;
                padding: 0;
            }

            h2 {
                color: inherit;
                font-size: 1.8em;
                margin: 0;
                padding: 0;
            }
        }

        @content;
    }
}

@mixin es-default-service-login-template($wrapper-name: ".template-wrapper") {
    #root > .app-viewport.external-service {
        #{$wrapper-name} {
            background-color: $servicos_default-bgColor;
            color: $servicos_default-color;
            font-size: $servicos_default-base-font-size;
            height: 100%;
    
            & > .content {
                background-color: inherit;
                color: inherit;
                font-size: inherit;
                padding: $appContent_defaultVerticalPadding $appContent_horizontalPadding;
    
                min-width: 520px;
    
                .btn-login {
                    @include es-form-button();
                }
            
                .information-card {
                    &.info-error {
                        @include message-box($main_warning-color, $main_color) {
                            margin-top: $appContent_defaultVerticalPadding;
                        }
                    }
                }
            
                .header-card {
                    @include es-card-envelope(0 0 15px 0, 15px 20px, 8px) {
                        background-color: $servicos_default-header-bgColor;
    
                        @include es-default-header-content(auto, 120px, 2rem, $servicos_default-header-color);
                    }
                }
    
                .login-section {
                    margin-bottom: 15px;
    
                    .form-wrapper {
                        margin-top: 15px;
                        
                        .form-login {
                            color: inherit;
    
                            .field-error {
                                padding: 0px;
                                color: $servicos_form-field-error-message-color;
                            }
            
                            .group-card { // Estilos e lay-out dos agrupamentos e grid
                                @include es-card-envelope(15px 0px, 20px 20px, 6px) {
                                    background-color: $servicos_default-card-bgColor;
                                }
    
                                text-align: left;
                                overflow: visible;
                                border: 1px solid $servicos_form-field-border-color;
    
                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                }
    
                                & > .grid-group-title {
                                    margin-top: 5px;
                                    color: $servicos_default-color;
                                    font-weight: 700;
                                }
                            }
    
                            @include es-form-servicos();  // Estilos dos campos dos formulário
                        }
                    }
                }
    
                .content-section {
                    .section-header-wrapper {
                        position: relative;
                
                        h2 {
                            color: inherit;
                            text-align: left;
                            margin-top: 1rem;
                            margin-bottom: 0;
                            font-size: 1.3em;
                        }
                    }
                
                    @content
                }
    
                .action-section {
                    margin-top: 1em;
                    padding: 1em 0;
                    text-align: center;
                }
            }
        }
    }    
}