/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'confirmacaoExternaDados' module specific styles
 * ---------------------------------------------------------
**/

@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-style : normal;
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?v=4.7.0");
           src : url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
                 url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#root > .app-viewport.external-service .confirmacao-externa-dados-wrapper {
    background-color: $confExtDados_bgColor;
    font-family: 'Roboto', arial, sans-serif;
      
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    min-height: 100%;
    color: $confExtDados_color;

    #msgBoxErroInicializacao {
        margin-top: 0;
    }

    #aghuseToastMsgId {
        position: fixed;
        top: 5px;
        right: 5px;
        margin-top: 0 ;        
    }

    fieldset.confirmar-dados-wifi {
        margin: 10px;
        padding: 1.5em;        
        border-width: 0.1em;
        height: 100%;
        width: -webkit-fill-available;
        border-radius: 4px;
        
        display: flex;
        justify-content: flex-start;

        legend {
            padding: .2em 1em !important;
            background: #f3f3f3;
            border: 0 !important;
            color: black;
            margin: 0 0 0 0 !important;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 10px;
            font-weight: bold;
            border-radius: 4px;
        }

        .form { 
            width: fit-content; 

            .form-content {
    
                padding: 0;    
    
                ._djfb_grid-group-title {                    
                    display: none;
                }
            
                ._djfb_grid-group-content {
            
                    margin: 0;
            
                    .form-input-field {
                        margin-top: 0.2em;
                        width: fit-content !important;
            
                        $form-field_width: 280px;
                        $form-field_height: 28px;
            
                        ._djfb_grid-column {            
                            position: relative;
                            padding-bottom: 1.5em; 
                    
                            .ui.fluid.input {
                                width: $form-field_width;
                
                                .input-wrapper, .masked-input-wrapper {
                                    width: 100%;   
                                }
                            }
                        }
                
                        &.field-data-nascimento {
                            .input-wrapper {
                                .date-picker-trigger {
                                    height: $form-field_height;
                                    width: $form-field_height;
                                    
                                    .button-wrapper {  
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        top: 0;
                 
                                        transform: none;
                                        display: flex;
                                        align-items: center;
                    
                                        button {
                                            width: 100%;
                                            height: 100%;
                                            position: relative;
                                        }
                                    }
                                }
            
                                #dtNascimento_datePickerButtonId {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: #459e00;                                    
            
                                    img {
                                        display: none;
                                    }
            
                                    &::before {
                                        font-family: 'FontAwesome', sans-serif;
                                        content: "\f271"; /*calendar-plus*/
                                        font-size: calc($form-field_height * 0.6);
                                        padding-left: 2px;
                                        color: white;
                                    }
            
                                    &:disabled {
                                        cursor: not-allowed;
                                        background-color: #999;
                                        box-shadow: none !important;
                                    }
                                } 
            
                                .date-picker-wrapper {
                                    .react-datepicker__header {
                                        background-color: #84be3f;
            
                                        .react-datepicker__current-month, 
                                        .react-datepicker__day-names .react-datepicker__day-name {
                                            color: white;
                                        }                            
                                        
                                    }
                                    .react-datepicker__navigation-icon::before {
                                        border-color: white;
                                    }
                                    
                                }
                                
                                
                            }
                            .field-error {
                                white-space: nowrap;
                            }
                        }
                
                        &.field-cpf {

                            ._djfb_grid-column {
                                padding-bottom: 0.6em;
                            }

                            .masked-input-wrapper.disabled {
                                input[disabled] {
                                    height: 10px;
                                    padding-left: 0 !important;

                                    border: none;
                                    color: $confExtDados_color;
                                    opacity: 1;
                                    box-shadow: none !important;
                                }
                            }

                            .label-additional-info {
                                display: none;
                            }
                        }
                    }
            
                    .input-wrapper.disabled {
                        cursor: not-allowed;
                        border: $mainForm_field-disabled-color;
            
                        .date-picker-trigger button {
                            border: none;
                        }
                    }
                }
    
            }
    
            .form-actions {
                display: flex;
                width: 280px;
                justify-content: space-evenly;                
                gap: 40px;
                margin-top: 1em;
        
                button {
                    width: 100%;
                }
            }
    
            /* Ajustar depois do facelift */
            .aghuse-button {
                border-radius: 3px;
                border: 1px solid #6cb229 !important;
                background: -webkit-linear-gradient(top, rgba(108, 178, 41, 1.0), rgba(108, 178, 41, 0)) rgba(181, 227, 134, 1.0) !important;

                font-weight: bold;
                color: black ;
                font-family: arial, sans-serif !important;
                font-size: 11px;
                text-transform: none;
    
                &:hover {
                    box-shadow: 0 0 8px #6cb229, 0 1px 0 rgba(255, 255, 255, 0.4) inset !important;
                    background-color: $formButton_bgColor;
                }
            }
        }
    }

    
}
