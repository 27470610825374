/* 
 * MIXINs para vizualização do laudo e atestado:
 *
 *      laudos-atestados-content: conteúdo do laudo/atestado
 *      laudos-atestados-resposivo: definicoes de responsividade para o componente
 *      laudos-atestados-pdf-mode: definições para quando gera PDF (no momneto, em uso apenas no 'regmedicos-services')
 *      
 * Importar (laudos-atestado-content e pdf-mode) para SASS do 'regmedicos-services'.
 */

 @mixin laudos-atestados-content($content-height: auto, $content-margin: 30px, $items-padding: 20px, $wrapper-width: 94%,
                       $laudo-atestado-bgcolor: #fff, $laudo-atestado-color: #000,
                       $table-border: #555) {

    $content-min-width: 300px;
    $content-tipouso-margin: $items-padding;
    $content-item-margin: 2 * $content-tipouso-margin;
    
    $separator-height: calc(1px + calc(2 * #{$items-padding}));
    $separator-margin: $items-padding;

    $head-logo-width: 300px;
    $head-content-margin: 2 * $items-padding;
    $head-content-unuseable: $head-logo-width + $head-content-margin;
    $head-content-maxwidth: calc(100% - #{$head-content-unuseable});

    $qrcode-size-mobile: 250px;
    $qrcode-size-desktop: 0.6 * $qrcode-size-mobile;

    $wrapper-margin: calc(calc(100% - #{$wrapper-width}) / 2);
    .content-wrapper {
        max-width: $wrapper-width;
        margin-left: $wrapper-margin;
        padding-bottom: $content-margin;
        min-height: $content-height;
        min-width: $content-min-width;
        background-color: $laudo-atestado-bgcolor;
        color: $laudo-atestado-color;

        & img {
            width: auto;
            height: auto;
            background-color: inherit;
        }

        & label {
            color: inherit;
        }

        .flex-break-line {
            flex-basis: 100%;
            height: 0;
        }

        .content-separator {
            padding: $separator-margin $content-margin;
            margin: 0px !important;

            hr {
                border-top: 1px solid $laudo-atestado-color !important;
                border-left: none !important;
                border-right: none !important;
                border-bottom: none !important;
                padding: 0px !important;
                margin: 0px !important;
            }
        }

        .content-header-wrapper {
            margin: 0px;

            ._head {
                display: flex;
                flex-wrap: wrap;
                padding: $content-margin $content-margin 0px $content-margin;

                .img-wrapper {
                    display: block;
                    text-align: left;
                    width: $head-logo-width;

                    .img-logo {
                        display: unset;
                        vertical-align: middle;
                        height: auto !important;
                        width: $head-logo-width;
                    }
                }

                .head-content {
                    display: inline-block;
                    text-align: left;
                    margin-left: $head-content-margin;
                    margin-top: auto;
                    max-width: $head-content-maxwidth;
                    
                    & label {
                        font-size: 1.2em;
                        font-weight: normal;
                    }
    
                    & label:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .content-body-wrapper {
            padding: 0px $content-margin;
            margin: 0px;

            ._body {
                .paciente {
                    padding: 5px 0px 0px $items-padding;
                    text-align: left;
                    font-size: 1.2em;
                    font-weight: bold;
                }

                .body-content {
                    $extra-padding: 1.5 * $items-padding;
                    margin-top: $extra-padding;
                    padding: 0px 0px $extra-padding $items-padding;
                    text-align: left;

                    .body-title {
                        text-align: center;
                        font-weight: bold;
                        font-size: 1.2em;
                        font-style: italic;
                        margin-bottom: 1em;
                    }
                }
            }
        }

        .content-footer-wrapper {
            .validacao {
                padding: $items-padding $content-margin 0px $content-margin;
                display: flex;
                justify-content: space-between;

                .validacao-msgs-wrapper {
                    margin-right: $content-margin;
                    width: 100%;
                    text-align: left;
                    margin-top: auto;
                    font-size: 1em;

                    .elaborado {
                        display: inline-block;
                        text-align: left;
                        font-size: inherit;
                    }

                    .mensagem-validacao {
                        margin-top: $content-margin;
                        display: block;
                        font-size: inherit;
                        cursor: text;

                        .emphasis {
                            font-weight: bold !important;
                        }
                    }
                }

                .qrcode-pv , .qrcode-lv {
                    overflow: visible;

                    img {
                        height: auto;
                    }

                    .qrcode-block {
                        display: inline-block;
                    }

                    .qrcode-block.pointer:hover {
                        cursor: pointer;
                    }

                }

                .qrcode-pv {
                    display: none;
                    margin-bottom: 20px;
                    height: auto;
                    width: $qrcode-size-mobile;

                    img {
                        width: $qrcode-size-mobile;
                    }
                }

                .qrcode-lv {
                    display: block;
                    height: $qrcode-size-desktop;
                    width: $qrcode-size-desktop;

                    img {
                        width: $qrcode-size-desktop;
                    }
                }

                .clear {
                    display: none;
                }
            }

            ._footer {
                padding: 0px $content-margin;
                text-align: left;

                & label {
                    font-size: 1.15em;
                    font-weight: normal;
                }

            }
        }
    }
}

@mixin laudos-atestados-pdf-mode($wrapper-width: 695px, $content-margin: 20px, $separator-margin: 15px, $qrcode-size-desktop: 150px,
                $grid-header-bgcolor: rgb(200, 200, 200), $grid-bgcolor: rgb(0, 0, 0)) {
	.pdf-document {
		width: $wrapper-width;
		.content-wrapper {
			margin: 0px !important;
			max-width: 100% !important;
			padding-bottom: 0px !important;
			width: $wrapper-width;

            .content-separator {
                padding: $separator-margin $content-margin !important;
            }

			.content-header-wrapper {
				$logo-height: 108px;
				$logo-width: 300px;
                ._head {
					display: block !important;
					padding-top: $content-margin;
                    padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
		            
		            position: relative;
		            height: $logo-height;
		            
					.img-wrapper {
						float: left;
						display: block !important;
						width: $logo-width !important;
						
			            .img-logo {
			                width: $logo-width !important;
			            }
					}
					
					$left-content-margin: 40px;
					$max-content-width: $wrapper-width - 2*$content-margin - $logo-width - $left-content-margin;
					$left-content-position: $logo-width + $left-content-margin;
					.head-content {
						position: absolute;
						bottom: 0px;
						left: $left-content-position;
						margin-left: 0px !important;
						
						float: left;
						display: block !important;
						width: $max-content-width; 
						max-width: unset !important;
					}
				
					.flex-break-line {
						clear: both !important;
						line-height: 0px !important;
						flex-basis: unset !important;
						height: unset !important;
					}
				}
			}

	        .content-body-wrapper {
	            ._body {
	            	padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
                }
            }
		
			.content-footer-wrapper {
				.validacao {
					display: block !important;
	            	padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
    
                    $validacao-line-height: 20px;
                    $msgs-separator-height: 30px;
                    $msgs-wrapper-width: $wrapper-width - $qrcode-size-desktop - 30px;
                    $msgs-wrapper-margin-top: $qrcode-size-desktop - (3 * $validacao-line-height) - $msgs-separator-height;

                    .validacao-msgs-wrapper {
                        float: left !important;
                        width: $msgs-wrapper-width !important;
                        margin-right: 0px !important;
                        margin-top: $msgs-wrapper-margin-top !important;

                        .elaborado {
                            line-height: $validacao-line-height !important;
                        }
                        .mensagem-validacao {
                            display: block !important;
                            margin-top: $msgs-separator-height !important;
                            padding-left: $content-margin !important;
                            padding-right: $content-margin !important;
                            line-height: $validacao-line-height;
                        }
                    }

					.qrcode-pv {
						display: none !important;
					}
					
					.qrcode-lv {
						float: right;
					}
					
					.clear {
						display: block !important;
						clear: both;
						line-height: 0px;
					}
				}			

				._footer {
	            	padding-left: $content-margin !important;
		            padding-right: $content-margin !important;
				}
            }
		}
	}
}

@mixin laudos-atestados-resposivo($sm-content-margin: 15px, $sm-items-padding: 12px) {
    $content-tipouso-margin: $sm-items-padding;
    $content-item-margin: 2 * $content-tipouso-margin;
    $separator-margin: $sm-items-padding;

    @media screen and (max-width: 320px) {
        .content-wrapper {
            .content-header-wrapper {
                ._head {
                    .head-content label { font-size: 0.98em !important; }
                }
            }

            .content-body-wrapper {
                ._body {
                    .paciente { font-size: 98% !important; }
                }
            }

            .content-footer-wrapper {
                .validacao { .validacao-msgs-wrapper { font-size: 0.85em !important; } }
                ._footer label { font-size: 1.02em !important; }
            }
        }
    }

    @media screen and (min-width: 321px) and (max-width: 767px) {
        .content-wrapper {
            .content-header-wrapper {
                ._head {
                    .head-content label { font-size: 1em !important; }
                }
            }

            .content-body-wrapper {
                ._body {
                    .paciente { font-size: 1em !important; }
                }
            }

            .content-footer-wrapper {
                .validacao { .validacao-msgs-wrapper { font-size: 0.9em !important; } }
                ._footer label { font-size: 1.1em !important; }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .content-wrapper {
            .content-header-wrapper {
                ._head {
                    display: block !important;
            
                    $logo-width: 250px !important;
                    .img-wrapper {
                        display: block;
                        width: auto !important;
                        text-align: center !important;
            
                        .img-logo {
                            height: auto !important;
                            width: $logo-width;
                        }
                    }
            
                    .head-content {
                        display: block !important;
                        margin: 20px 0px 0px 0px !important;
                        max-width: unset !important;
                        text-align: center !important;
                    }
                }
            }

            .content-footer-wrapper {
                .validacao {
                    flex-direction: column !important;
                    align-items: center !important;
                    margin-top: 20px !important;
                    .qrcode-pv { display: block !important; }
                    .qrcode-lv { display: none !important; }
                    .validacao-msgs-wrapper { 
                        text-align: center !important;
                        margin: 0px !important;
                        .mensagem-validacao { text-align: center !important; }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 414px) {
        .content-wrapper {
            .content-separator { padding: $separator-margin $sm-content-margin !important; }
    
            .content-header-wrapper {
                ._head {
                    padding-top: $sm-content-margin !important;
                    padding-left: $sm-content-margin !important;
                    padding-right: $sm-content-margin !important;
                }
            }

            .content-body-wrapper {
                padding: 0px $sm-content-margin !important;
                ._body {
                    .paciente { padding: 0px 0px 0px $sm-items-padding !important; }
                }
            }
        
            .content-footer-wrapper {
                .validacao { 
                    padding: $sm-items-padding $sm-content-margin 0px $sm-content-margin !important; 
                    .validacao-msgs-wrapper {
                        .mensagem-validacao { margin-top: $sm-content-margin !important; }
                    }
                }

                ._footer {
                    padding-left: $sm-content-margin !important;
                    padding-right: $sm-content-margin !important;
                }
            }
        }
    }
}
