/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'alterarSenha' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('alterar-senha') {
    .instructions-section {
        padding: 1em 0;
    }

    .alterar-senha-form {
        margin-top: 2em;
        
        @include mc-form-principal();
    }

    .main-action {
        padding-top: 1.5rem;

        &:not(:empty) {
            padding-bottom: 1.5rem;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
