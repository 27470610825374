/* Import common definition styles */
@import '../../styles/common.scss';


/* ---------------------------------------------------------
 *  'geradorTokenIntegracaoLogin' module specific styles
 * ---------------------------------------------------------
**/

#root > .app-viewport.external-service {
    .gerador-token-integracao-wrapper {
        background-color: $servicos_default-bgColor;
        color: $servicos_default-color;
        font-size: $servicos_default-base-font-size;
        height: 100%;

        .btn-generate {
            @include es-form-button();
        }

        .information-card {
            &.info-success {
                @include message-box($main_information-bgColor, $main_color) {
                    margin-top: $appContent_defaultVerticalPadding;
                }
            }

            &.info-error {
                @include message-box($main_warning-color, $main_color) {
                    margin-top: $appContent_defaultVerticalPadding;
                }
            }
        }

        & > .content-wrapper {
            background-color: inherit;
            color: inherit;
            font-size: inherit;
            padding: $appContent_defaultVerticalPadding $appContent_horizontalPadding;

            min-width: 540px;

            & > .content-box {
                .header-card {
                    @include es-card-envelope(0 0 15px 0, 15px 20px, 8px) {
                        background-color: $servicos_default-header-bgColor;

                        @include es-default-header-content(auto, 120px, 2rem, $servicos_default-header-color);
                    }
                }

                .primary-card {
                    @include es-card-envelope(15px 0px, 20px 20px, 6px) {
                        background-color: $servicos_default-card-bgColor;
                    }

                    .section-instruction {
                        text-align: left;

                        .instruction-required {
                            margin-top: 10px;
                            font-size: inherit;
                            color: $servicos_form-field-required-mark-color;
                        }
                    }

                    .form-wrapper {
                        margin-top: 15px;
                        
                        .form-generator {
                            color: inherit;
        
                            .field-error {
                                color: $servicos_form-field-error-message-color;
                                padding: 0px;
                            }
            
                            .group-card { // Estilos e lay-out dos agrupamentos e grid
                                color: inherit;
                                text-align: left;
                                overflow: visible;

                                border-radius: 4px;
                                border: 1px solid $servicos_form-field-border-color;
                                padding: 10px 15px;

                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                }

                                & > ._djfb_grid-group-title {
                                    margin-top: 5px;
                                    color: $servicos_default-color;
                                    font-weight: 700;
                                }

                                & > ._djfb_grid-group-description {
                                    color: lighten($servicos_default-color, 15%);
                                    font-size: 0.95em;
                                }
                            }

                            @include es-form-servicos();  // Estilos dos campos dos formulário

                            .txt-parametro {
                                position: relative;

                                & > ._djfb_grid-column {
                                    & > .field-label {
                                        display: inline-block;
                                        position: absolute;
                                        top: 0;
                                    }
                                }
                                
                            }

                            .txt-codigo-paciente {
                                width: 360px;
                            }
                        }
                    }
                }

                .section-token {
                    @include es-card-envelope(15px 0px, 20px 20px, 6px) {
                        background-color: $servicos_default-information-color;
                        color: $servicos_default-header-color;
                    }
                    text-align: left;

                    & > .token-content {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .copyable-field {
                            border: solid 1px $servicos_form-field-border-color;
                            border-radius: 4px;
                            width: 100%;
                            @include select-enabled();
                        }

                        .action-copy {
                            margin-left: 0.75em;

                            & > div {
                                display: inline-block;
                                & > button {
                                    padding-left: 1em;
                                    padding-right: 1em;
                                }
                            }
                        }
                    }

                    & > .copy-message {
                        color: $main_emphasis-color;
                        margin-top: 0.5em;
                        font-size: 0.9em;

                        &.error {
                            color: $main_warning-color;
                        }
                    }
                }

                .action-section {
                    margin-top: 15px;
                    padding: 15px 0;
                    text-align: center;
                }
            }
        }
    }
}    

#root > .app-viewport.external-service {
    .gerador-token-integracao-wrapper {
        & > .content {
            .header-card .header-wrapper > .header-content {
                h1 {
                    font-size: 2.4em;
                }
            }
        }
    }
}
