/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'whatermark' module specific styles
 * ---------------------------------------------------------
**/
@mixin whatermark($top-margin: 0px, $bottom-margin: 0px, $width: 100%, $z-index: 8000) {
    $height: calc(100% - #{$top-margin} - #{$bottom-margin});
    div.watermark-wrapper {
        position: fixed;
        top: $top-margin;
        left: 0;
        width: $width;
        height: $height;
        z-index: $z-index;
        pointer-events: none;
        overflow: hidden;

        & > .watermark-content-wrapper {
            position: relative;
            display: block;
            height: 100%;
            width: 100%;
            pointer-events: none;
            overflow: hidden;

            & > .watermark-text-wrapper {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                & > .watermark-text {
                    color: #aaa;
                    line-height: 1em;
                    white-space: nowrap;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);

                    @include appFont-default();                    
                }
            }
        }
    }

    @content;
}

div#watermarkID {
    @include whatermark($viewerModal_default-header-height, $viewerModal_default-footer-height, 100%);
}