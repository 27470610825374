/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  'login' module specific styles
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .login-wrapper {
        background-color: transparent;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        width: 100%;

        & > .content-wrapper {
            & > .content-box {
                display: inline-block;
                padding: $appContent_defaultVerticalPadding 0;
                width: 75%;
                max-width: $app_default-auth-max-width;

                .header {
                    flex-grow: 0;
                    padding: 0;
                    display: flex;

                    .logo-box {
                        margin: 0;
                        width: 100%;
                        text-align: center;

                        &:not(:first-child) {
                            margin-left: 2em;
                        }

                        img {
                            display: inline-block;
                            height: 70px;
                            width: auto;
                            vertical-align: middle;
                        }
                    }
                }

                .body {
                    padding: 2rem 0;
                    font-size: inherit;

                    .login-form {
                        font-size: inherit;

                        &.identificacao {
                            margin-top: 4rem;
                            margin-bottom: 2rem;
                        }

                        &.senha {
                            margin-top: 2.5rem;
                        }
                        
                        &.perguntas {
                            margin-top: 1.2rem;
                        }

                        & > .login-welcome-message {
                            & > .title {
                                @include appFont-black();
                                text-transform: uppercase;
                            }

                            & > .message {
                                margin-top: 0.5rem;
                                padding-bottom: 0.5rem;
                            }
                        }
    
                        & > .manter-conectado {
                            margin-top: $appContent_defaultVerticalPadding;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            font-size: inherit;
    
                            > .wrapper {
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
    
                                > label {
                                    margin-left: 10px;
                                    font-size: 0.9rem;
                                    font-weight: inherit;
                                }
    
                                @include toggle-change-size(48px, 2px) {
                                    label {
                                        background-color: transparent;
                                        &::after {
                                            background-color: darken($main_color, 15%);
                                        }
                                    }

                                    .switch--shadow:checked + label::after {
                                        background-color: darken($main_color, 15%);
                                    }
                                }
                            }
                        }

                        & > .login-message-box {
                            min-height: 4.8rem;
                        }

                        & > .reenviar-email-ativacao {
                            margin-top: 1rem;
                            font-size: 0.9rem;
    
                            a {
                                padding-left: 5px;
                            }

                            .mensagem-adicional {
                                margin-top: 1.8rem;
                            }
                        }
                    }

                    .main-action {
                        margin-top: $appContent_defaultVerticalPadding;

                        &.voltar, &.alterar-dados {
                            margin-top: 1.9rem;
                        }
                    }

                    .extra-actions {
                        margin-top: 2.0rem;

                        & > .button-wrapper {
                            display: inline-block;

                            &:not(:first-child) {
                                margin-left: 2.5rem;
                            }

                            .action-image:not(.disabled):hover {
                                cursor: pointer;
                            }

                            img {
                                display: inline-block;
                                width: auto;
                                height: 45px;
                            }
                        }
                    }
                }

                .footer {
                    flex-grow: 0;
                    padding: 0;
        
                    .organization-logo {
                        margin: 0;
                        width: 100%;
                        text-align: center;

                        img {
                            display: inline-block;
                            height: 50px;
                            width: auto;
                        }
                    }
        
                    .versao {
                        margin-top: 2rem;
                        span {
                            font-size: 0.8rem;
                        }

                        span.build-time {
                            margin-left: 0.5em;
                        }
                    }
                }

                .forca-troca-senha {
                    margin: 1em 0 0 0;
                    .title {
                        @include appFont-black();
                        text-transform: uppercase;
                    }

                    .instructions {
                        padding: 1.5em 0;
                    }
                
                    .alterar-senha-form {
                        margin-top: 2em;
                    }
                }
            }
        }

        .ending-padding {
            display: block;
            height: calc(100vh - (100 * var(--vh, 1vh)));
        }
    }

    &.layout-wifi .login-wrapper {
        & > .content-wrapper > .content-box {
            .body .login-form, 
            .forca-troca-senha .alterar-senha-form {
                @include mc-form-input-float-label($wifi_content-color, $wifi_content-bgColor, $wifi_content-color, $wifi_content-color);
            }
        }    
    }

    &:not(.layout-wifi) .login-wrapper {
        & > .content-wrapper > .content-box {
            .body .login-form, 
            .forca-troca-senha .alterar-senha-form {
                @include mc-form-input-float-label($main_color, $main_bgColor, $main_color, $main_color);
            }
        }    
    }
}


/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    &:not(.mobile) {
        & .login-wrapper {
            & > .content-wrapper {
                margin-right: 15px; /* correction due to scroll bar width */

                & > .content-box {
                    margin: 0 $appContent_horizontalPadding;
                }
            }
        }
    }

    &.mobile {
        & .login-wrapper {
            & > .content-wrapper {
                & > .content-box {
                    margin: 0 $appContent_mobile-horizontalPadding;

                    & .body {
                        & .extra-actions {
                            & > .button-wrapper:not(:first-child) {
                                margin-left: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    @mixin floating_label_adjust($font-size, $top) {
        font-size: $font-size !important;
        top: $top !important;
    }

    & .login-wrapper {
        & > .content-wrapper > .content-box {
            @media screen and (max-width: 375px) {
                width: 92%;
            }
            @media screen and (min-width: 375px) and (max-width: 475px) {
                width: 86%;
            }
        }

        .body {
            .login-form {
                .float-label-field {
                    .field-email-cpf-localizador {
                        .ui.label.label {
                            @media screen and (max-width: 340px) {
                                @include floating_label_adjust(0.80em, 1.5px);
                            }
                            @media screen and (min-width: 340px) and (max-width: 375px) {
                                @include floating_label_adjust(0.88em, 0.9px);
                            }
                            @media screen and (min-width: 375px) and (max-width: 410px) {
                                @include floating_label_adjust(0.9em, 0.75px);
                            }
                        }
                    }
                }
            }
        }
    }
}
