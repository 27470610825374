.aghu {

    &.field-aghuse {

        .label-aghuse {
            text-align: left;
            width: 100%; 

            display: flex;
            justify-content: space-between;

            .label-text {
                font-family: arial, sans-serif !important;
                font-size: 12px;                
                color: #312e25;
            }

            .label-additional-info {
                .aghu-iconeinput {
                    position: relative;
                    float: left;
                    margin: 0;
                    width: 16px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    cursor: pointer;
                
                    &.aghu-interrog {
                        background-image: url(../image/interrog.png);
                    
                        &:hover {
                            background-position: 0 -16px;
                        }
                    }
        
                    &.aghu-asterisco {
                        background-image: url(../image/asterisco.png);
        
                        &:hover {
                            background-position: 0 -16px;
                        }
                    }
                
                    
                }

            }        

        }

        .ui.fluid.input { 

            .input-wrapper, .masked-input-wrapper {   
                &.invalid {
                    box-shadow: 0px 0px 5px #ff0000, 0 2px 2px #8f8f8f inset !important;
                
                }                                                

                input, button {
                    border-color: #daebc5;
                }

                

                input {
                    box-shadow: inset 0 2px 2px #8f8f8f;
                    color: #433f38;
                    height: 28px;
                    font-family: arial, sans-serif !important;
                    font-size: 12px;
                }
            }
        }

        .field-error {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            color: red;
            text-align: left;
            font-family: arial, sans-serif !important;
            font-size: 12px;
        }
    }
    
}