/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appLoading' module specific styles
 * ---------------------------------------------------------
**/

@keyframes loadingFadeInOpacity {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes loadingFadeOutOpacity {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

#root {
    .app-loading-wrapper {
        & > div.loading-frame {
            background-color: rgb(0, 0, 0);  /* Fallback for web browsers that doesn't support RGBa */
            background-color: $main_shadowfog-bgColor;
            animation-name: 'loadingFadeInOpacity';
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 100ms;
            z-index: 11000;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: default;
    
            @include no-select();
        
            & > div.loading-box {
                display: block;
                overflow: hidden;
                position: absolute;
                width: 120px;
                height: 120px;
                top: 50%; 
                left: 50%;
                transform: translate(-50%, -50%);

                img.loading {
                    max-width: 100%;
                }
            }
                  
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
