/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'consultas' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('consultas') {
    .aviso-remarcacao {
        font-size: inherit;
        margin-top: 0;
        margin-bottom: $appContent_defaultVerticalPadding;
    }

    & > .consultas-section {
        font-size: 0.94em;
        margin-top: 2em;

        .consultas-atuais, .consultas-historico {
            .consulta-card-wrapper {
                &:not(:first-child) {
                    margin-top: 1.5em;
                }

                & > .consulta-situacao {
                    padding: 0.75em;
                    text-align: center;
                    background-color: $consultas_card-title-bgColor;
                    color: $consultas_card-title-color;
                }

                & > .consulta-body {
                    padding: 1em;
                    background-color: $consultas_card-content-bgColor;
                    color: $consultas_card-content-color;

                    & > .body-info {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .highlight {
                            color: $consultas_card-teleatendimento-highlight-color;
                        }

                        & > .col-left, & > .col-right {
                            display: inline-block;

                            &> div:not(:first-child) {
                                margin-top: 0.5em;
                            }
                        }

                        & > .col-left {
                            &> div {
                                display: flex;
                                flex-direction: row;
                            }

                            .info-image-wrapper {
                                flex-shrink: 0;
                                align-self: center;
                                height: auto;
                                width: $consultas_card-info-icon-width;
                                img {
                                    height: auto;
                                    width: 100%;
                                }
                            }

                            .info-text {
                                align-self: center;
                                margin-left: 0.5em;
                                display: inline-block;

                                time {
                                    @include appFont-bold();
                                }

                                .zona, .sala {
                                    min-width: 6.5em;
                                    display: flex;
                                    flex-direction: row;

                                    .caption {
                                        display: inline-block;
                                        @include appFont-bold();
                                    }

                                    .text {
                                        display: inline-block;
                                        margin-left: 0.33em;
                                    }
                                }
                            }
                        }

                        & > .col-right {
                            margin-left: 1em;
                        }
                    }

                    .body-action {
                        margin-top: 1em;
                        margin-left: auto;
                        margin-right: auto;
                        width: 300px;

                        & > button:not(:first-of-type) {
                            margin-top: 1em;
                        }

                        .action-button {
                            background-color: $consultas_card-title-bgColor;
                        }

                        .download-error {
                            color: $main_warning-color;
                            margin-top: 0.33em;
                        }
                    }
                }

                &.estornada {
                    & > .consulta-situacao > .nome-situacao {
                        color: $consultas_card-title-color;
                    }

                    & > .consulta-body > .body-info {
                        & > .col-left, & > .col-right {
                            & > div {
                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }
        }

        .consultas-historico {
            margin-top: 2em;

            & > .accordion {
                & > .accordion-item {
                    &.active > .content {
                        opacity: 0.7;
                        max-height: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    & > .iformation-dlg-wrapper {
        .iformation-dlg-box {
            max-width: 360px;

            .dialog-header {
                padding: 0.4em 1rem;
                background-color: $appConfirmationDlg_header-bgColor;
                color: $appConfirmationDlg_header-color;
                font-size: 1.15em;
                line-height: 1.15em;
                @include appFont-bold();
    
                @include round-cross-btn(1, fixed, $main_color) {
                    top: 0.3em;
                    right: 0.3em;
                    transform: scale(0.8, 0.8);

                    &:hover, &:focus {
                        .close {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
        
        .message {
            font-size: 1em;
        }

        .warning {
            color: $main_warning-color;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    .consultas-wrapper {
        & > .content-wrapper {
            & > .content-box {
                .aviso-remarcacao, .consultas-section {
                    @media screen and (max-width: 350px) {
                        font-size: 0.85rem;

                        .consulta-card-wrapper > .consulta-body > .body-info > .col-left .info-image-wrapper {
                            width: calc(0.85 * #{$consultas_card-info-icon-width}) !important;
                        }
                    }

                    @media screen and (min-width: 350px) and (max-width: 410px) {
                        font-size: 0.9rem;

                        .consulta-card-wrapper > .consulta-body > .body-info > .col-left .info-image-wrapper {
                            width: calc(0.9 * #{$consultas_card-info-icon-width}) !important;
                        }
                    }
                }
            }
        }
    }
}
