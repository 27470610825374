/* Import common definition styles */
@import '../../styles/common.scss';

/* ---------------------------------------------------------
 *  'informarLocalizador' module specific styles
 * ---------------------------------------------------------
**/
@include card-module-basic-wrapper-block('informar-localizador') {
    text-align: center;

    .info-principal {
        margin-top: 2rem;
        font-size: inherit;
        font-size: 1.55em;
    }

    .info-adicional {
        margin-top: 1em;
        font-size: 1em;
    }

    .informar-localizador-form {
        margin-top: 2.5em;

        @include mc-form-input-float-label($appCard_normal-color, $appCard_normal-bgColor, $appCard_normal-color, $appCard_normal-color);

        .obter-localizador {
            margin-top: 2em;
            margin-bottom: 1em;
        }
    }

    .main-action {
        padding-top: 1.5rem;

        &:not(:empty) {
            padding-bottom: 1.5rem;
        }
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
