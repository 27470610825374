/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'teleatendimentoExterno' module specific styles
 * ---------------------------------------------------------
**/

@include es-default-service-login-template(".teleatendimento-externo-wrapper");

#root > .app-viewport.external-service {
    .teleatendimento-externo-wrapper {
        & > .content {
            &.fill-area {
                height: 100%;

                .content-section {
                    height: 100%;

                    div#jitsiRootId {
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
           
            &.full-viewport {
                padding: 0 !important;
                margin: 0 !important;
            }
            
            .header-card .header-wrapper > .header-content{
                h1 {
                    font-size: 2.70em;
                }
            }
        }
    }
}
