/*
 * Estilos básico do componente emailCPFLocaliador
 *
 * Para demais estilhos (definições de cores, label como placeholder, etc...) incluir o mixin de formulários (definido em 'common.scss').
 */

.field-email-cpf-localizador {
    font-size: 1em;
    width: 100%;
    position: relative;
    text-align: left;
}