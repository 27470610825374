/* Import common definition styles */
@import '../../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'jitsiMeetingRoom' module specific styles
 * ---------------------------------------------------------
**/

div#jitsiWrapperId.jitsi-root-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;

    & .custom-toolbar {
        position: absolute;
        left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        transition: top .3s ease-in, bottom .3s ease-in;
        z-index: 300;

        background-color: $teleatendimento_custom-toolbar-bgColor;
        border-radius: 6px;
        background-clip: border-box;

        .ctb-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-radius: 3px;

            &:hover {
                cursor: pointer;
                background-color: $teleatendimento_custom-toolbar-button-hover-color;
            }

            &:not(:first-of-type) {
                margin-left: 8px;
            }
        }

        .button-hangup {
            background-color: $main_warning-color;
        
            & > .icon-wrapper {
                & img {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        .button-upload {
            & > .icon-wrapper {
                & img {
                    width: 30px;
                    height: 26px;
                }
            }
        }
    }
}

div#jitsiRootId {
    &.loading {
        visibility: hidden;
    }
}

/* ---------------------------------------------------------
 *  Module specific styles responsiveness
 * ---------------------------------------------------------
**/
#root > .app-viewport {
    &.desktop {
        $custom-button-size: 48px;
        $toolbar-padding: 6px;
        $toolbar-height: calc(#{$custom-button-size} + calc(2 * #{$toolbar-padding}));
    
        div#jitsiWrapperId.jitsi-root-wrapper > .custom-toolbar {
            bottom: calc(-1 * #{$toolbar-height});
            height: $toolbar-height;
            padding: $toolbar-padding;

            .ctb-button {
                width: $custom-button-size;
                height: $custom-button-size;
            }

            &.visible {
                bottom: calc(22px - #{$toolbar-padding});
            }

            @media screen and (max-width: 499px) {
                visibility: hidden;
            }        
        }
    }

    &.mobile {
        $custom-button-size: 32px;
        $toolbar-padding: 4px;
        $toolbar-height: calc(#{$custom-button-size} + calc(2 * #{$toolbar-padding}));

        div#jitsiWrapperId.jitsi-root-wrapper > .custom-toolbar {
            top: calc(-1 * #{$toolbar-height});
            height: $toolbar-height;
            padding: $toolbar-padding;

            .ctb-button {
                width: $custom-button-size;
                height: $custom-button-size;
            }

            .button-upload > .icon-wrapper img {
                width: 24px;
                height: 20.8px;
            }

            &.visible {
                top: calc(20px - #{$toolbar-padding});
            }
        }
    }
}
