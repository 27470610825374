/* Import common definition styles */
@import '../../../styles/common.scss';

/* ---------------------------------------------------------
 *  'appMessageDialog' module specific styles
 * ---------------------------------------------------------
**/
div#appMessageDialogWrapperID {
    @include floating-container(fixed) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 1em;
        background-color: $main_shadowfog-bgColor;
    }

    @include no-select();
}

div#appMessageDialogBoxID {
    $bg-color: rgb(233, 233, 233);
    $color: #000000;
    @include floating-container(relative) {
        border-radius: 4px;
        overflow: hidden;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: $bg-color;
        color: $color;
        text-align: left;
        font-size: inherit;

        display: table;
    }

    .dialog-header {
        display: block;
        position: relative;
    }

    .dialog-content {
        padding: 15px 15px;
        overflow: hidden;
    }

    .dialog-actions {
        padding-top: 15px;
        text-align: center;

        .btn-autosize {
            width: auto !important;
            padding-left: 20px;
            padding-right: 20px;
        }

        .btn-separator {
            margin-left: 20px;
        }
    }
}
